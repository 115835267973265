import useAPI from "../../../api/useAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { questionCategory } from "../../../listsAndObjects";
import { GMTtoKSTconverter } from "../../../converter";
import { useEffect } from "react";
import { useAuth } from "../../../auth/auth";
import { useBlock } from "../../../customHook/useBlock";
import parse from 'html-react-parser'
import styled from "styled-components";

export default function QuestionAnswer({setReload}) {
  const location = useLocation()
  const { question_category, question_title, question_board, admin_board, admin_datetime, question_file, question_type, question_datetime, question_idx } = location.state.question
  const navigate = useNavigate()
  const { deleteAPI } = useAPI()
  const { user } = useAuth()
  const { setBlock } = useBlock()

  let answerDate = GMTtoKSTconverter(admin_datetime + `.000Z`)
  let askDate = GMTtoKSTconverter(question_datetime + `.000Z`)

  useEffect(() => {
    if (!location.state) {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    const handleDelete = async (question_idx) => {
    setBlock(true)
    let url = `/user/question?question_idx=${question_idx}`
    let questionResponse = await deleteAPI(url, user)
    if (questionResponse.result) {
      setReload(Math.random())
      navigate('/mypage/question')
    }
    setBlock(false)
  }

  return (
    <Div>
      <div className="underLine" />
      <div className="title">
        <div>문의내용</div>
        <span>작성일 : {`${askDate.getFullYear()}.${askDate.getMonth() + 1}.${askDate.getDate()}`}</span>
      </div>
      <div className="content">
        <div>
          <p>처리상태</p>
          <div>
            {question_type ? '답변완료' : '답변대기중'}
          </div>
        </div>
        <div>
          <p>문의 유형</p>
          <div>
            {questionCategory[question_category - 1]}
          </div>
        </div>

        <div>
          <p>문의 제목</p>
          <div>
            {question_title}
          </div>
        </div>

        <div>
          <p>문의 내용</p>
          <div>
            {question_board}
          </div>
        </div>

        <div>
          <p>첨부 파일</p>
          <div>{question_file ? <a href={`${question_file}`} target="_blank" rel="noreferrer" >첨부파일 보기</a> : "없음"}</div>
        </div>
      </div>
      {question_type &&
        <>
          <div className="underLine" style={{ margin: '0px 0 48px' }} />
          <div className="title">
            <div>↳ 관리자 답변</div>
            <span>작성일 : {`${answerDate.getFullYear()}.${answerDate.getMonth() + 1}.${answerDate.getDate()}`}</span>
          </div>
          <div className="reply">{parse(admin_board.replace('<p></p>', '<br/>'))}</div>
        </>
      }
      <div className="buttonArea">
        {!question_type && 
          <div className="blackButton" onClick={()=>{handleDelete(question_idx)}}>삭제하기</div>
        }
        <div className="whiteButton" onClick={() => { navigate('/mypage/question') }}>
          목록으로
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  text-align: left;
  margin-bottom: 101px;

  .title{
    margin:-4px 0 6.5px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    div{
      font-family: Pretendard;
      font-size: 20px;
      font-weight: 800;
      color: #000;
    }
    span{
      font-family: Pretendard;
      font-size: 14px;
      letter-spacing: -0.22px;
      color: #666;
    }
  }

  .content>div{
    display: flex;
    align-items: center;
    min-height:60px;
    border-bottom: solid 1px #efefef;
    padding: 21px 0;
    box-sizing: border-box;

    p{
      margin:0;
      margin-right: 68px;
      min-width: 52px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
    }
    div{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      color: #303033;
      max-width:380px;
    }
    a{
      text-decoration:underline;
    }
  }

  .reply{
    padding:22px 0 48px;
    border-bottom: solid 1px #e0e0e0;
  }

  .buttonArea{
    padding-top:38px;
    display: flex;
    justify-content:center;
    .blackButton{
      width: 172px;
      height: 48px;
      margin-right:14px;
    }
    .whiteButton{
      width: 172px;
      height: 48px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.37px;
      border-color: #000;
      color: #000;
      min-height:initial;
    }
  }
  @media (max-width: 720px) {
    margin-bottom: 88px;
    .title{
      div{
        font-size: 18px;
      }
      span{
        font-size: 12px;
      }
    }
    .content>div{
      display: flex;
      align-items: center;
      min-height:60px;
      border-bottom: solid 1px #efefef;

      p{
        margin:0;
        margin-right: 64px;
        min-width: 52px;
      }
    }
    .buttonArea{
      .blackButton, .whiteButton{
        font-size: 14px;
      }
    }
  }
`
