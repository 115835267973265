
import SearchBar from '../../components/search/searchBar'
import Sections from './sections'
import styled from 'styled-components';
import MainBanner from '../../images/mainBanner.png'

export default function Main() {

  return (
    <>
      <Div>
        <div>
          {/* <h2>Jabble.</h2> */}
          <p>A platform for Creatives</p>
          <div className="searchBar">
            <SearchBar/>
          </div>
          <span>
            Trending : model, makeup artist, hair artist, sylist
          </span>
        </div>
      </Div>
      <Sections/>
    </>
  );
}

const Div = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 524px;
  background-image:url(${MainBanner});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  > div{
    width: 100%;
    text-align:center;
    *{
      font-family: Montserrat;
    }
    > p{
      font-size: 24px;
      font-weight: 500;
      margin: 42px 0 0 0;
      padding: 0px;
      color:white;
    }
    > span{
      max-width:860px;
      font-weight: 500;
      font-size: 14px;
      text-align:left;
      margin:20px auto 0;
      display: block;
      color:#DCDCDC;
    }
  }
  @media (max-width: 1500px) {
    >div{
      width: 90%;
      margin:0 auto;
    }
  }
  @media (max-width: 720px) {
    height: 320px;
    > div{
      > h2{
        font-size: 53.2px;
      }
      > p{
        font-size: 24px;
      }
      >.searchBar, >span{
        display:none;
      }
    }
  }
`

