import { useState, useEffect } from "react"
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from "styled-components"
import FindID from "./findID"
import FindPW from "./findPW"

export default function Find() {
  // ?type=id
  // ?type=password
  const [searchParams] = useSearchParams();  
  const [findPassword, setFindPassword] = useState(false)
  let navigate = useNavigate()
  let type = searchParams.get('type')

  useEffect(()=>{
    if(type === 'id'){
      setFindPassword(false)
    } else {
      setFindPassword(true)
    }
  },[type])

  return (
    <Div>
      <div className="header">회원정보 찾기</div>
      <div className="route">
        <div style={findPassword ? {borderBottom: "4px solid #E4E4E4", color:"#999"} : {borderBottom: "4px solid #222"}} onClick={() => { navigate('/start/find?type=id',{ state: { from: '/' } }) }}>아이디</div>
        <div style={!findPassword ? {borderBottom: "4px solid #E4E4E4", color:"#999"} : {borderBottom: "4px solid #222"}} onClick={() => { navigate('/start/find?type=password',{ state: { from: '/' } }) }}>비밀번호</div>
      </div>
      <div>
        {!findPassword ?
          <FindID>
            <div className="loginButton">찾으시던 아이디가 생각나셨나요?<span onClick={()=>navigate('/start',{ state: { from: '/' } })}>로그인하기</span></div>
          </FindID>
          :
          <FindPW>
            <div className="loginButton">찾으시던 비밀번호가 생각나셨나요?<span onClick={()=>navigate('/start',{ state: { from: '/' } })}>로그인하기</span></div>
          </FindPW>
        }
      </div>
    </Div>
  )
}

const Div = styled.div`
  max-width: 360px;
  height:680px;
  .route{
    display: flex ;
    div{
      width:100%;
      padding:48px 0 18px;

      font-family: Pretendard;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.28px;
      color: #292929;
      cursor:pointer;
    }
  }
  .loginButton, .loginButton >span{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.22px;
  }
  .loginButton{
    margin-top:38px;
    border-top: solid 1px #e9e9e9;
    padding-top:17px;
    color: #757576;
    >span{
      color: #000;
      margin-left:18px;
      text-decoration:underline;
      cursor:pointer;
    }
  }

  .content{
    padding:48px 0;
  }

  .underLine{
    width: 100%;
    height: 1px;
    margin: 38px 0 0;
    border-top: solid 1px #e9e9e9;
  }
  @media (max-width: 720px) {
    max-width: 324px;
    margin:0 auto;
  }

`