export const getSearchHistoryInLocalStorage = () => {
  const searchHistory = JSON.parse(localStorage.getItem('keyword'))
  return searchHistory ? searchHistory : []
  // return 값 배열형식으로 통일
}

export const setSearchHistoryInLocalStorage = (searchKeyword, searchHistory) => {
  if (searchHistory.length >= 5) {
    // localStorage 5이상이면 제일 오래된것 삭제하기(index값 높을수록 오래된것)
    searchHistory.pop()
    searchHistory.unshift(searchKeyword)
    localStorage.setItem('keyword', JSON.stringify(searchHistory));
    return searchHistory
  } else {
    // localStorage 5미만이면 찾아서 하나 맨 앞에 붙여넣기
    searchHistory.unshift(searchKeyword)
    localStorage.setItem('keyword', JSON.stringify(searchHistory));
    return searchHistory
  }
}

export const deleteSearchHistoryInLocalStorage = (index, searchHistory) => {
  let newSearchHistory = [...searchHistory]
  newSearchHistory.splice(index, 1)
  localStorage.setItem('keyword', JSON.stringify(newSearchHistory));
  return newSearchHistory
}

export const deleteAllSearchHistoryInLocalStorage = () => {
  localStorage.removeItem('keyword');
}