import { useForm, Controller } from "react-hook-form";
import { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import ProjectMember from './projectMember'
// import SearchModal from "../../../components/modal/searchModal";
import DatePicker from "react-multi-date-picker";
import calenderIcon from '../../../images/calenderIcon.svg';
import { useNavigate, useLocation } from "react-router-dom";
import useProjectAPI from "../../../api/useProjectAPI";
import { useAuth } from "../../../auth/auth";
import useAPI from "../../../api/useAPI";
import inputFileImage from '../../../images/inputFileImage.svg'
import inputFileImageBlue from '../../../images/inputFileImageBlue.svg'
import zoom from '../../../images/zoom.svg'
import userImage from "../../../images/userImage.svg"
import styled from "styled-components";
import Select from "../../../components/select/select";
import { useModal } from '../../../components/modal/modal';
import { useBlock } from "../../../customHook/useBlock";
import { occupationIndex } from '../../../listsAndObjects'
import playButton from "../../../images/playButton.svg"
import deleteButton from "../../../images/delete.svg"
import { dateRegExp } from "../../../components/regex";

export default function ProjectForm() {

  const { user, userType } = useAuth()
  const { register, handleSubmit, formState: { errors }, control, getValues, setValue, reset, resetField } = useForm();
  const navigation = useNavigate()
  const location = useLocation()
  const { modalShow, modalClose, subImageModal, addCampaginMemberModal } = useModal()
  const { uploadImageAPI, uploadSeveralImageAPI } = useAPI()
  const { getProjectAPI, putProjectAPI, postProjectAPI } = useProjectAPI()
  const { setBlock } = useBlock()

  const [showProjectMemberContainer, setProjectMemberContainer] = useState(false)
  const [project_brand, setProject_brand] = useState(location.state?.project_brand || '')
  const [project_category, setProject_category] = useState(location.state?.project_category || '')
  const [project_name, setProject_name] = useState(location.state?.project_name || '')
  const [thumbnail, setThumbnail] = useState(location.state?.main_img)
  const fileInput = useRef(); // thumbnail 바뀌었을때 input file에 저장된 이미지 변경하여 이미지 삭제 후 같은 이미지 올려도 onChange가능하도록
  const modalRef = useRef()
  const [project_start, setProject_start] = useState('')
  const [project_url, setProject_url] = useState('')
  const [subImage, setSubImage] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('') // 유저 검색어
  const [selectedCampaginMemberList, setSelectedCampaginMemberList] = useState([]) // 유저가 선택한 리스트

  const url = userType === 'client' ? `/${userType}/campaign?archive_idx=${location.state?.project_idx}&user_idx=${user.data.user_idx}` : `/${userType}/portpolio?artists_idx=${user.data.user_idx}&portpolio_idx=${location.state?.project_idx}`

  const archiveCategoryOptions = [
    { value: 1, label: 'LookBook' },
    { value: 2, label: 'Campaign' },
    { value: 3, label: 'Editorial' },
    { value: 4, label: 'Magazine' },
    { value: 5, label: 'Commercial' },
  ]

  useEffect(() => {
    if (location.pathname === '/mypage/project/update') {
      if (!location.state) {
        navigation(-1)
      } else {
        const fetchAPI = async () => {
          let project = await getProjectAPI(url, user)
          setProject_start(project.project_start + `.000Z`)
          setProject_url(project.project_url)
          setSubImage(project.sub_img)
          setSelectedCampaginMemberList(project.member)
          reset()
          setValue("project_start", project.project_start + `.000Z`);
        }
        fetchAPI()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function handler(event) {
      if (!modalRef.current?.contains(event.target)) {
        setProjectMemberContainer(false) // modalRef 바깥영역 클릭시
      } else {
        setProjectMemberContainer(!showProjectMemberContainer) // modalRef 안영역(Input) 클릭시 보였다 닫았다 할 수 있게.
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, [showProjectMemberContainer])


  const handleFileChange = (e) => {
    if (e.target.files[0]?.size > 5000000) {
      modalShow({ message: "5MB 이하의 이미지를 업로드해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    setThumbnail(e.target.files[0])
  }

  const handleSubFileChange = (e) => {
    if (typeof (e) === "string") { // youtube Link의 경우
      let newSubImage = [...subImage]
      newSubImage.push(e)
      setSubImage(newSubImage)
      return
    }
    let files = e.target.files
    if (files.length + subImage.length > 10) {
      modalShow({ message: "이미지는 최대 10개까지 등록 가능합니다.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 5000000) {
        modalShow({ message: "5MB 이하의 이미지를 업로드해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
        return
      }
    }
    let newSubImage = [...subImage]
    for (let i = 0; i < files.length; i++) {
      newSubImage.push(files[i])
    }
    modalClose()
    setSubImage(newSubImage)
  }

  const onSubmit = async (data) => {
    
    if (!thumbnail) {
      modalShow({ message: "썸네일 이미지를 업로드해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      setBlock(false)
      return
    }
    if (subImage.length < 4) {
      modalShow({ message: "최소 4개의 이미지를 업로드해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      setBlock(false)
      return
    }
    
    // image file to url
    let uploadResponse = await uploadImageAPI(thumbnail)
    let severalUploadResponse = await uploadSeveralImageAPI(subImage) // API 내부에 setBlock 있음
    if (uploadResponse.result === 'success') {
      data.main_img = uploadResponse.data.img_url
    } else {
      setBlock(false)
      modalShow({ message: `썸네일 이미지 업로드에 실패하였습니다. 관리자에게 문의해주세요.`, actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }

    if (severalUploadResponse.result === 'success') {
      data.sub_img = severalUploadResponse.data.imageUrls
    } else {
      setBlock(false)
      modalShow({ message: `이미지 업로드에 실패하였습니다. 관리자에게 문의해주세요.`, actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }

    selectedCampaginMemberList?.forEach((member) => {
      member.job = occupationIndex[member.job_detail]
    })
    data.project_member = selectedCampaginMemberList

    setBlock('작품 등록 중...')

    let postOrPutProjectResponse
    if (location.state?.project_idx) { // update인 경우 location.state로 data 보냄.
      data.project_idx = location.state.project_idx
      postOrPutProjectResponse = await putProjectAPI(data, user) // update project인 경우
    } else {
      postOrPutProjectResponse = await postProjectAPI(data, user) // add project인 경우
    }

    if (postOrPutProjectResponse?.result === "success") {
      setBlock(false)
      navigation('/mypage/project')
      return
    }
    setBlock(false)
  }

  const getSubImageItem = (subImage) => {
    if (!subImage) {
      return (
        <div className="uploadButton">
          <div className="selectFileIcon"/>
          <p>이미지/영상</p>
        </div>
      )
    }  // 이미지 없을때 클릭시 등록됨.

    if (typeof (subImage) === "object") {
      return <img className="uploadedImage" src={URL.createObjectURL(subImage)} alt="file icon" /> // 현재 올린 이미지
    }

    if (subImage.includes("https://d3azijzmx402dn.cloudfront.net/")) {
      return <img className="uploadedImage" src={subImage} alt="file icon" /> // 이미지 서버에 저장된 이미지
    } else {
      return ( // youtube thumbnail
        <div>
          <img className="uploadedImage" style={{ objectFit: "inherit" }} src={`https://img.youtube.com/vi/${subImage}/mqdefault.jpg`} alt="Youtube Thumbnail" />
          <img className="playImage" src={playButton} alt="play img" />
        </div>
      )
    }
  }

  const getSubImageUpload = () => {
    let subImageUpload = []
    let loopLength;

    subImage.length < 10 ? loopLength = subImage.length + 1 : loopLength = subImage.length
    // 10개까지 

    for (let i = 0; i < loopLength; i++) {
      subImageUpload.push(
        <div key={uuidv4()} style={{ position: "relative", aspectRatio: "1/1"}}>
          <div className="subImage" onClick={() => { subImageModal(i, handleSubFileChange) }}>
            {getSubImageItem(subImage[i])}
          </div>
          {subImage[i] && // delete 부분
            <div className="deleteBackground">
              <div onClick={() => {
                let newSubImage = [...subImage]
                newSubImage.splice(i, 1)
                setSubImage(newSubImage)
              }}>
                삭제
              </div>
            </div>
          }
        </div>
      )
    }
    return (
      subImageUpload
    )
  }

  const deleteSelectedArtist = (e, i) => {
    e.preventDefault()
    let newArr = [...selectedCampaginMemberList]
    newArr.splice(i, 1);
    setSelectedCampaginMemberList(newArr)
  }

  const AddCampaginMember = (searchKeyword, selectedCampaginMemberList) => {
    if(!searchKeyword){
      modalShow({ message: "검색어를 입력해주세요", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    if(searchKeyword > 50){
      modalShow({ message: "영문50자, 한글25자 이내로 작성해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    const addCampaginMemberCallback = (unEnrolledArtist) => {
      setSelectedCampaginMemberList([...selectedCampaginMemberList, unEnrolledArtist])
      setSearchKeyword('')
    }
    addCampaginMemberModal(searchKeyword, addCampaginMemberCallback)
  }

  const checkKeyDown = (e, isSearchKeydown, searchKeyword, selectedCampaginMemberList) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      isSearchKeydown && AddCampaginMember(searchKeyword, selectedCampaginMemberList) // legcial scope 확인
    }
  };

  return (
    <Div inputFileImage={inputFileImage} inputFileImageBlue={inputFileImageBlue}>
      <div className="headerArea">
        <div className="header">내 캠페인 작업 등록</div>
        <div className="subHeader">캠페인 작업을 등록하고 매칭업을 시작해보세요.</div>
      </div>
      <form className="projectForm" id='projectForm' onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <div>
          <div>
            <p>브랜드 명 <span>*</span></p>
            <input
              type="text" placeholder="의뢰인 또는 의뢰 브랜드를 입력해주세요" value={project_brand}
              {...register("project_brand", { required: true, maxLength:60, onChange: () => { setProject_brand(getValues("project_brand")) }, })}
            />
            {errors.project_brand?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px'}}>의뢰인(브랜드) 명을 입력해주세요.</span>}
            {errors.project_brand?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px'}}>영문60자, 한글30자 이내로 작성해주세요.</span>}
          </div>

          <div>
            <p>캠페인 명  <span>*</span></p>
            <input type="text" placeholder="캠페인 명을 입력해주세요." value={project_name} {...register("project_name", { required: true, maxLength:60, onChange: () => { setProject_name(getValues("project_name")) } })} />
            {errors.project_name?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px'}}>캠페인 명을 입력해주세요.</span>}
            {errors.project_name?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px'}}>영문60자, 한글30자 이내로 작성해주세요.</span>}
          </div>

          {userType === 'client' &&
            <div>
              <p>캠페인 카테고리 <span>*</span></p>
              <div className="selectWrapper">
                <Controller
                  control={control}
                  name="project_category"
                  rules={{ required: true }}
                  valueAsNumber={true}
                  defaultValue={project_category} // onSubmit의 data.project_category 의 default value
                  render={() => (
                    <Select
                      onChange={(param) => { setProject_category(param.value);resetField('project_category');setValue("project_category", param.value) }}
                      options={archiveCategoryOptions}
                      defaultValue={archiveCategoryOptions.find(option => option.value === project_category)}
                      placeholder="캠페인 카테고리를 선택해주세요"
                      borderColor="#dcdcdc"
                      borderColorHover="#0053ff"
                      borderColorFocus="#0053ff"
                    />
                  )}
                />
                {errors.project_category?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px'}}>캠페인 카테고리를 선택해주세요.</span>}
              </div>
            </div>
          }

          <div>
            <p>캠페인 제작/촬영 날짜 <span>*</span></p>
            <div className="inputWithButton">
              <Controller
                control={control}
                name="project_start"
                rules={{ required: true, validate: () => {return dateRegExp.test(document.getElementById('project_start').value)} }}
                value={getValues("project_start")}
                defaultValue={project_start}
                render={({ field }) => (
                  <DatePicker
                    id="project_start"
                    inputClass="datePicker"
                    placeholder="YYYY/MM/DD"
                    format={"YYYY/MM/DD"}
                    value={project_start}
                    onChange={(date) => {field.onChange(new Date(date).toISOString())}}
                    // https://thewebdev.info/2021/12/05/how-to-use-react-datepicker-with-react-hooks-forms/
                    selected={field.value}
                  />
                )}
              />
              <div className="buttonNextToInput">
                <label htmlFor="project_start">
                  <img src={calenderIcon} alt="calender icon" />
                </label>
              </div>
            </div>
            {errors.project_start?.type === 'required' && <div><span style={{ color: "#EB5757", fontSize:'12px'}}>날짜를 선택해주세요.</span></div>}
            {errors.project_start?.type === 'validate' && <div><span style={{ color: "#EB5757", fontSize:'12px'}}>형식에 맞게 날짜를 입력해주세요.</span></div>}
          </div>

          {userType === 'client' &&
            <div>
              <p>소스 홈페이지 <span>*</span></p>
              <input type="text" placeholder="ex) www.jabble.co.kr" value={project_url}
                {...register("project_url", { required: true, maxLength:200, validate: (project_url) => { return project_url.substring(0, 4) === 'www.'; }, onChange: () => { setProject_url(getValues("project_url")) } })}
              />
              {errors.project_url?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px'}}>캠페인의 소스를 입력해주세요.</span>}
              {errors.project_url?.type === 'validate' && <span style={{ color: "#EB5757", fontSize:'12px'}}>' www. '으로 시작해야합니다.</span>}
              {errors.project_url?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px'}}>영문200자, 한글100자 이내로 작성해주세요.</span>}
            </div>
          }
          <div></div>

          {userType === 'client' &&

            <div className="underLine"></div>
          }

          {userType === 'client' &&
            <>
              <div style={{ gridColumn: "1/3", position: 'relative' }}>
                <p>콘텐츠 제작 참여자 등록하기</p>
                <div className="memberSearchInput">
                  <input
                    type="text"
                    placeholder="제작 참여자를 검색해보세요."
                    autoComplete="off"
                    className='project_member'
                    id='project_member'
                    value={searchKeyword}
                    ref={modalRef}
                    onKeyDown={(e) => checkKeyDown(e, true, searchKeyword, selectedCampaginMemberList, setSelectedCampaginMemberList)}
                    onChange={(e) => { setSearchKeyword(e.target.value); setProjectMemberContainer(true) }}
                  />
                  <label className="project_memberSearch" htmlFor="project_member" onClick={(e) => { e.preventDefault(); AddCampaginMember(searchKeyword, selectedCampaginMemberList) }}>
                    추가
                  </label>
                  <img src={zoom} alt="find icon" />
                </div>
                <ProjectMember AddCampaginMember={AddCampaginMember} showProjectMemberContainer={showProjectMemberContainer} searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} selectedCampaginMemberList={selectedCampaginMemberList} setSelectedCampaginMemberList={setSelectedCampaginMemberList} />
                {/* TODO: 추후 react-select/async-creatable 로 변경. 유저선택시 키보드 사용 가능.. */}
              </div>

              {/* 캠페인 제작 참여자 등록시 부분 */}
              {selectedCampaginMemberList.length > 0 ?
                <div className="memberContainer">
                  {selectedCampaginMemberList.map((user, index) => {
                    return (
                      <div key={uuidv4()} className="flexContainer">
                        <img src={user.user_profile || user.member_profile || userImage} alt="profile_image" />
                        <div className="name">{user.user_name || user.member_name}</div>
                        <div className="delete" onClick={(e) => { deleteSelectedArtist(e, index) }} style={{ backgroundImage: `url(${deleteButton})` }}></div>
                      </div>
                    )
                  })}
                </div>
                :
                <div className="noCampaignMember">캠페인 제작 참여자가 없습니다.</div>
              }
            </>
          }
          <div className="underLine"></div>

          <div className="uploadThumbnail">
            <p>썸네일 이미지 등록 <span>*</span></p>
            <input id="files" style={{ display: 'none' }} type="file" accept="image/*" key={thumbnail} onChange={(e) => { handleFileChange(e) }} />
            {thumbnail ? // input key를 통해 thumbnail state가 reset되었을 때 감지 가능
              <div className="thumbnailImage">
                <img src={typeof (thumbnail) === "object" ? URL.createObjectURL(thumbnail) : thumbnail} ref={fileInput} alt="file icon" />
                {/* img url인 경우 string, File 인경우 object */}
                <div>
                  <p onClick={() => { setThumbnail(null) }}>삭제</p>
                </div>
              </div>
              :
              <label htmlFor="files" >
                <div className="notUploaded">
                  <div /><p>썸네일 이미지</p><span>330x250px</span>
                </div>
              </label>
            }
          </div>

          <div className="tip">
            <p>• 썸네일 이미지는 해당 프로젝트를 대표할수 있는 1개의 이미지입니다</p>
            <p>• 썸네일 이미지는 서브이미지로 저장되지 않습니다</p>
          </div>

          <div className="uploadsubImage">
            <p>이미지/영상 등록 <span>*</span></p>
            <div>
              {getSubImageUpload()}
            </div>
          </div>

          <div className="tip">
            <p>• 이미지는 최소 4개, 최대 10개 등록 가능합니다</p>
            <p>• 영상의 경우 Youtube에 업로드된 영상만 등록 가능합니다</p>
            <p>• JABBLE에서 모니터링 또는 신고가 접수되는 경우, 포트폴리오가 임의로 삭제 처리될 수 있습니다.</p>
          </div>
        </div>

      </form>
      <div className="buttonBar">
        <div className="buttons">
          <button className="blueButton" form='projectForm' type="submit" >작성완료</button>
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  padding-bottom: 1px; // buttonBar 아래영역 margin 잡히게하기위해??, 없으면 흰색영역 생김
  .headerArea{
    *{text-align:center !important;}
  }
  .buttonBar{
    height: 80px;
    border-top: solid 1px #ddd;
    background-color: #fff;
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;
    .buttons{
      max-width: 1040px;
      width:90%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content:flex-end;
      align-items: center;
      .blueButton{
        width: 136px;
        height: 44px;
      }
    }
  }
  .underLine{
    grid-column: 1/3;
    margin: 4px 0 !important;
    border-top: solid 1px #f1f1f1 !important;
  }
  .projectForm{
    background: #fff;
    max-width: 1040px;
    width:90%;
    margin:40px auto;
    padding:42px 52px 58px;
    box-sizing:border-box;
    border-radius: 8px;
    border: solid 1px #ededed;
    text-align: left;
    > div{
      display:grid;
      grid-template-columns: 1fr 1fr;
      gap: 36px 60px;
    }

    select, input{
      border: 1px solid #dcdcdc;
      width:100%;
      height:50px;
      box-sizing:border-box;
      padding-left:16px;
      border-radius: 4px;
      color: #5d5d5d;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
    }

    .selectWrapper{
      height:50px;
    }

    // hover and focus style
    > div > input:hover, >div >input:focus{
      border: solid 1px #0053ff;
    }
    .memberSearchInput:hover, .rmdp-container:focus, .rmdp-container:hover{
      > input{
        border: solid 1px #0053ff;
      }
    }

    // react-select
    >div .css-126dsu1-control:hover{
      border: solid 1px #0053ff;
      background-color: #fff !important;
    }

    >div .css-1f09hfb-placeholder{
      color: #afafaf !important;
      font-weight: normal !important;
    }

    input::placeholder, .datePicker::placeholder{
      font-size: 14px;
      color: #afafaf;
      font-weight: normal;
    }
    
    p{
      height:20px;
      margin:0 0 10px;
      padding:0;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
      > span{
        color:#EB5757; // 강조 표시
      }
    }

    .uploadThumbnail{
      grid-column:1/3;
      > label:hover{
        .notUploaded{
          border: dashed 1px #0053ff;
          p, span{
            color: #0053ff;
          }
          > div{
            background-image: url(${props => props.inputFileImageBlue});
          }
        }
      }
      > label{
        max-width: 330px;
        width: 100%;
        height: 250px;
        display: block;
        box-sizing: border-box;
        cursor: pointer;
        .notUploaded{
          border: dashed 1px #aaa;
          width:100%;
          height:100%;
          display:flex;
          flex-direction:column;
          justify-content:center;
          align-items:center;
          box-sizing: border-box;
          border-radius: 4px;
          transition:0.2s;
          p{
            margin: 18px 0 6px;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: bold;
            color: #8a909c;
            transition:0.2s;
          }
          span{
            font-family: Pretendard;
            font-size: 13px;
            font-weight: 500;
            color: #8a909c;
            transition:0.2s;
          }
          div{
            background-image: url(${props => props.inputFileImage});
            width:20px;
            height:20px;
            transition:0.2s;
          }
        }
      }
      .thumbnailImage{
        width: 330px;
        height: 250px;
        position:relative;
        > img{
          width:100%;
          height:100%;
          object-fit: cover;
          border-radius: 4px;
        }
        > div{
          opacity: 0;
          transition:0.2s;
          position:absolute;
          top:0;
          bottom:0;
          right:0;
          left:0;
          border-radius: 4px;
          background-color:rgba(0,0,0,0.5);
          display:flex;
          justify-content:center;
          align-items:center;
          > p{
            padding: 7px 22px;
            border-radius: 17px;
            border: solid 1px #bbb;
            margin:0;
            font-size: 15px;
            font-family: Pretendard;
            font-weight: bold;
            color: #fff;
            cursor:pointer;
          }
        }
      }
      .thumbnailImage:hover{
        >div{
          opacity: 1;
        }
      }
    }

    .uploadsubImage{
      grid-column:1/3;
      > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 12px;
        border-radius: 4px;
        .deleteBackground{
          position:absolute;
          opacity:0;
          background: rgba(0,0,0,0.5);
          color:white;
          top:0;
          bottom:0;
          left:0;
          right:0;
          transition: 0.2s;
          border-radius: 4px;
          display:flex;
          justify-content:center;
          align-items:center;
          > div{
            padding: 7px 22px;
            border-radius: 17px;
            border: solid 1px #bbb;
            cursor:pointer;
          }
        }
        .deleteBackground:hover {
          opacity:1;
        }
        .subImage{
          width:100%;
          aspect-ratio:1/1;
          margin: 0 auto;
          border-radius: 4px;
          .uploadedImage{
            width:100%;
            height:100%;
            object-fit:cover;
            border-radius:4px;
            display: flex;
          }
          .uploadButton:hover{
            border: dashed 1px #0053ff;
            .selectFileIcon{
              background-image: url(${props => props.inputFileImageBlue});
            }
            p{
              color: #0053ff;
            }
          }
          .uploadButton{
            display:flex;
            justify-content:center;
            align-items:center;
            flex-direction:column;
            height:100%;
            border: dashed 1px #aaa;
            border-radius:4px;
            transition:0.2s;
            cursor:pointer;
            .selectFileIcon{
              width:20px;
              height:20px;
              background-image: url(${props => props.inputFileImage});
              transition:0.2s;
            }
            p{
              margin:18px 0 0;
              font-family: Pretendard;
              font-size: 14px;
              font-weight: bold;
              color: #8a909c;
              transition:0.2s;
            }
          }
          
          .playImage{
            width:60px;
            height:60px;
            top:calc(50% - 30px);
            left:calc(50% - 30px);
            filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(128deg) brightness(110%) contrast(102%);
          }
        }
      }
    }

    .tip{
      grid-column:1/3;
      max-width: 519px;
      min-width: 514px; // 글자 안깨지는 최소값
      width: 90%;
      border-radius: 4px;
      border: solid 1px #bad0ff;
      background-color: #f8fafc;
      padding:12px 24px;
      box-sizing: border-box;

      p{
        color: #0053ff;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.67;
        color: #0053ff;
        padding:0;
        margin:0;
      }
    }

    .memberSearchInput:focus{
      border-color:#0053ff;
    }
    .memberSearchInput{
      position: relative;
      width:100%;
      border-radius: 4px;
      margin-bottom:6px;
      .project_member{
        border: solid 1px #dcdcdc;
        padding-left:44px;
      }
      .project_member:focus{
        border-color:#0053ff;
      }
      .project_memberSearch{ // 추가 버튼
        position:absolute;
        top:calc(50% - 19px);
        right:16px;
        text-align: center;
        width: 66px;
        height: 32px;
        border-radius: 4px;
        border: solid 1px #e2e2e3;
        cursor:pointer;
        color: #303033;
        font-family: Pretendard;
        font-size: 13px;
        font-weight: bold;
        line-height: 32px;
      }
      img{
        position:absolute;
        top:calc(50% - 11px);
        left:16px;
        display:block;
      }
      .project_memberSearchWindow{
        position: absolute;
        width:280px;
        top:50px;
        min-height: 80px;
        border: 1px solid #D9D9D9;
        border-top: none;
        box-sizing: border-box;
        background-color: #FFF;
        z-index: 100;
      }
    }
   
    .inputWithButton{
      display: flex;
      justify-content: space-between;
      position: relative;
      width:100%;
      .rmdp-container{
        flex:1;
        .datePicker{
          width:100%;
        }
      }
    
      .buttonNextToInput{
        position:relative;
        display: block;
        width: 50px;
        height: 50px;
        background-color: #222222;
        margin-left: 8px;
        border-radius:4px;
        img{
          position:absolute;
          width:18px;
          height:20px;
          top:calc(50% - 9px);
          left:calc(50% - 10px);
        }
      }
    }
    .memberContainer{
      grid-column:1/3;
      padding-top: 4px; // .delete overflow시 안짤리게
      margin: -25px -14px 0; //top: total 20px(grid gap 36px + input margin bottom 5px + padding 4px - 25px = 20px)
      display: flex;
      overflow: auto;
    
      .flexContainer{
        width:52px;
        /* display: flex;
        justify-content: space-between;
        align-items: center; */
        margin-bottom:10px;
        position:relative;
        margin:0 14px;
        > img{
          width:52px;
          height:52px;
          object-fit:cover;
          display:block;
          border-radius:50%;
        }
        > .name{
          margin-top:6px;
          text-align: center;
          font-family: Pretendard;
          font-size: 12px;
          color: #666;
        }
        > .delete{
          position:absolute;
          width:20px;
          height:20px;
          top:-4px;
          right:-8px;
          cursor:pointer;
        }
      }
    }
    .noCampaignMember{
      height:51px; // 51px = 76(.memberContainer .flexContainer의 height+padding) - 25(.memberContainer의 margin -25px )
      margin:-14px 0 13px; // 28px = 14px grid gap 36px + input margin-bottom 6px;
      font-family: Pretendard;
      font-size: 12px;
      font-weight: 500;
      color: #999;
    }
  }
  @media (max-width: 900px) {
    .projectForm{
      padding: 32px 22px 58px;
      >div{
        gap: 36px 20px;
      }
      .uploadsubImage > div {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  @media (max-width: 720px) {
    .buttonBar{
      justify-content: center;
      max-width: 360px;
      margin: 0 auto 42px !important;
      padding: 0 18px;
      box-sizing:border-box;
      border-top:0;
      border-left:solid 1px #ededed;
      border-right:solid 1px #ededed;
      border-bottom:solid 1px #ededed;
      border-radius:0 0 8px 8px;
      bottom: -4px; // border-radius 밖으로 projectForm border left, right 삐져나오는것 안보이게하기위해.
      .buttons{
        justify-content:center;
      }
    }
    .projectForm{
      max-width: 360px;
      width: 100%;
      margin: 40px auto 0;
      padding: 24px 18px 10px;
      border-bottom: 0;
      border-radius:8px 8px 0 0;
      >div{
        display: block;
      }

      select, input{
        height:46px;
      }
    
      input{
        margin-bottom:32px;
      }

      .selectWrapper{
        height:46px;
        margin-bottom:32px;
      }

      .inputWithButton{
        .buttonNextToInput{
          width: 46px;
          height: 46px;
        } 
      }
        
      .underLine{
        margin:6px 0 38px!important;
      }

      .tip{
        display: flex;
        flex-direction: column;
        min-width: initial;
        max-width: initial;
        width: 100%;
        margin-top:18px;
        margin-bottom:32px;
        p{
          height:inherit;
        }
        li{
          margin-left: 0px;
          margin-top:10px;
        }
      }

      .memberSearchInput{
        input{
          margin-bottom:0px;
        }
        .project_memberSearch{
          top: calc(50% - 17px);
        }
        img{
          top:calc(50% - 10px);
        }
      }
      .memberContainer{
        margin-top:20px;
        margin-bottom:32px;
      }
      .noCampaignMember{
        margin-top:20px;
        height: 39px;
      }
      
      .uploadsubImage > div {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media (max-width: 420px) {
    width:100%;
    .projectForm{
      border: 0;
      border-radius:0;
      padding-left:0;
      padding-right:0;
      > div{
        margin: 0 auto;
        min-width:324px;
        width:324px;
      }
    }
    .buttonBar{
      margin: 0 auto !important;
      border:0;
      border-radius:0;
    }
  }

`