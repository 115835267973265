
import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useBlock } from "./useBlock";
import useAPI from "../api/useAPI";

const PaginationContext = createContext();

export const PaginationProvider = ({children}) => {
  const { getAPI } = useAPI()
  const { setBlock } = useBlock()

  const [apiData, changeApiData] = useState();
  const [updatedApiData, changeUpdatedApiData] = useState();
  const [isDataChanged, setDataChanged] = useState(false);
  // SearchResultPage
  // artistDetail -> artist의 project들
  // brand -> 해당 client의 project들
  // project -> 마이페이지 내작업공유.

  useEffect(()=>{
    setDataChanged(apiData?.length !== updatedApiData?.length)
  },[apiData, updatedApiData])

  const setApiData = async (url) => {
    let { data } = await getAPI(url)
    setBlock(false)
    changeApiData(data?.portpolio || data?.archive || data?.artists || data?.inspiration)
  }

  const setApiDataWidthPreviousData = async (url) => {
    let { data } = await getAPI(url)
    setBlock(false)
    let newData = data?.artists || data?.archive || data?.portpolio || data?.inspiration
    changeApiData([...apiData, ...newData])
    changeUpdatedApiData([...apiData, ...newData])
  }

  const checkDataChanged = (prev, next) => {
    for(let i = 0; i<next.length;i++){
      if(!prev.includes(next[i])){ return true }
    } 
    return false
    // 데이터가 절묘하게 한번에 불러오는 데이터 최대값과 같았을때 더보기 계속 떠있을수있어 data 변화여부 check함
  }

  const [artistUrl, setArtistUrl] = useState()
  const [artistData, changeArtistData] = useState([]);
  const [isArtistChanged, setArtistChanged] = useState(false)

  const setArtistData = async (url) => {
    // cursor 있으면 무조건 새로 호출해야함(더보기 버튼 누른것). 새로 호출후 기존데이터와 병합하기.
    // cursor 없으면 url 비교후 같으면 기존데이터 보내기, 다르면 url로 데이터 호출 + setUrl
    let cursor = url.split('&cursor=')
    if(cursor[1]){
      let { data } = await getAPI(url)
      setArtistUrl(cursor[0])
      let newData = data?.artists
      let dataChanged = checkDataChanged(artistData, newData)
      setArtistChanged(dataChanged)
      changeArtistData([...artistData, ...newData])
      setBlock(false)
    } else if(artistUrl !== url){
      let { data } = await getAPI(url)
      setArtistUrl(url)
      changeArtistData(data?.artists)
      setArtistChanged(true)
    }
    setBlock(false)
  }

  const [archiveUrl, setArchiveUrl] = useState()
  const [archiveData, changeArchiveData] = useState([]);
  const [isArchiveChanged, setArchiveChanged] = useState(false)

  const setArchiveData = async (url) => {
    // cursor 없으면 url 비교후 같으면 기존데이터 보내기, 다르면 url로 데이터 호출 + setUrl
    // cursor 있으면 무조건 새로 호출해야함(더보기 버튼 누른것). 새로 호출후 기존데이터와 병합하기.
    let cursor = url.split('&cursor=')
    if(cursor[1]){
      let { data } = await getAPI(url)
      setArchiveUrl(cursor[0])
      let newData = data?.archive
      let dataChanged = checkDataChanged(archiveData, newData)
      setArchiveChanged(dataChanged)
      changeArchiveData([...archiveData, ...newData])
    } else if(archiveUrl !== url){
      let { data } = await getAPI(url)
      setArchiveUrl(url)
      changeArchiveData(data?.archive)
      setArchiveChanged(true)
    }
    setBlock(false)
  }

  const [inspirationUrl, setInspirationUrl] = useState()
  const [inspirationData, changeInspirationData] = useState([]);
  const [isInspirationChanged, setInspirationChanged] = useState(false)

  const setInspirationData = async (url) => {
    // cursor 없으면 url 비교후 같으면 기존데이터 보내기, 다르면 url로 데이터 호출 + setUrl
    // cursor 있으면 무조건 새로 호출해야함(더보기 버튼 누른것). 새로 호출후 기존데이터와 병합하기.
    let cursor = url.split('?cursor=')
    if(cursor[1]){
      let { data } = await getAPI(url)
      setInspirationUrl(cursor[0])
      let newData = data?.inspiration
      let dataChanged = checkDataChanged(inspirationData, newData)
      setInspirationChanged(dataChanged)
      changeInspirationData([...inspirationData, ...newData])
    } else if(inspirationUrl !== url){
      let { data } = await getAPI(url)
      setInspirationUrl(url)
      changeInspirationData(data?.inspiration)
      setInspirationChanged(true)
    }
    setBlock(false)
  }
  
  const value = useMemo(
    () => ({ apiData, isDataChanged, setApiData, setApiDataWidthPreviousData, artistData, setArtistData, isArtistChanged, archiveData, setArchiveData, isArchiveChanged, inspirationData ,setInspirationData, isInspirationChanged}),
    /*eslint-disable */
    [artistData,apiData,isDataChanged, archiveData, inspirationData]
    /*eslint-enable */
  );

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  )
};


export const usePagination = () => {
  return useContext(PaginationContext);
};