

import { useAuth } from "../../auth/auth"
import useQuery from "../../api/useQuery";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import MypageHome from "./home";
import EditInfo from "./edit_info";
import WishList from "./wishList";
import Moodboard from "./moodboard";
import ProjectRoute from "./project";
import Matching from "./matching";
import QuestionRoute from "./question";
import { useBlock } from "../../customHook/useBlock";
import Logo from "../../images/opacityLogo.png"
import styled from "styled-components";
import userImage from "../../images/userImage.svg"
import goback from "../../images/goback.svg"
import { useWindowSize } from "../../customHook/useWindowSize";
import PageNotFound from "../pageNotFound";


export default function Mypage() {
  const { user, userType } = useAuth();
  const location = useLocation()
  const navigate = useNavigate()
  const [apiData, setApiData] = useQuery({ url: `/${userType}/mypage`, user: user })
  const { BlockUI } = useBlock()
  const isProjectUploadOrAdd =  location.pathname === '/mypage/project/update' || location.pathname === '/mypage/project/add'
  const { innerWidth } = useWindowSize()
  const mobileMypage =  innerWidth <= 900 && location.pathname !== '/mypage'
  // mypage에서 menu들 보이고, 목록 클릭시 안보이게.

  const navigationList = [
    {
      url: '/mypage/home',
      title: '프로필 정보',
    },
    {
      url: '/mypage/edit_info',
      title: '내 정보 수정',
    },
    {
      url: '/mypage/wishList',
      title: '위시리스트',
    },
    {
      url: '/mypage/moodboard',
      title: '무드보드',
    },
    {
      url: '/mypage/project',
      title: '내 작업 공유',
    },
    {
      url: '/mypage/matching',
      title: '매칭 스페이스',
    },
    {
      url: '/mypage/question',
      title: '1:1 문의하기',
    },
  ]

  return (
    <Div>
      <div className="mypage" style={isProjectUploadOrAdd ? {background: "#f8f8f8", maxWidth:"initial"} : {}}>
        <div className="mypageAside" style={isProjectUploadOrAdd || mobileMypage ? {display:"none"} : {}}>
          <div className="profile" onClick={() => { navigate('/mypage/home') }}>
          {userType === 'artists' &&
            <>
              <img src={apiData?.user_profile || userImage} alt="artist profile" />
              <div>
                <div className="user_name">{apiData?.user_name}</div>
                <div className="user_id">{apiData?.user_id}</div>
                <div className="user_type">Artist</div>
              </div>
            </>
          }
          {userType === 'client' &&
            <>
              <img className="clientImage" src={apiData?.user_profile || Logo} alt="client logo" />
              <div>
                <div className="user_name">{apiData?.company_name}</div>
                <div className="user_id">{apiData?.user_id}</div>
                <div className="user_type">Client</div>
              </div>
            </>
          }
          </div>

          <div className="mypageAsideItems">
            {navigationList.map((item) => {
              return (
                <div key={item.url} className={`${location.pathname.includes(item.url) && 'active'}`} onClick={() => { navigate(item.url) }}>
                  <div>
                    {item.title}
                  </div>
                </div>
              )
            })}
          </div>

        </div>
        <div className="mypageMain">
          <div className="back" style={location.pathname === '/mypage' ? {display:"none"} : {}} onClick={() => { navigate('/mypage')}}><img src={goback} alt="back"/></div>
          {apiData ?
            <Routes>
              <Route path="/" element={<MypageHome userInfo={apiData} setApiData={setApiData} />} />
              <Route path="home" element={<MypageHome userInfo={apiData} setApiData={setApiData} />} />
              <Route path="edit_info" element={<EditInfo userInfo={apiData} />}></Route>
              <Route path="wishList" element={<WishList />}></Route>
              <Route path="moodboard" element={<Moodboard />}></Route>
              <Route path="project/*" element={<ProjectRoute />}></Route>
              <Route path="matching" element={<Matching />}></Route>
              <Route path="question/*" element={<QuestionRoute />}></Route>
              <Route path="/*" element={<PageNotFound />}></Route>
            </Routes>
            :
            <BlockUI />}
        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
/* margin-bottom:-100px; */
.mypage {
  max-width: 1400px;
  width: 100%;
  min-width: 324px;
  margin: 0 auto;
  display: flex;
  text-align: center;

  > .header{
    margin-top: 40px;
    margin-bottom:20px;
    font-size: 1.5rem;
  }

  .mypageAside {
    width: 100%;
    max-width: 220px;
    padding-bottom: 50px;
    margin-bottom: 54px; // 문의하기로부터 footer까지 최소거리 150px 하기위해.
    text-align: left;
    margin-right: 40px;
    margin-left: 60px;

    .profile{
      margin-top:56px;
      cursor:pointer;
      img{
        display: block;
        width: 116px;
        height:116px;
        object-fit: cover;
        border-radius: 50%;
        box-sizing:border-box;
      }
      .clientImage{
        width: 182px;
        height:113px;
        border: solid 1px #e8e8e8;
        border-radius: 0;
        object-fit: contain;
        padding:5px;
      }
      .user_name{
        margin:22px 0 6px;
        font-family: Pretendard;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.04px;
        color: #222;
      }
      .user_id{
        font-family: Pretendard;
        font-size: 12px;
        color: #606063;
      }
      .user_type{
        margin:18px 0 34px;
      }
    }
  }

  .mypageAsideItems{
    border-top: solid 1px #ebebeb;
    padding:26px 0;
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      div{
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        color: #424242;
        margin-bottom:30px;
  
        img{
          margin: 0 20px 0 40px;
          vertical-align: middle;
          width:20px;
          height:20px;
        }
      }
    }

    .active {
      >div{
        color: #0053ff;
        position:relative;
      }
      >div:after{
        content:"";
        display:block;
        position:absolute;
        width: 100%;
        height: 1px;
        border-bottom: solid 2px #0053ff;
      }
    }
  }


  .mypageMain {
    flex: 1;
    position:relative; // PageNumber Component 위치 조정 위해 필요
    .back{
      display: none;;
    }
    .header{
      font-family: Pretendard;
      font-size: 26px;
      font-weight: bold;
      margin:56px 0 12px;
      text-align:left;
    }
    .subHeader{
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.02px;
      color: #606063;
      text-align:left;
    }
    .underLine{
      margin:38px 0 32px;
      border-top: solid 1px #000;
    }
  }
}

  @media (max-width: 1200px) {
    .mypage{
      .mypageAside{
        max-width: 182px;
        margin-left: 40px;
      }
    }
  }

  @media (max-width: 900px) {
    .mypage{
      display:block;
      .mypageMain{
        margin: 0 auto;
        min-width: 324px;
        .back{
          padding: 0 18px;
          box-sizing:border-box;
          display:flex;
          height:56px;
          align-items:center;
          img{
            width:11px;
            height:20px;
          }
        }
        .header{
          margin-top:10px;
        }
      }
      .mypageAside{
        margin: 0px;
        margin-bottom:24px;
        padding-bottom:0px;
        max-width:initial;
        .mypageAsideItems{
          padding-left: 18px;
          border-top: solid 10px #f7f7f7;
          >div{
            div:hover{
              color: #0053ff;
            }
          }
        }
        .profile{
          padding-left: 18px;
          margin-top:28px;
          display: flex;
          img{
            margin-right:18px;
          }
        }
      }
    }
  }
`
