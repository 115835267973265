

import useHeader from "./useHeader"
import { objectKeyChangeFromXtoY } from "../converter"
import { useLocation, useNavigate } from "react-router-dom"

export default function useProjectAPI(){
  const { headers } = useHeader()
  const location = useLocation()
  const navigate = useNavigate()
  
  const getUrl = (user, query) => {
    let newQuery = query? query: ''
    if(user.data.user_privilege === 1){
      return `/api/artists/portpolio${newQuery}`
    } else {
      return `/api/client/campaign${newQuery}`
    }
  }

  const adapterOn = (data, user) => {
    // data = object
    if(user.data.user_privilege === 1){
      delete data.project_member;
      delete data.project_category;
      return objectKeyChangeFromXtoY(data, 'project', 'portpolio')
    } else {
      return objectKeyChangeFromXtoY(data, 'project', 'archive')
    }
  }

  // const adapterOff = (data, user) => {
  //   // data.portpolio = array -> map필요
  //   if(user.data.user_privilege === 1){ // artists
  //     let newPortpolio = data.portpolio.map((data) => {return objectKeyChangeFromXtoY(data, 'portpolio', 'project')})
  //     data.projects = newPortpolio
  //     delete data.portpolio;

  //     return data
  //   } else { // client
  //     let newArchive = data.archive.map((data) => {return objectKeyChangeFromXtoY(data, 'archive', 'project')})
  //     data.projects = newArchive
  //     delete data.archive;
  //     return data
  //   }
  // }

  async function getProjectAPI(url, user){
    return await fetch(`/api${url}`, {
      method: "GET",
      headers: await headers(user),
    })
    .then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
    .then(res => {
      if (res.status === 200) {
        return user.data.user_privilege === 1 ? objectKeyChangeFromXtoY(res.body.data, 'portpolio', 'project') : objectKeyChangeFromXtoY(res.body.data, 'archive', 'project')
      } else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }
  // 내작업 공유 수정시 사용

  async function putProjectAPI(data, user){
    let url = getUrl(user)
    let convertedData = adapterOn(data, user)
  
    return await fetch(url, {
      method: "PUT",
      headers: await headers(user),
      body:JSON.stringify(convertedData)
    })
    .then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
    .then(res => {
      if (res.status === 200) {
        return res.body
      } else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }

  async function postProjectAPI(data, user){
    let url = getUrl(user)
    return await fetch(url, {
      method: "POST",
      headers: await headers(user),
      body:JSON.stringify(adapterOn(data, user))
    })
    .then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
    .then(res => {
      if (res.status === 200) {
        return res.body
      } else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }

  async function deleteProjectAPI(idx, user){
    let url = getUrl(user, `?idx=${idx}`)
    return await fetch(url, {
      method: "DELETE",
      headers: await headers(user),
    })
    .then(async (res) => {
      return { status: res.status, body: await res.json() }
    }
    )
    .then(res => {
      if (res.status === 200) {
        return res.body
      } else {
        let { detail } = res.body
        let responseCode = detail.split('_')[0]
        let responseMessage = detail.split('_')[1]
        let result = false
        navigate(location.pathname, {
          state:{errorStatusCode: responseCode}
        });
        return { responseCode,responseMessage, result }
      }
    })
  }


  return { getProjectAPI, putProjectAPI, postProjectAPI, deleteProjectAPI}
}
