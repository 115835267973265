import Banner from "../../components/banner/banner";
import styled from "styled-components";
import { useWindowSize } from '../../customHook/useWindowSize'
import QuestionImage from '../../images/questionImage.svg'
import Arrow from '../../images/arrowForQuestion.svg'

import { useState } from "react";



export default function QNA() {
  const { innerWidth } = useWindowSize()
  const [currentIdx, setIdx] = useState()

  const style = {
    height: innerWidth > 720 ? "300px" : "180px",
    textAlign: "center"
  }

  const questionAndAnswer = [
    {
      question: "자블은 어떤 서비스 인가요?",
      answer: "자블은 다양한 카테고리의 브랜드 광고 콘텐츠와 광고 제작 전문 아티스트들을 소개하고 연결시켜주는 데이터 베이스 기반 디지털 매칭 플랫폼 입니다."
    },
    {
      question: "자블은 누가 이용하나요?",
      answer: "패션, 광고, 엔터테인먼트 중심으로 브랜드, 매거진, 광고 대행사, 프로덕션, 에이전시, 제작 전문 아티스트들이 이용하고 있습니다."
    },
    {
      question: "자블은 어떨 때 사용하면 좋은가요?",
      answer: "자블은 광고 콘텐츠 제작을 필요로 하는 모두를 위한 서비스 입니다. 패션, 광고, 엔터테인먼트 중심으로 브랜드들의 최신 광고 콘텐츠 및 제작 전문 아티스트들의 포트폴리오를 한눈에 만나볼 수 있습니다. 또한 자블 Inspiration 칼럼을 통해 영감을 얻을 수 있습니다."
    },
    {
      question: "자블에 어떤 기능들이 있나요?",
      answer: "제작 전문 아티스트 매칭, 무드보드, 위시리스트를 활용하여 광고 콘텐츠 제작 전반에 있어 시간 단축 및 효율을 극대화 할 수 있습니다."
    },
    {
      question: "제휴 및 파트너쉽이 좋은 점은 무엇인가요?",
      answer: "다양한 카테고리의 파트너사들과 캠페인 프로젝트 진행, 동종업계 네트워킹, 프로젝트 추천 및 제안 등 다양한 혜택이 있습니다."
    },
    {
      question: "제휴 및 파트너쉽은 어떻게 진행 되나요?",
      answer: "웹사이트 하단에 제휴 및 업무협약을 클릭하여 폼을 작성해주시면, 내부 검토 후 순차적으로 연락드리고 있습니다."
    },
  ]

  return (
    <Div>
      <Banner location="QNA" style={style} />
      <div className="QNAWrapper">
        <div className="title"> 자주 묻는 질문</div>
        <div className="QNA">
          {questionAndAnswer.map((QNA, index) => {
            return (
              <div key={QNA.question+QNA.answer}>
                <div className="question" onClick={()=>{setIdx(currentIdx === index ? null : index)}} style={currentIdx === index ? {borderBottom:'0px'} : {}}>
                  <img src={QuestionImage} alt="question mark" />
                  <span>
                  {QNA.question}
                  </span>
                  <img src={Arrow} className="arrow" alt="arrow" style={currentIdx === index ? {transform:"rotate(180deg)", transition:'0.2s'} : {transform:"rotate(0deg)"}}/>
                </div>
                <div className="answer" style={currentIdx === index ? {display: "block"} :{display: "none"}}>
                  {QNA.answer}
                </div>
              </div>
            )
          })}

        </div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  margin-bottom:138px;
  .QNAWrapper{
    max-width:1280px;
    min-width:324px;
    width:90%;
    margin:54px auto 0;
    .title{
      font-family: Pretendard;
      font-size: 26px;
      font-weight: bold;
      line-height: 1.15;
      letter-spacing: -0.24px;
      padding-bottom:31px;
      border-bottom:1px solid #000;
    }
    .QNA{
      .question{
        height:95px;
        display:flex;
        align-items:center;
        border-bottom: solid 1px #e0e0e0;
        cursor:pointer;
        img{
          width:40px;
          height:40px;
          margin-right:12px;
        }
        .arrow{
          width: 20px;
          height: 12px;
          margin-right:25px;
          margin-left:auto;
        }
        span{
          font-family: Pretendard;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: -0.25px;
          color: #303033;
          margin-right:10px;
        }
      }
      .answer{
        background-color: #f5f5f5;
        padding:21px 17px;
      }
    }
  }
 

  @media (max-width: 720px) {
    margin-bottom:79px;
  }
`