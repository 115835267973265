import { passwordRegExp } from '../../../components/regex';
import { useState } from 'react';
import EditInfoForm from './editInfoForm'
import { useForm } from "react-hook-form";
import useAPI from '../../../api/useAPI';
import styled from 'styled-components';
import { useModal } from '../../../components/modal/modal';
import { useAuth } from '../../../auth/auth';
import { useBlock } from '../../../customHook/useBlock';

export default function EditInfo({ userInfo }) {
  const { user, userType } = useAuth()

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [changeInfo, setChangeInfo] = useState(false)
  const [userData, setUserData] = useState({}) // 비번검증 후 return 값
  const { modalShow, modalClose } = useModal()
  const { setBlock } = useBlock()
  const { postAPI } = useAPI()

  const onSubmit = async (submitData) => {
    setBlock(true)
    let url = `/user/auth`
    let { data, result } = await postAPI(url, user, submitData)
    if (result === 'success') {
      // mypage 호출해서 state로 변경
      data.user_pw = submitData.user_pw
      setUserData(data)
      setChangeInfo(true)
    } else {
      modalShow({ message: '비밀번호를 다시 확인해주세요.', actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
    }
    setBlock(false)
  }

  return (
    <Div>
      <div className="header">내 정보 수정</div>
      <div className="subHeader">수정할 내용을 입력해주세요.</div>
      <div className="underLine" />
      {!changeInfo ?
        <div className="passwordConfirm">
          <div className='label'>
            <label>비밀번호</label>
          </div>
          <form id='passwordConfirm' onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input type="password" placeholder="비밀번호를 입력해주세요" autoComplete="off" {...register("user_pw", { required: true, pattern: { value: passwordRegExp } })} />
              <div className="errorMessage">
                {errors.user_pw?.type === 'pattern' && <span>영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요.</span>}
                {errors.user_pw?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
              </div>
            </div>
            <button className="blackButton" style={{ width: '92px', height: '48px', fontSize: '15px'}} form='passwordConfirm' type="submit">확인</button>
          </form>
        </div>
        :
        <div>
          <EditInfoForm signupType={userType} user={user} userData={userData} userInfo={userInfo} />
        </div>
      }
    </Div>
  )
}

const Div = styled.div`
  margin-right: 40px;
  .passwordConfirm{
    .label{
      text-align: left;
      margin:8px 0 12px;
      font-size: 14px;
      font-family:Pretendard;
      font-weight: bold;
      letter-spacing: -0.22px;
      color: #4d4d4d;
    }
    form{
      display:flex ;
      input{
        width: 380px;
        height: 48px;
        border-radius: 4px;
        border: 1px solid #ebebeb;
        padding-left:15px;
        box-sizing: border-box;
        background-color: #f8f8f8;
        margin-right:8px;
        margin-bottom:10px;
      }
      input::placeholder{
        font-size: 14px;
        letter-spacing: -0.22px;
        color: #b1b3b3;
      }

    }
    .errorMessage{
      height:10px;
      text-align:left;
      color:#EB5757;  // 경고 메세지
    }
  }
  @media (max-width: 900px) {
    margin-right: 0px;
    margin-bottom:207px;
    padding: 0 18px;
    box-sizing:border-box;
  }
  @media (max-width: 520px) {
    .passwordConfirm{
      form{
        input{
          width:calc(100% - 98px);
          max-width: calc(380px - 98px);
          min-width: calc(324px - 98px);
        }
      }
    }
  }
`