
import Logo from './logo'
import Nav from './nav'
import AsideNav from './asideNav'
import SmallSearchInputForNav from '../search/smallSearchInputForNav'
import styled from 'styled-components'

function Gnb() {
  return (
    <Div>
      <div className="flexWrapper">
        <div className="flexLeft">
          <Logo/>
          <Nav/>
        </div>
        <div className="flexRight">
          <div className="smallSearchInput">
            <SmallSearchInputForNav/>
          </div>
          <AsideNav/>
        </div>
      </div>
    </Div>
  );
}


export default Gnb;

const Div = styled.div`

  width:100%;
  min-width: 324px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  border-bottom: 1px solid #e9e9e9 ;

  // sticky Nav 만들기 위한 Tag
  > .flexWrapper{
    max-width:1400px;
    width:100%;
    height:80px;
    margin:0 auto;
    display: flex;
    justify-content:space-between;
    text-align: center;
  
    >.flexLeft{
      display: flex;
      justify-content: flex-start;
    }
    >.flexRight{
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @media (max-width: 1500px) {
    & >.flexWrapper {
      width:90%;
    }
  }

  @media (max-width: 1250px) {
    & >.flexWrapper{
      .smallSearchInput{
        display: none;
      }
    }
  }

  @media (max-width: 420px) {
    .flexWrapper{
      height:56px;
    }
  }
`