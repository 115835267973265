import ArrangeBar from "../../../components/selectSearchBar";
import CreativeItem from "../../../components/archive/creativeItem";

import styled from 'styled-components';
import { useEffect, useState } from 'react'
import { archiveCategory } from '../../../listsAndObjects';
import { toSnakeCase } from '../../../converter';

import { useAuth } from '../../../auth/auth';
import { useScrollToTop } from '../../../customHook/userScrollToTop';
import { useNavigate } from 'react-router-dom';
import { useBlock } from '../../../customHook/useBlock';
import PageNumber from "../../../components/pageNumber"
import useAPI from "../../../api/useAPI"
import { useModal } from "../../../components/modal/modal";
import checkIcon from "../../../images/checkIcon.svg"

export default function Moodboard() {

  const { user } = useAuth()
  const { getAPI, putAPI } = useAPI()
  const { setBlock } = useBlock()
  const { modalShow, modalClose } = useModal()
  const navigate = useNavigate()
  const [archiveTypeIndex, setArchiveTypeIndex] = useState(0)
  const [sort, setSort] = useState(0) // 0 이름순, 1 등록순
  const [filter, setFilter] = useState(1)
  const [selectPageno, setSelectPageno] = useState(0)
  const [apiData, setApiData] = useState([])
  const [total_pageno, setTotal_pageno] = useState(1)
  const [reload, setReload] = useState(Math.random())
  const [isEditMode, setEditMode] = useState(false)
  const [editChecked, setEditChecked] = useState([])
  const { scrollToTop } = useScrollToTop()

  let archiveType = Object.keys(toSnakeCase(archiveCategory)).find(key => toSnakeCase(archiveCategory)[key] === archiveTypeIndex) // photographer 등 직업

  // archiveTypeIndex 로 ArchiveType 찾기
  let url = `/user/moodboard?category=${archiveTypeIndex}&sort=${sort}&filter=${filter}&pageno=1`

  useEffect(() => {
    setBlock(true)
    const fetchAPI = async () => {
      let { data } = await getAPI(url, user)
      setApiData(data?.archive)
      setTotal_pageno(data?.total_pageno)
      setSelectPageno(0)
      setBlock(false)
      setEditMode(false)
      setEditChecked([])
      scrollToTop()
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reload])

  useEffect(() => {
    if (!selectPageno) { return }
    setBlock(true)
    const fetchAPI = async () => {
      let newUrl = url + `&pageno=${selectPageno}`
      let { data } = await getAPI(newUrl, user)
      setApiData(data?.archive)
      setTotal_pageno(data?.total_pageno)
      setBlock(false)
      scrollToTop()
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno])

  const setArchiveType = (value) => {
    if (value === 'archive') {
      setArchiveTypeIndex(0)
      return
    }
    setArchiveTypeIndex(toSnakeCase(archiveCategory)[value])
  }

  const deleteMoodboard = async (e, editChecked, user) => {
    e.stopPropagation()
    if(editChecked.length === 0) return
    if (user) {
      for(let i = 0;i<editChecked.length;i++){
        let url = `/user/moodboard?idx=${editChecked[i]}`
        let { result } = await putAPI(url, user)
        if (result !== 'success') {
          modalShow({ message: `무드보드 삭제에 실패했습니다.`, submessage:'잠시 후 다시 시도해주세요.', actionButton: <div onClick={() => { modalClose() }} className="blackButton">확인</div> })
          setReload()
          break
        }
      }
      setReload()
    }
  }

  const archiveList = apiData?.map((archiveData) => {
    if(isEditMode){
      let checkedIndex = editChecked.indexOf(archiveData.archive_idx)
      return (
        <div key={archiveData.archive_idx} className="editBox" onClick={
          checkedIndex>=0 ? 
          ()=>{
            let newEditChecked = [...editChecked]
            newEditChecked.splice(checkedIndex, 1)
            setEditChecked(newEditChecked)
          }
          : 
          ()=>{setEditChecked([...editChecked, archiveData.archive_idx])}}
          >
          <div className="checkImage">
            <div style={checkedIndex>=0 ? {backgroundImage: `url(${checkIcon})`, opacity:'1'} : {backgroundImage: `url(${checkIcon})`, opacity:'0'}}></div>
          </div>
          <div className="editButton" style={checkedIndex>=0 ? {opacity:'1'} : {}}/>
          <CreativeItem key={archiveData.archive_idx} creativeData={archiveData} hoverImage={true}></CreativeItem>
        </div>
      )
    } else {
      return (
        <CreativeItem key={archiveData.archive_idx} creativeData={archiveData} hoverImage={true}></CreativeItem>
      )
    }
  })

  return (

    <Div>
      <div className="header">무드보드</div>
      <div className="subHeader">나만의 무드보드로 영감을 얻어보세요.</div>
      <div className="underLine" />
      <ArrangeBar urlPath={archiveType} sort={sort} setSort={setSort} setSearchCategory={setArchiveType} searchCategory={archiveType} filter={filter} setFilter={setFilter}></ArrangeBar>
      {apiData.length > 0 ?
        <>
          <div className="editArea">
            {!isEditMode ?
              <div className="edit" onClick={() => { setEditMode(true) }}>
                편집
              </div>
              :
              <div>
                <span onClick={() => { setEditChecked([]) }}>선택해제</span>
                <span onClick={(e) => { deleteMoodboard(e, editChecked, user) }}>삭제</span>
                <span onClick={() => { setEditMode(false); setEditChecked([]) }}>취소</span>
              </div>
            }
          </div>
          <div className="archiveList">{archiveList}</div>
        </>
        :
        <div className="noContent">
          <span>🤔</span>
          <p>
            등록한 무드보드가 없습니다.<br />
            아카이브를 둘러보시겠습니까?
          </p>
          <div className="blueButton" onClick={() => { navigate('/archive') }}>
            아카이브 둘러보기
          </div>
        </div>
      }
      {total_pageno >= 2 && <PageNumber style={{ marginTop: '40px' }} total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}


const Div = styled.div`
  margin:0 auto 120px;
  width: 100%;
  text-align:left;
  padding-right:40px;
  box-sizing:border-box;
  .delete{
    display: block !important;
  }
  .editArea{
    margin:18px 0 16px;
    text-align:right;
    *{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.04px;
    }
    .edit{
      color: #0053ff;
      cursor:pointer;
    }
    span{
      margin-left:20px;
      color: #999;
      cursor:pointer;
    }
  }

  .archiveList{
    max-width: 1400px;
    width:100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap:56px 26px;
    .editBox{
      position: relative;
      cursor:pointer;
      .checkImage{
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: solid 1px #dedee1;
        background-color: #fff;
        top:12px;
        left:12px;
        z-index:2;
        > div{
          transition:0.2s;
          height:100%;
          width:100%;
        }
      }
      .editButton{
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        aspect-ratio: 338 / 255;
        position: absolute;
        z-index:1;
        opacity: 0;
        transition: 0.2s;
      }
    }
    .editBox:hover{
      .editButton{
        opacity: 1;
      }
    }
  }

  .noContent{
    margin-top:28px;
  }

  @media (max-width: 1200px) {
    width:100%;
  }
  @media (max-width: 900px) {
    padding: 0 18px;
    box-sizing:border-box;
  }
  @media (max-width: 720px) {
    .archiveList{
      grid-template-columns: 1fr 1fr;
      margin-top: 31px;
    }
    .editArea{
      margin-top: -30px;
    }
    .noContent{
      margin-top:76px;
    }
  }
  @media (max-width: 420px) {
    .archiveList{
      grid-template-columns: 1fr;
    }
  }
`
