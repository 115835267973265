import styled from "styled-components";
import { useModal } from "./modal";
import { useWindowSize } from '../../customHook/useWindowSize';

export default function DeleteConfirmModal({ handleDelete, deleteIndex}){

  const { modalClose } = useModal()
  const { innerWidth } = useWindowSize()

  return(
    <Div innerWidth={innerWidth}>
      <div className="message">내 작업(캠페인 프로젝트) 삭제</div>
      <div className="submessage">프로젝트 삭제시 복구할 수 없습니다.<br/>정말 삭제하시겠습니까?</div>

      <div className="buttons">
        <div className="whiteButton" onClick={()=>{modalClose()}}>취소</div>
        <div className="blackButton" onClick={()=>{handleDelete(deleteIndex)}}>삭제</div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  max-width: 400px;
  width: calc(${props => props.innerWidth}px * 0.8);
  height: calc(${props => props.height}px * 0.9);
  max-height: 220px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding:52px 0 24px;
  box-sizing:border-box;
  overflow: auto;
  .buttons{
    display: flex;
    justify-content: center;
    >div{
      width: 164px;
      height: 48px;
      margin: 28px 5px 0;
    }
  }

  @media (max-width: 720px) {
    width:324px;
    height:178px;
    padding:32px 0 20px;
    .buttons{
      >div{
        width: 134px;
        height: 48px;
        margin: 16px 5px 0;
      }
    }
  }
`
