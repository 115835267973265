import { useState, useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import { useScrollToTop } from '../../customHook/userScrollToTop';

import CreativeItem from '../../components/archive/creativeItem';

import CreativeImages from '../../components/creatives/creativeImages'
import { useAuth } from "../../auth/auth";
import useAPI from '../../api/useAPI';
import { usePagination } from '../../customHook/usePagination';
import { useBlock } from '../../customHook/useBlock';

import ProfileAside from '../../components/profileAside';
import styled from 'styled-components';
import { useWindowSize } from '../../customHook/useWindowSize';


export default function ArtistDetail() {
  const location = useLocation();
  const navigate = useNavigate()
  const { user } = useAuth();
  const { getAPI } = useAPI()
  const {apiData, setApiData, setApiDataWidthPreviousData, isDataChanged} = usePagination()
  const [searchParams] = useSearchParams();
  const { scrollToTop } = useScrollToTop()
  const { innerWidth } = useWindowSize()


  const { setBlock } = useBlock()

  const [cursor, setCursor] = useState(0)

  let user_idxForViewCount = user ? user.data.user_idx : 0 // view count용
  let { user_idx } = useParams() // 해당 user_idx로 detail 불러옴

  const [profileData, setProfileData] = useState()

  let url = `/artists/detail?artists_idx=${user_idx}`
  let projectIdParam = searchParams.get('projectID')

  useEffect(()=>{
    setBlock(true)
    setApiData(url)
    const fetchAPI = async () => {
      let url = `/artists/profile?artists_idx=${user_idx}&user_idx=${user_idxForViewCount}`
      let { data } = await getAPI(url)
      setProfileData(data)
    }
    fetchAPI()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.pathname])
  // sideSearchModal에서 url바뀔때 rendering안되어 dependency 추가


  useEffect(()=>{
    if(!cursor){return}
    let newUrl = url+`&cursor=${cursor}`
    setBlock(true)
    setApiDataWidthPreviousData(newUrl)
    // 이전데이터에 새 데이터 더해서 data return하는 함수
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cursor])

  const getProject = (projects) => {
    if(projects.length === 0) return
    if (!projectIdParam) { // projectID param 없을때 thumbnail rendering
      return (
        <>
          <div className="title">{profileData.user_name}님의 포트폴리오</div>
          <div className="portpolioList">            
            {projects.map((project)=>{
              if(project.portpolio_idx) return <CreativeItem key={project.portpolio_idx} creativeData={project} redirect={`/artist/${user_idx}?projectID=${project.portpolio_idx}`}/> 
              else if(project.archive_idx) return <div key={project.archive_idx}></div> // usePagination의 apiData brand, artist, archive에서 사용되어 archive_idx가 그전데이터로 저장된 경우 key값 error뜸.
              else return <div key={project.user_idx}></div> // apiData에 user_idx가 들어있는 경우
            })}
          </div>
          {apiData?.length % 6 === 0 && apiData?.length >= 6 && isDataChanged &&
            <div className="viewMore" style={{ marginTop: "30px" }} onClick={() => { user ? viewMore(apiData[apiData.length-1]): navigate('/start')}}>더보기</div>
          }
        </>
      )
    } else {  // projectID에 해당하는 image들 rendering.
      let getProjectByProjectIdx = projects.find(project => `${project.portpolio_idx}` === projectIdParam);
      scrollToTop()
      if (getProjectByProjectIdx) {
        // query에 해당하는 project가 있을때
        return (
          <CreativeImages brand={getProjectByProjectIdx.portpolio_brand} name={getProjectByProjectIdx.portpolio_name} date={getProjectByProjectIdx.portpolio_start} images={getProjectByProjectIdx.sub_img} redirectUrl={window.location.pathname}></CreativeImages>
        )
      } else {
        navigate(location.pathname, {
          state: { errorStatusCode: '40601' }
        });
      }
    }
  }

  const viewMore = (lastItem) => {
    setCursor(lastItem.portpolio_idx)
  }


  return (
    <>
      <Div>
        <div className="banner" style={projectIdParam && innerWidth <= 900 ? {display:'none'} : {}}/>
        {apiData && profileData &&
          <div className="artistDetailWrapper">
            <ProfileAside profileData={profileData} type="artist" smallProfileForMobile={projectIdParam && innerWidth <= 900}/>
            <div className="portpolioArea">
              {getProject(apiData)}
            </div>
          </div>
        }
      </Div>
    </>
  );

}

const Div = styled.div`
  .banner {
    width:100%;
    height: 220px;
    background-image: linear-gradient(to top, #6b6b6b, #424242);
  }

  .artistDetailWrapper {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto 71px;
    display: flex;
    justify-content: space-between;

    .portpolioArea {
      width:100%;
      min-width: 324px;
      margin-left:43px;
      > .title{
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.04px;
        color: #222;
        padding:40px 0 22px;
        border-bottom: solid 1px #ededed;
      }
      .portpolioList{
        margin-top:30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:33px 21px;
      }
    }
  }

  @media (max-width: 1500px) {
    .artistDetailWrapper{
      width:95%;
    }
  }

  @media (max-width: 1000px) {
    .artistDetailWrapper{
      .portpolioArea {
        .portpolioList{
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .artistDetailWrapper{
      width:100%;
      display: block;
      > div{
        height: 414px; // profileAside가 위로 올라갔을때의 높이
        border-bottom:10px solid #f7f7f7;
        box-sizing:border-box;
      }
      .portpolioArea {
        width:90%;
        margin:32px auto 0;
        height:initial;
        border-bottom:0;
        > .title{
          margin:0 auto;
          padding: 0 0 24px;
        }
        .portpolioList{
          margin:0 auto;
          padding-top:24px;
        }
      }
    }
  }

  @media (max-width: 580px) {
    .artistDetailWrapper{
      .portpolioArea {
        .portpolioList{
          grid-template-columns: 1fr;
        }
      }
    }
  }
`
