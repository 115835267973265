import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useHeader from "./useHeader";

const useQuery = ({ url, user })  => {
  const navigate = useNavigate();
  const location = useLocation()
  const [apiData, changeApiData] = React.useState();
  const [apiURL, setApiURL] = React.useState('/api'+url);
  const [reload, setReload] = React.useState(Math.random())
  const { headers } = useHeader()


  React.useEffect(() => {
    async function fetchAsync(){
      fetch(`${apiURL}`, {
        headers: await headers(user)
      })
      .then(async(data) => {
        if(data.status === 200){
          return {status:data.status, body:await data.json()}
        } else {
          let body = await data.json()
          let status = body.detail.split('_')[0]
          let errMessage = body.detail.split('_')[1]
          if(errMessage){
            return {status, errMessage}
          } else {
            errMessage = status
            status = '40101'
            return {status, errMessage} // {detail: 'Not authenticated'} 인 경우
          }
        }
      })
      // .then(({ code, status, ...apiData }) => {
        .then((res) => {
        if (res.status > 400) {
          navigate(location.pathname, {
            state:{errorStatusCode: res.status}
          });
        } else {
          changeApiData(res.body.data);
        }
      });
    } 
    fetchAsync()
  }, /*eslint-disable */[apiURL, user, reload]/*eslint-disable */
  );

  const setApiData = (newUrl) => {
    newUrl && setApiURL(newUrl)
    setReload(Math.random())
  }
  return [apiData, setApiData]
};

export default useQuery;
