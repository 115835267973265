

import ArrangeBar from "../../components/selectSearchBar";
import ArtistCard from '../../components/artistProfile/artistCard';
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../../auth/auth";
import { usePagination } from '../../customHook/usePagination';
import { useBlock } from '../../customHook/useBlock';
import { occupationIndex } from '../../listsAndObjects';
import { stringToSnakeCase, toSnakeCase } from '../../converter';
import { useWindowSize } from "../../customHook/useWindowSize";
import Banner from "../../components/banner/banner";

import styled from 'styled-components'

export default function Artists() {
  const { user } = useAuth();
  const location = useLocation()
  const navigate = useNavigate()
  const { artistData, setArtistData, isArtistChanged} = usePagination()
  const { setBlock } = useBlock()
  const { innerWidth } = useWindowSize()

  const [sort, setSort] = useState(0)
  const [filter, setFilter] = useState(1)
  const [cursor, setCursor] = useState(0)
  const [userLocation, setUserLocation] = useState('')

  let profession = location.pathname.replace('/', '') // url과 pro search value 동기화용.
  let prefessionIndex = profession === 'artist' ? 0 : toSnakeCase(occupationIndex)[profession] // url을 해당 occupationIndex의 숫자로 변환
  let user_idx = user ? user.data.user_idx : 0
  let url = `/user/artists?user_idx=${user_idx}&profession=${prefessionIndex}&sort=${sort}&location=${userLocation}&filter=${filter}`

  useEffect(() => {
    setBlock(true)
    setArtistData(url)
    setCursor(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  useEffect(() => {
    if (!cursor) { return }
    let newUrl = url + `&cursor=${cursor}`
    setBlock(true)
    setArtistData(newUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor])

  const changeProfession = (value) => { navigate(`/${stringToSnakeCase(value)}`) }

  const viewMore = (lastItem) => {
    sort ? setCursor(lastItem.admin_datetime) : setCursor(lastItem.user_name)
  }

  const artistList = artistData?.map((artistData) => {
    return (
      <ArtistCard key={artistData.user_idx} artistData={artistData} />
    )
  })

  return (
    <Div>
      <Banner location="artist"></Banner>
      {innerWidth > 720 ?
      <>
        <h2>다양한 커리어를 가진 아티스트들을 만나보세요!</h2>
        <div className="arrangeBar">
          <ArrangeBar urlPath={profession} isArtists={true} sort={sort} setSort={setSort} setSearchCategory={changeProfession} userLocation={userLocation} setUserLocation={setUserLocation} filter={filter} setFilter={setFilter}></ArrangeBar>
        </div>
      </>
      :
      <>
       <div className="arrangeBar">
          <p>Artist</p>
          <ArrangeBar urlPath={profession} isArtists={true} sort={sort} setSort={setSort} setSearchCategory={changeProfession} userLocation={userLocation} setUserLocation={setUserLocation} filter={filter} setFilter={setFilter}></ArrangeBar>
        </div>
      </>
      }
      {artistData && <div className="artistList">{artistList}</div>}
      {isArtistChanged && artistData?.length % 16 === 0 && artistData?.length >= 16 && 
        <div className="viewMore" style={{ marginTop: "62px" }} onClick={() => { user? viewMore(artistData[artistData.length - 1]) : navigate('/start') }}>더보기</div>
      }
    </Div>
  );
}

const Div = styled.div`
  margin-bottom:146px;
  > h2{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: -0.03px;
    color: #1b1b1b;
    margin:62px auto 28px;
    max-width: 1400px;
    width: 100%;
  }
  .artistList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 41px 30px;
    max-width: 1400px;
    width: 100%;
    min-width:324px;
    border-top: 1px solid #ededed;
    padding-top:40px;
    margin: 46px auto 0;
  }
  @media (max-width: 1500px) {
    >h2{
      width: 90%;
    }
    .artistList{
      width:90%;
    }
    .arrangeBar{
      width:90%;
      margin:0 auto;
    }
  }

  @media (max-width: 1100px) {
    .artistList{
      grid-gap:12px;
    }
  }

  @media (max-width: 720px) {
    > h2{
      font-size: 17px;
    }
    .artistList{
      grid-template-columns: 1fr 1fr;
      grid-gap: 41px 30px;
      width:calc(90% + 12px);
    }
    .arrangeBar{
      display:flex;
      justify-content:space-between;
      align-items:center;
      margin:28px auto -15px;
      > p{
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: normal;
        margin:0;
      }
      > div{
        min-width:inherit;
        margin:0;
      }
    }
  }
  @media (max-width: 440px) {
    .artistList{
      grid-gap:12px;
    }
  }
`
