import { Link } from "react-router-dom";
import styled from "styled-components"


// main page에서 Inspiration, Artist, Archive등의 헤더부분

function Header({headerTitle, headerDescription, link}) {
  return (
    <Div>
      <div className="flexLeft">
        <div className="header">
          <Link to={link || ''}>
            {headerTitle}
          </Link>
        </div>
        <div className="headerDescription">{headerDescription}</div>
      </div>
      {headerTitle !== "Category" && <div className="viewAllInHeader"><Link to={link || '/'}>View all</Link></div>}
    </Div>
  );
}



export default Header;

const Div = styled.div`
  display:flex;
  justify-content:space-between;
  margin:88px auto 32px;

  .flexLeft{
    display: flex;
    align-items:center;
    .header{
      margin-right:20px;
      line-height: 49px;
      height:49px;
      a{
        display: inline-block;
        font-size: 40px;
        font-family: Montserrat;
        font-weight: 600;
      }
    }
    .headerDescription{
      font-weight: 500;
      font-size: 15px;
      color: #aaa;
    }
  }
  
  .viewAllInHeader{
    display: inline-block;
    line-height: 49px;
    > a{
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      color: #292929;
    }
    margin-right:calc(15px * 2 + 28px + 24px); // 화살표 이미지 2개 + 화살표 사이간격 + 화살표와의 간격
  }

  @media (max-width: 1500px) {
    width:90%;
  }

  @media (max-width: 1250px) {
    width:initial;
    margin-left:36px;
    margin-right:36px;
    .viewAllInHeader{
      margin-right:0;
    }
  }

  @media (max-width: 900px) {
    margin-left:18px;
    margin-right:18px;
  }

  @media (max-width: 720px) {
    .flexLeft{
      .headerDescription{
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    margin-top:42px;
    margin-bottom:18px;
    &:first-child{
      margin-top:28px;
    }
    .flexLeft{
      .header{
        a{
          font-size: 24px;
        }
      }
      .headerDescription{
        display: none;
      }
    }
    .viewAllInHeader{
      >a{
        font-size: 13px;
      }
    }
  }
`