import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CreativeItem from '../../components/archive/creativeItem';

import useQuery from '../../api/useQuery';
import { usePagination } from '../../customHook/usePagination';
import { useNavigate } from "react-router-dom";
import { useBlock } from '../../customHook/useBlock';
import ProfileAside from '../../components/profileAside';
import styled from 'styled-components';
import { useWindowSize } from '../../customHook/useWindowSize';
import { useAuth } from '../../auth/auth';

export default function Brand() {
  const { user } = useAuth()
  const { apiData, isDataChanged, setApiData, setApiDataWidthPreviousData } = usePagination()
  const [cursor, setCursor] = useState(0)
  const { setBlock } = useBlock()
  const { innerWidth } = useWindowSize()
  const navigate = useNavigate()
  let { user_idx } = useParams() // 해당 user_idx로 detail 불러옴
  let url = `/client/list?client_idx=${user_idx}`
  const [profileData] = useQuery({ url })

  useEffect(() => {
    setBlock(true)
    setApiData(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!cursor) { return }
    let newUrl = url + `&cursor=${cursor}`
    setBlock(true)
    setApiDataWidthPreviousData(newUrl)
    // 이전데이터에 새 데이터 더해서 data return하는 함수
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor])

  const getProject = (projects) => {
    if(projects.length === 0) return
    return (
      user_idx &&
      <>
        <div className="title">{profileData.company_name}의 아카이브</div>
        <div className="portpolioList">            
          {projects.map((project)=>{
            if(project.archive_idx) return(<CreativeItem key={project.archive_idx} creativeData={project} redirect={`/archive/${project.archive_idx}`}/>)
            else return(<div key={project.portpolio_idx}></div>) // usePagination의 apiData brand, artist, archive에서 사용되어 portpolio_idx가 그전데이터로 저장된 경우 key값 error뜸.
          })}
        </div>
        {apiData?.length % 6 === 0 && apiData?.length >= 6 && isDataChanged && 
          <div className="viewMore" style={{ marginTop: "30px" }} onClick={() => { user ? viewMore(apiData[apiData.length-1]) : navigate('/start')}}>더보기</div>
        }
      </>
    )
  }

  const viewMore = (lastItem) => {
    setCursor(lastItem.archive_idx)
  }

  return (
    <Div>
      <div className="banner" style={innerWidth <= 900 ? {display:'none'} : {}}/>
      {apiData && profileData &&
        <div className="clientDetailWrapper">
          <ProfileAside profileData={profileData} type="brand" smallProfileForMobile={innerWidth <= 900}/>
          <div className="portpolioArea">
            {getProject(apiData)}
          </div>
        </div>
      }
    </Div>
  );
}

const Div = styled.div`
  margin-bottom:120px;
  .banner {
    width:100%;
    height: 220px;
    background-image: linear-gradient(to top, #6b6b6b, #424242);
  }

  .clientDetailWrapper{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .portpolioArea {
      width:100%;
      margin-left:43px;
      > .title{
        font-family: Pretendard;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.04px;
        color: #222;
        padding:40px 0 22px;
        border-bottom: solid 1px #ededed;
      }
      .portpolioList{
        margin-top:30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:33px 21px;
      }
    }
  }
 

  @media (max-width: 1500px) {
    .clientDetailWrapper{
      width:95%;
    }
  }

    @media (max-width: 900px) {
    .clientDetailWrapper{
      width:100%;
      display: block;
      > div{
        height: 310px; // profileAside가 위로 올라갔을때의 높이
        border-bottom:10px solid #f7f7f7;
        box-sizing:border-box;
      }
      .portpolioArea {
        width:90%;
        margin:32px auto 0;
        height:initial;
        border-bottom:0;
        > .title{
          margin:0 auto;
          padding: 0 0 24px;
        }
        .portpolioList{
          margin:0 auto;
          grid-template-columns: 1fr 1fr;
          // artist detail 보다 profile부분 짧아서 900으로 함
        }
      }
    }
  }

    @media (max-width: 580px) {
    .clientDetailWrapper{
      .portpolioArea {
        .portpolioList{
          grid-template-columns: 1fr;
        }
      }
    }
  }
`

