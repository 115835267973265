import styled from 'styled-components';
import { GMTtoKSTconverter } from '../../converter';

export default function CreativeList({ project, onClick, children }) {

  let date = GMTtoKSTconverter(project?.project_start + `.000Z`)
  return (
    <>
      {
        project ?
          <Div>
            <div className="flexWrapper">
              <div className="projectsThumbnail" onClick={onClick}>
                <img src={project.main_img} alt="thumbnail" />
              </div>
              <div className="detail">
                <div className="description">
                  <div className="project_brand">{project.project_brand}</div>
                  <div className="project_name">{project.project_name}</div>
                  <div className="project_datetime">{`${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`}</div>
                </div>
                <div className="children">
                  {children}
                </div>
              </div>
            </div>
          </Div>
          :
          <Div>
            <div className="flexWrapper">
              <div className="projectsThumbnail" style={{cursor:"inherit"}}>
                <div/>
              </div>
              <div className="noProject">
                <p>아직 업로드한 작품이 없습니다.</p>
                <p style={{color:"#0053ff", cursor:"pointer", textDecoration:"underline"}} onClick={onClick}>지금 등록해보세요</p>
              </div>
            </div>
          </Div>
      }
    </>
  )
}

const Div = styled.div`
  width:100%;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content:space-between;

  .flexWrapper{
    display: flex;
    width:100%;
    height:156px;
    padding:22px 0;
    text-align: left;
    position:relative; // .projectsThumbnail > div absolute 위해
    box-sizing:border-box;
    .projectsThumbnail{
      cursor: pointer;
      img{  // 등록된 창작물 있을때
        object-fit:cover;
        width: 148px;
        height: 112px;
        border-radius:4px;
        display: block;
      }
      > div{ // 등록된 창작물 없을때
        width: 148px;
        height: 112px;
        border-radius:4px;
        background-image: linear-gradient(to top, #fbfbfb, #f3f3f3);
        position: absolute;
      }
    }
    .detail{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      .description{
        height: 100%;
        padding-left:20px;
      }
      .project_brand{
        margin:6px 0 8px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        color: #5d5d5d;
      }
      .project_name{
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        height:62px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .project_datetime{
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        color: #aaa;
      }
    }
    .noProject{
      flex-direction: column;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      >p{
        margin:0;
        padding:0;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.22px;
        color: #222;
        line-height: 1.43;
      }
    }
  }
  .children{
    display: flex;
    align-items: center;
  }

  @media (max-width: 720px) {
    .flexWrapper{
      padding: 10px 0 0;
      height:110px;
      .projectsThumbnail{
        img{
          width:120px;
          height:90px;
        }
        > div{ 
          display: none;
        }
      }
      .detail{
        height:90px;
        /* display: block; */
        .project_brand{
          margin:4px 0;
        }
        .project_name{
          height:36px;
          font-size:14px;
        }
        .project_datetime{
          margin-top:12px;
        }
        .children{
          .buttons{
            >div{
              height: 32px;
              line-height: 32px;
              width:62px;
            }
            .blackButton{
              margin-left:8px;
            }
          }
        }
      }
    }
  }  

  @media (max-width: 550px) {
    .flexWrapper{
      height:initial;
      .projectsThumbnail{
        height: 138px;
      }
      .detail{
        display: block;
      }
    }
  }

`