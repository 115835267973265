import { useState } from 'react'
import { useForm } from "react-hook-form";
import styled from 'styled-components'
import { useModal } from '../../../components/modal/modal'
import { useAuth } from '../../../auth/auth';
import { useBlock } from '../../../customHook/useBlock';
import useAPI from '../../../api/useAPI';
import userImage from "../../../images/userImage.svg"
import Logo from "../../../images/opacityLogo.png"
import { GMTtoKSTconverter } from '../../../converter';

export default function MypageHome({ userInfo, setApiData }) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { putAPI } = useAPI()
  const [user_myself, setUser_myself] = useState(userInfo.user_myself)
  const { user } = useAuth()
  const { setBlock } = useBlock()

  const { modalShow, modalClose, profileChangeModal } = useModal()

  const [isSaveButtonActive, setSaveButtonActive] = useState(false)

  let registerDate = GMTtoKSTconverter(userInfo.admin_datetime + `.000Z`)

  const setChange = (e) => { // 저장하기 활성화 및 state change
    setUser_myself(e.target.value)
    e.target.value === '' && setSaveButtonActive(false)
    userInfo.user_myself === e.target.value ? setSaveButtonActive(false) : setSaveButtonActive(true)
  }

  const editProfile = () => {
    profileChangeModal()
  }

  const onSubmit = async (data) => {
    setBlock(true)
    let url = `/api/artists/myself`
    let { result } = await putAPI(url, user, data)
    if (result === "success") {
      modalShow({ message: "저장되었습니다", actionButton: <div className="blackButton" onClick={() => modalClose()}>확인</div> })
    }
    setApiData()
    setSaveButtonActive(false)
    setBlock(false)
  }

  return (
    <Div>
      <div className="header">프로필 정보</div>
      <div className="subHeader">수정할 내용을 입력해주세요.</div>
      <div className="underLine" />
      <div className="profileContainer">
        <div className="profile">
          {userInfo.user_privilege === 1 && // artist
            <>
              <img src={userInfo?.user_profile || userImage} alt="artist profile" />
              <div>
                <span className="name">{userInfo.user_name}</span>
                <span className="occupation">{userInfo.job_detail}</span>
              </div>
            </>
          }
          {userInfo.user_privilege === 2 && // client
            <>
              <img className="clientImage" src={userInfo?.user_profile || Logo} alt="client logo" />
              <div>
                <span className="name">{userInfo.user_name}</span>
                <span className="occupation">{userInfo.job_detail}</span>
              </div>
            </>
          }
        </div>
        <div className="profileChange" onClick={() => editProfile()}>
          프로필 수정
        </div>
      </div>

      <div className="userInformation">
        <p>계정(이메일)</p>
        <div>{userInfo.user_id}</div>
        <p>멤버 가입일</p>
        <div>{`${registerDate.getFullYear()}년 ${registerDate.getMonth() + 1}월 ${registerDate.getDate()}일`}</div>
        <p>계정타입</p>
        <div>{userInfo.user_privilege === 1 ? 'Artist' : 'Client'}</div>
      </div>

      <div className="underLine"/>


      <form id='signupForm' onSubmit={handleSubmit(onSubmit)}>
        <p>자기소개</p>
        <textarea placeholder='자기소개 내용을 입력하세요' value={user_myself || ''} {...register("user_myself", { maxLength: 200, onChange: (e) => { setChange(e) } })} />
        {isSaveButtonActive ?
          <button className="blackButton" form='signupForm' type="submit">저장하기</button>
          :
          <button  className="blackButton" style={{backgroundColor: "#E4E4E4", cursor: "default" }}>저장하기</button>
        }
        {errors.user_myself?.type === 'maxLength' && <div className="errorMessage">100자 이내로 입력해주세요.</div>}
      </form>

    </Div>
  )
}


const Div = styled.div`
  margin-right:40px;
  text-align: left;

  .profileContainer{
    max-width:460px;
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;

    .profile{
      display: flex;
      align-items:center;
      margin-bottom:11px;
      img{
        display: block;
        width: 82px;
        height:82px;
        object-fit: cover;
        border-radius: 50%;
        box-sizing:border-box;
      }
      .clientImage{
        width: 182px;
        height:113px;
        border: solid 1px #e8e8e8;
        border-radius: 0;
        object-fit: contain;
        padding:5px;
      }
      > div{
        display: flex;
        flex-direction:column;
        margin-left:22px;
      }
      .name{
        font-family: Pretendard;
        font-size: 20px;
        font-weight: 800;
        color: #000;
      }
      .occupation{
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        color: #606063;
        margin-top:6px;
      }
    }
    .profileChange{
      width: 88px;
      height: 40px;
      display:flex;
      justify-content:center;
      align-items:center;
      border-radius: 20px;
      border: solid 1px #0053ff;
      font-family: Pretendard;
      font-size: 13px;
      font-weight: 600;
      color: #0053ff;
      cursor:pointer;
    }
  }

  .userInformation{
    > p{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
      margin:19px 0 7px;
    }
    > div{
      max-width:460px;
      width:100%;
      height: 42px;
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      background-color: #fafafa;
      padding-left:16px;
      box-sizing:border-box;
      display: flex;
      align-items:center;
    }
  }

  .underLine{
    width: 100%;
    height: 1px;
    background-color:#ebebeb;
    border-top:none !important;
    margin:30px 0 !important;
  }

  > form{
    >p{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
      margin:0;
    }
    textarea{
      max-width:460px;
      width:100%;
      height:167px;
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      box-sizing: border-box;
      padding:14px 16px;
      resize: none;
      margin:10px 0 20px;
    }
    textarea::placeholder{
      color: #afafaf;
    }
    .blackButton{
      width: 108px;
      height: 44px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom:118px;
    }
    .errorMessage{
      color:#EB5757;  // 경고 메세지
      line-height: 20px;
      margin:-108px 0 88px
    }
  }
  
  @media (max-width: 900px) {
    padding: 0 18px;
    margin-right:0;
    box-sizing:border-box;
  }

  @media (max-width: 460px) {
    .profileContainer{
      .profile{
        img{
          width: 72px;
          height:72px;
        }
        .clientImage{
          width: 112px;
          height:72px;
        }
        > div{
          margin-left:16px;
        }
        .name{
          font-size: 18px;
        }
        .occupation{
          font-size: 12px;
          margin-top:4px;
        }
      }
      .profileChange{
        width: 82px;
        height: 38px;
        font-size: 12px;
      }
    }
    > form{
      .blackButton{
        margin-bottom:80px;
      }
      .errorMessage{
        margin:-70px 0 50px
      }
    }
  }
`