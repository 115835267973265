import { useState } from "react";
import useAPI from "../api/useAPI";
import { useAuth } from "../auth/auth";
import { useLoading } from "./useLoading";

export default function useCampaginMember() {
  const [user_list, setUser_list] = useState([])
  
  const { getAPI } = useAPI()
  const { user } = useAuth()
  const { setLoading } = useLoading()

  const setSearch = async (keyword) => {
    if (!keyword) {
      setUser_list([])
      return
    }
    let url =`/client/campaign/member?user_name=${keyword}`
    let { data } = await getAPI(url, user)
    setUser_list(data?.user_list)
    setLoading(false)
  }

  return { user_list, setSearch }

}