
import heartIcon from '../../images/heartBlack.svg';
import heartFilledtIcon from '../../images/heartFilled.svg';
import userImage from '../../images/userImage.svg'
import shareButton from '../../images/shareButton.svg'
import { archiveCategory } from '../../listsAndObjects';
import { getKeyByValue } from '../../converter';

import { GMTtoKSTconverter } from '../../converter';
import { useParams } from 'react-router-dom';
import { useModal } from '../../components/modal/modal';
import { usePopup } from '../../components/popup/popup';
import useAPI from '../../api/useAPI';
import { useBlock } from '../../customHook/useBlock';
import { useAuth } from "../../auth/auth";
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

export default function ProfileAside({ profileData, type, smallProfileForMobile }) {
  // type => artist, archive, brand

  const { putAPI } = useAPI()
  const { user } = useAuth();
  const { modalShow, reportModal, matchingModal, modalClose, shareModal, addWishAndMoodbardModal } = useModal()
  const { PopupShow } = usePopup()
  const { setBlock } = useBlock()
  const navigate = useNavigate()
  let { user_idx } = useParams()
  const [like_cnt, setLike_cnt] = useState()
  const [like_check, setLike_check] = useState()
  const [wishlist_cnt, setWishlist_cnt] = useState()
  const [wishlist_check, setWishlist_check] = useState()
  const [moodboard_cnt, setMoodboard_cnt] = useState()
  const [moodboard_check, setMoodboard_check] = useState()
  const [isArtist, setArtist] = useState(false)
  const [isArchive, setArchive] = useState(false)
  const [isBrand, setBrand] = useState(false)

  useEffect(() => {
    setLike_cnt(profileData.like_cnt)
    setLike_check(profileData.like_check)
    setWishlist_cnt(profileData?.wishlist_cnt)
    setWishlist_check(profileData?.wishlist_check)
    setMoodboard_cnt(profileData?.moodboard_cnt)
    setMoodboard_check(profileData?.moodboard_check)
    type === "artist" && setArtist(true)
    type === "archive" && setArchive(true)
    type === "brand" && setBrand(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let date = profileData && (profileData.user_registry || profileData.admin_datetime) && GMTtoKSTconverter(profileData.user_registry||profileData.admin_datetime + `.000Z`)
  // user_registry -> brand
  // admin_datetime -> artist

  const addMyWishList = async (idx, user) => {
    if (user) {
      setBlock(true)
      let url = `/user/wishlist?idx=${idx}`
      let { data, result } = await putAPI(url, user)
      if (result === 'success') {
        if (data.wishlist_check) {
          addWishAndMoodbardModal("위시리스트에 추가되었습니다.", "최고의 아티스트들을 마이 위시리스트에 등록해보세요.", <Link to='/mypage/wishList' className="blackButton" onClick={() => modalClose()} >마이 위시리스트 보기</Link>)
          setWishlist_cnt(wishlist_cnt + 1)
        } else {
          setWishlist_cnt(wishlist_cnt - 1)
        }
        setWishlist_check(!wishlist_check)
      }
      setBlock(false)
    } else {
      modalShow({ message: `로그인이 필요한 서비스입니다.`, submessage: '로그인하고 활동을 시작해보세요!', actionButton: <Link to='/start' className="blackButton" onClick={() => modalClose()}>로그인</Link> })
    }
  }

  const addMyBoard = async (idx, user) => {
    if (!isArchive) return
    if (user) {
      setBlock(true)
      let url = `/user/moodboard?idx=${idx}`
      let { data, result } = await putAPI(url, user)
      if (result === 'success') {
        if (data.moodboard_check) {
          addWishAndMoodbardModal(`마이보드에 추가되었습니다.`, "최신 트렌드로 나만의 무드보드를 꾸며보세요.", <Link to='/mypage/moodboard' className="blackButton" onClick={() => modalClose()}>마이보드 확인하기</Link>)
          setMoodboard_cnt(moodboard_cnt + 1)
        } else {
          setMoodboard_cnt(moodboard_cnt - 1)
        }
        setMoodboard_check(!moodboard_check)
      }
      setBlock(false)
    } else {
      modalShow({ message: `로그인이 필요한 서비스입니다.`, submessage: '로그인하고 활동을 시작해보세요!', actionButton: <Link to='/start' className="blackButton" onClick={() => modalClose()}>로그인</Link> })
    }
  }

  const togglePopUp = () => {
    if (!isArtist) return
    PopupShow(user_idx)
    modalClose()
  }

  const matching = (profileData) => {
    if (!isArtist) return
    if (user) {
      if (user.data.user_privilege === 1) {
        modalShow({ message: '클라이언트만 가능한 서비스입니다.', submessage: '이용이 필요하시면 고객센터로 문의해주세요.', actionButton: <div className="blackButton" onClick={() => modalClose()}>닫기</div> })
        return
      }
      matchingModal(profileData, togglePopUp)
    } else {
      modalShow({ message: `로그인이 필요한 서비스입니다.`, submessage: '로그인하고 활동을 시작해보세요!', actionButton: <Link to='/start' className="blackButton" onClick={() => modalClose()}>로그인</Link> })
    }
  }

  const likeButton = async (e, idx, user) => {
    e.stopPropagation()
    if (isBrand) return
    if (user) {
      setBlock(true)
      let url
      isArtist ? url = `/artists/like?artists_idx=${idx}` : isArchive ? url = `/client/archive/like?archive_idx=${idx}` : url = ''
      let { data, result } = await putAPI(url, user)
      if (result === "success") {
        if (isArtist) {
          data.artists_like ? setLike_cnt(like_cnt + 1) : setLike_cnt(like_cnt - 1) // artist
        } else {
          data.archive_like ? setLike_cnt(like_cnt + 1) : setLike_cnt(like_cnt - 1) // archive
        }
        setLike_check(!like_check)
      }
      setBlock(false)
    } else {
      modalShow({ message: `로그인이 필요한 서비스입니다.`, submessage: '로그인하고 활동을 시작해보세요!', actionButton: <Link to='/start' onClick={() => { modalClose() }} className="blackButton">로그인</Link> })
    }
  }

  const report = (user_idx) => {
    if (!user) {
      modalShow({ message: `로그인이 필요한 서비스입니다.`, submessage: '로그인하고 활동을 시작해보세요!', actionButton: <Link to='/start' onClick={() => { modalClose() }} className="blackButton">로그인</Link> })
      return
    } else {
      reportModal(user_idx)
    }
  }

  return (
    <>
      {smallProfileForMobile ?
        <SmallProfileForMobile>
          <div className="profileDetail">
            <img className={`profileImage ` + type} src={profileData.user_profile ? profileData.user_profile : userImage} alt="profile_image" />
            <div>
              <div className="name">
                {isArtist ? profileData.user_name: isArchive ? profileData.archive_brand : profileData.company_name}
              </div>
              <div className="job">
                {isArtist ? profileData.job_detail : isArchive ? getKeyByValue(archiveCategory, profileData.archive_category) : profileData.job_detail}
              </div>
            </div>
          </div>
          <div className="icons">
            <img className="share" src={shareButton} alt="share icon" onClick={() => { shareModal(profileData, type) }}/>
            {isArtist &&
              <img className="heart" src={like_check ? heartFilledtIcon : heartIcon} alt="heart icon" onClick={(e) => {likeButton(e, profileData.user_idx, user)}}/>
            }
          </div>
        </SmallProfileForMobile>
        :
        <Profile>
          <div>
            <div className="profileArea">
              <img className={`profileImage ` + type} style={isArchive ? { cursor: "pointer" } : {}} src={profileData.user_profile ? profileData.user_profile : userImage} alt="profile_image" onClick={() => { isArchive && navigate(`/brand/${profileData.user_idx}`) }} />
              <div className="user_type">{isArtist ? "Artist" : "Client"}</div>
              <div className="profileDescription">
                <div className="name">
                  {isArtist ? profileData.user_name : isArchive ? profileData.archive_brand : profileData.company_name}
                </div>
                <div className="job">
                  {isArtist ? profileData.job_detail : isArchive ? getKeyByValue(archiveCategory, profileData.archive_category) : profileData.job_detail}
                </div>
                <div className="location">
                  {isArtist && `지역 ${profileData.address_siNm?.split(',')[0]}`}
                </div>
                <div className="source">
                  {isArchive && profileData.archive_url && <>Source by : <a href={`https://${profileData.archive_url}`} target="_blank" rel="noreferrer">{profileData.archive_url}</a></>}
                  {isBrand && profileData.user_homepage && <>Source by : <a href={`https://${profileData.user_homepage}`} target="_blank" rel="noreferrer">{profileData.user_homepage}</a></>}
                </div>
              </div>
            </div>

            <div className="buttonArea">
              {isArtist &&
                <div className="blueButton" onClick={() => { matching(profileData) }}>
                  프로젝트 매칭하기
                </div>
              }
              {isArchive &&
                <div className="blueButton" onClick={() => { addMyBoard(profileData.archive_idx, user) }}>
                  {moodboard_check ? "무드보드에서 삭제" : "무드보드 추가"}
                </div>
              }
              {isArtist &&
                <div className="whiteButton" onClick={() => { addMyWishList(profileData.user_idx, user) }}>
                  {wishlist_check ? "- 위시리스트에서 삭제" : "+ 위시리스트 추가"}
                </div>
              }
              <div className="grayButtons" style={isBrand ? { justifyContent: "center" } : {}}>
                <div className="grayButton" onClick={() => { shareModal(profileData, type) }}>
                  <img src={shareButton} alt="share icon" />공유하기
                </div>
                {!isBrand &&
                  <div className="grayButton" onClick={(e) => { isArtist ? likeButton(e, profileData.user_idx, user) : likeButton(e, profileData.archive_idx, user) }}>{/* archive_idx */}
                    <img src={like_check ? heartFilledtIcon : heartIcon} alt="heart icon" />좋아요
                  </div>
                }
              </div>
            </div>

            {!isBrand &&
              <div className="icons">
                <div><span>{profileData.view_cnt}</span><span>{isArtist ? "프로필" : "아카이브"}<br/>조회 수</span></div>
                <div><span>{like_cnt}</span><span>프로필<br/>좋아요</span></div>
                <div><span>{isArtist ? wishlist_cnt : moodboard_cnt}</span><span>{isArtist ? "위시리스트" : "마이보드"}<br/>추가 수</span></div>
              </div>
            }

            {isArtist &&
              <div className="aboutMe">
                <div className="title">About Me</div>
                <div className="description">{profileData.user_myself ? profileData.user_myself : "등록된 자기소개가 없습니다."}</div>
              </div>
            }
            <div className="joinDate">
            {date &&        
              `멤버 가입일 : ${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`
            }
            </div>
            <div className="report" onClick={() => { report(profileData.user_idx) }}>신고하기</div>
          </div>
        </Profile>
      }
    </>
  )
}

const SmallProfileForMobile = styled.div`
  height:86px !important;
  padding:22px 0;
  width:90%;
  margin:0 auto;
  box-sizing:border-box;
  border-bottom: 1px solid #f7f7f7 !important;
  display: flex;
  justify-content:space-between;
  align-items:center;
  .profileDetail{
    display: flex;
    align-items:center;
    height:42px;
    .artist{
      width:42px;
      height:42px;
      margin-right:12px;
      object-fit: cover;
      border-radius:50%;
    }
    .brand{
      width: 68px;
      height: 42px;
      object-fit: contain;
      margin-right:12px;
      border: solid 1px #ededed;
      border-radius:4px;
      box-sizing: border-box;
      padding:2px;
    }
    > div{
      .name{
        font-family: Pretendard;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.04px;

      }
      .job{
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: normal;
        color: #606063;
      }
    }
  }
  .icons{
    .share{
      width:16px;
      height:16px;
    }
    .heart{
      width:17px;
      height:15px;
      margin-left:20px;
    }
  }
`

const Profile = styled.div`
  width: 304px;
  padding: 25px 33px 39px 39px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #f1f1f1;
  background-color: #fff;
  margin-top:-149px; // 220(배너높이) - 71(겹치는부분)px
  height: 100%;

  .profileArea{
    width:100%;
    text-align: center;
    .profileImage{
      background-color: white;
      width: 174px;
      height: 108px;
      border-radius: 8px;
      object-fit: contain;
      display: block;
      margin: 0 auto;
      border: solid 1px #e5e5e5;
    }

    .artist{
      object-fit: cover;
      border-radius:50%;
      border:none;
      width: 107px;
      height: 107px;
    }

    .user_type{
      width: 62px;
      margin: -15px auto 0; // -15 -> profileImage와 겹치는 높이
      position: relative;
      padding: 0;
    }
    .profileDescription{
      margin:22px auto;
      .name{
        font-family: Pretendard;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.05px;
        color: #000;
      }
      .job{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        color: #606063;
        margin:4px auto 10px;
      }
      .location, *{
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.02px;
        color: #989898;
      }
      .source{
        > a{
          text-decoration:underline;
        }
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .buttonArea{
    padding-bottom:33px;
    border-bottom: solid 1px #ebebeb;
    .blueButton{
      width: 232px;
      height: 42px;
      margin-bottom:10px;
    }
    .blueButton:hover{
      background-color: #7ea7ff;
      transition:0.2s;
    }
    .whiteButton{
      width: 232px;
      height: 42px;
      margin: 10px auto;
    }
    .whiteButton:hover{
      color: #b0b0b0;
      transition:0.2s;
    }
    .grayButtons{
      display: flex;
      justify-content:space-between;
      .grayButton{
        width: 113px;
        height: 42px;
        img{
          width: 16px;
          height: 14px;
          display: inline-block;
          margin-right:4px;
        }
      }
      .grayButton:hover{
        color: #b0b0b0;
        transition:0.2s;
      }
    }
  }

  .aboutMe{
    padding:33px 0;
    border-top: solid 1px #ebebeb;
    .title{
      font-family: Montserrat;
      font-size: 14px;
      font-weight: bold;
      color: #292929;
      margin-bottom:12px;
    }
    .description{
      font-family: Pretendard;
      font-size: 13px;
      line-height: 1.69;
      color: #5d5d5d;
    }
  }

  .joinDate {
    border-top: solid 1px #ebebeb;
    padding: 26px 0 10px;
    text-align: center;
    font-family: Pretendard;
    font-size: 11px;
    font-weight: 500;
    color: #c8c8c8;
  }


  .report{
    text-align: center;
    cursor: pointer;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    color: #808080;
    text-decoration:underline;
  }

  .icons {
    display:flex;
    justify-content:space-between;
    padding:33px 0;
    >div {
      display:flex;
      flex-direction:column;
      span:first-child{
        font-family: Montserrat;
        font-size: 18px;
        font-weight: bold;
        color: #292929;
        margin-bottom:8px;
      }
      span:last-child{
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 500;
        color: #999;
      }
    }
    img {
      width: 17px;
      margin-right: 10px;
    }
  }

  @media (max-width: 900px) {
    width:100%;
    box-shadow:inherit;
    border-bottom:none;
    padding:0;
    position: relative;
    margin-top: -108px;
    border-radius:0;
    >div{
      position: absolute;
      top:-53.5px;
      left: 0;
      right: 0;
    }
    .buttonArea{
      max-width: 324px;
      margin: 0 auto;
      border-bottom:none;
      padding-bottom:0;
      .blueButton, .whiteButton{
        width:100%;
      }
      .grayButtons{
        .grayButton{
          width: 158px;
        }
      }
    }
    .joinDate{
      padding-top:22px;
      border-top:none;
    }
    .report{
      margin-bottom:22px;
    }
    .icons, .aboutMe{
      display: none;
    }
  }
`
