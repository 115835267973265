import styled from "styled-components"
import userImage from "../../../images/userImage.svg"
import { useEffect } from "react";
import { objectKeyChangeFromXtoY } from "../../../converter"
import useCampaginMember from "../../../customHook/useCampaginMember";
import { useModal } from "../../../components/modal/modal";
import { useLoading } from "../../../customHook/useLoading";

// projectForm campaign member search
export default function ProjectMember({ showProjectMemberContainer, searchKeyword, setSearchKeyword, selectedCampaginMemberList, setSelectedCampaginMemberList, AddCampaginMember }) {
  // showProjectMemberContainer -> input 눌렀을때 열었다 닫았다 가능하도록.
  const { user_list, setSearch } = useCampaginMember()
  const { isLoading, setLoading  } = useLoading()
  const { modalShow, modalClose } = useModal()

  useEffect(() => {
    if(!searchKeyword){return}
    setSearch(searchKeyword)
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword])


  const selectArtists = (artist) => {
    const indexOfObject = selectedCampaginMemberList.findIndex(object => {
      return object.member_idx === artist.user_idx; // Get client/campaign/member 에서 결과값 user_idx, user_name임
    });
    if (indexOfObject !== -1) {
      modalShow({ message: "이미 추가된 유저입니다", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    let newArtist = objectKeyChangeFromXtoY(artist, 'user', 'member')
    setSelectedCampaginMemberList([...selectedCampaginMemberList, newArtist])
    setSearchKeyword('')
  }

    const getResult = (user_list) => {
      if(isLoading){
        return (
          <div className="loading">유저를 찾는 중 입니다...</div>
        )
      }
      if (user_list?.length > 0) {
        return (
          <div className="memberSearchList">
            {user_list.map((user) => {
              return (
                <div key={user.user_idx} onClick={() => { selectArtists(user) }} >
                  <img src={user.user_profile || user.member_profile || userImage} alt="profile_image" />
                  <div className="name">{user.user_name || user.member_name}</div>
                  <div className="occupation">{user.job_detail}</div>
                </div>
              )})
            }
            <div style={{cursor:'inherit', fontSize: '13px'}}
              onClick={()=>{AddCampaginMember(searchKeyword, selectedCampaginMemberList)}}
            >찾으시는 유저가 없습니까? 유저를 직접<span className="addArtist">{`등록`}</span>해보세요</div>
          </div>
        )
      } else {
        return (
          <div className="noResult">
            '{searchKeyword}'(으)로 등록된 유저가 없습니다.<br /><br />'{searchKeyword}'(으)로 아티스트를 <span className="addArtist" onClick={()=>{AddCampaginMember(searchKeyword, selectedCampaginMemberList)}}>추가</span>하시겠습니까?
          </div>
        )
      }
    }

    return (
      <>
        {searchKeyword && showProjectMemberContainer &&
          <Div>
            {getResult(user_list)}
          </Div>
        }
      </>
    )
  }

  
const Div = styled.div`
  position:absolute;
  grid-column: 1/3;
  width:100%;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  background: #fff;
  z-index:1;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
  .loading{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #b7b7b7;
    padding:7px 22px;
    line-height:34px;
  }
  .memberSearchList {
    max-height: 260px;
    overflow-y: auto;
    > div{
      cursor:pointer;
      display:flex;
      align-items:center;
      padding:7px 22px;
      transition:0.2s;
      > img{
        display:block;
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius:50%;
      }
      > .name{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        color: #424242;
        padding:0 10px;
      }
      > .occupation{
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        color: #b7b7b7;
      }
    }
    > div:hover{
      background-color: #f8f8f8;
    }
  }

  .noResult{
    padding:7px 22px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.38;
    color: #999;
  }

  .addArtist{ // 유저 등록 Span
    text-decoration: underline;
    color: blue;
    margin:0 0 0 3px;
    font-size:14px;
    cursor:pointer; 
  }
`