
import styled from 'styled-components'
import { useAuth } from '../../auth/auth';
import { useNavigate } from 'react-router-dom';

export default function EventBanner() {

  const { user } = useAuth()
  const navigate = useNavigate()

  return (
    <>
      {!user && 
        <Div onClick={()=>{navigate('/start')}}>
          Jabble 신규 회원가입 하고 <span>혜택</span> 받자!
        </Div>
      }
    </>
    
  );
}


const Div = styled.div`
  width: 100%;
  height: 40px;
  background-color: #000;
  &, span{
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.03px;
    color: #fff;
    display: flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
  }
  span{
    color:#ff4b00;
    margin:0 5px;
  }
`