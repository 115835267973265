import styled from "styled-components"
import MatchingDescrpition from "./matchingDescrpition"
import { useState, useEffect } from "react"
import { useModal } from "../../../components/modal/modal"
import { useAuth } from "../../../auth/auth"
import { useWindowSize } from "../../../customHook/useWindowSize"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';
import useAPI from "../../../api/useAPI"
import { GMTtoKSTconverter } from "../../../converter"
import Slide from "../../../components/slide"

export default function Matching() {

  const { modalShow, modalClose,
    // accountModal
  } = useModal()
  const { getAPI } = useAPI()
  const navigate = useNavigate()
  const { user, userType } = useAuth()
  const [user_privilege, setUser_privilege] = useState()
  const { innerWidth } = useWindowSize()
  const [matchingData, setMatchingData] = useState([]) // 전체 data
  const [filteredData, setFilteredData] = useState([]) // 해당 매칭 단계의 data
  const [matchingState, setMatchingState] = useState(0) // 현재 매칭 메뉴바에서 선택된 매칭 단계
  const [clickedMatchingUser, setMatchingUser] = useState(0) // 현재 매칭 단계에서 click된 유저의 index
  // const matching_process = filteredData[clickedMatchingUser]?.matching_process // 현재 click된 유저의 매칭 단계.
  let matchingDate = GMTtoKSTconverter(filteredData[clickedMatchingUser]?.matching_datetime + `.000Z`)
  let artistItemWidth = innerWidth > 720 ? 64 : 47
  let clientItemWidth = innerWidth > 720 ? 124 : 90
  let itemWidth = user_privilege === 1 ? clientItemWidth : artistItemWidth // client 계정일때 artist 계정 보여줌
  let artistCenterMargin = innerWidth > 720 ? 34 : 21
  let clientCenterMargin = innerWidth > 720 ? 22 : 11
  let centerMargin = user_privilege === 1 ? clientCenterMargin : artistCenterMargin // client 계정일때 artist 계정 보여줌

  const matchingStateOptions = [
    { value: 0, label: '전체 보기' },
    { value: 1, label: '매칭 신청' },
    { value: 2, label: '매칭 진행' },
    { value: 3, label: '매칭 종료' },
    { value: 4, label: '매칭 취소' }
  ]

  useEffect(() => {
    const fetchAPI = async () => {
      let url = `/matching`
      let { data } = await getAPI(url, user)
      setMatchingData(data?.matching)
      setFilteredData(data?.matching)
      setUser_privilege(data?.user_privilege)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (matchingState) {
      // 전체보기 외
      let newData = matchingData.filter((matching) => matching.matching_process === matchingState)
      setFilteredData(newData)
    } else {
      // 전체보기
      setFilteredData(matchingData)
    }
    setMatchingUser(0)
  }, [matchingData, matchingState])


  const getMatchingStateMenu = () => {
    let matchingStateMenu = []
    for (let i = 0; i < matchingStateOptions.length; i++) {
      let matchingStateLength = matchingData.filter((matching) => matching.matching_process === i).length  // Q(n^2)
      matchingStateMenu.push(
        <span key={uuidv4()} className={matchingState === i ? "active" : ""} style={matchingStateLength === 0 ? { cursor: 'inherit' } : { cursor: 'pointer' }} onClick={() => { i === 0 ? setMatchingState(i) : matchingStateLength && setMatchingState(i) }}>{matchingStateOptions[i].label}
          <span>{i === 0 ? matchingData.length : matchingStateLength}</span>
        </span>
      )
    }
    return matchingStateMenu
  }

  const getMaxWidth = () => {
    let mypage = document.getElementsByClassName("mypage")[0];
    let mypageWidth = mypage.offsetWidth
    let mypageAside = document.getElementsByClassName("mypageAside")[0];
    let mypageAsideWidth = mypageAside.offsetWidth
    let computedStyle = mypageAside.currentStyle || window.getComputedStyle(mypageAside);
    return mypageWidth - mypageAsideWidth - parseInt(computedStyle.marginRight, 10) - parseInt(computedStyle.marginLeft, 10) - 38 // 화살표 너비
  }

  const getMatchingUserList = (matchingData, centerMargin) => { // artists or client list
    return matchingData.map((matchingData, index) => {
      let className = clickedMatchingUser === index ? "active" : ""
      if (user_privilege === 1) {
        return (
          <div className={"user client " + className} key={uuidv4()} onClick={() => { setMatchingUser(index) }} style={centerMargin ? { marginRight: `${centerMargin}px` } : {}}>
            <img alt="client profile" src={matchingData.client.user_profile} />
            <p>{matchingData.client.company_name}</p>
          </div>
        )
      } else {
        return (
          <div className={"user artist " + className} key={uuidv4()} onClick={() => { setMatchingUser(index) }} style={centerMargin ? { marginRight: `${centerMargin}px` } : {}}>
            <img alt="artist profile" src={matchingData.artists.user_profile} />
            <p>{matchingData.artists.user_name}</p>
          </div>
        )
      }
    })
  }

  const getButtons = () => {
    return (
      <div className="buttons">
          <div><a href="https://pf.kakao.com/_NApXxj" target="_blank" rel="noreferrer">1:1 채팅하기</a></div>
            <div onClick={() => modalShow({ message: <div className="numberInfoModalTitle">📱 전화문의 </div>, others: <><div className="numberInfoModalMessage">매칭 관련 jabble 담당 연락처</div><div className="numberInfoModalNumber">02-983-1923</div></>, actionButton: <div className="blackButton" onClick={() => modalClose()}>확인</div> })}>
              전화문의
            </div>
            {/* {(matching_process === 2 || matching_process === 3) && userType === "artists" &&
              <div onClick={() => accountModal(user.data.user_idx)}>계좌 등록</div>
            } */}
        </div>
    )
  }

  const sildeSetting = {
    itemWidth,
    totalItemLength: filteredData.length,
    centerMargin: user_privilege !== 1 ? artistCenterMargin : clientCenterMargin,
  }

  return (
    <Div itemWidth={itemWidth}>
      <div className="title">
        <h2>매칭 스페이스</h2>
        <span>매칭을 통해 가치를 창출해보세요</span>
      </div>

      <div className="matchingstates">{getMatchingStateMenu()}</div>

      <div className="matchingHeader" style={{ maxWidth: getMaxWidth() + "px" }}>
        {matchingData.length !== 0 &&
          <>
            <p>
              매칭 리스트
            </p>
            <div className="matchingList">
              {innerWidth > 900 ?
                <Slide {...sildeSetting}>
                  {getMatchingUserList(filteredData)}
                </Slide>
                :
                getMatchingUserList(filteredData, centerMargin)
              }
            </div>
          </>
        }
      </div>
      {matchingData.length !== 0 ? // matching 있을 때
        <div className="matchingDescrpitionWrapper">
          <div>
            <div className="matchingDescrpitionHeader">
              {userType === "artists" ?
                <div className="info client">
                  <img src={filteredData[clickedMatchingUser]?.client.user_profile} alt="client profile" />
                  <div>
                    <span className="name">{filteredData[clickedMatchingUser]?.client.company_name}</span>
                    <div>
                      <span className="date">{`${matchingDate.getFullYear()}.${matchingDate.getMonth() + 1}.${matchingDate.getDate()}`}</span>
                    </div>
                  </div>
                </div>
                :
                <div className="info artist">
                  <img src={filteredData[clickedMatchingUser]?.artists.user_profile} alt="artist profile" />
                  <div>
                    <span className="name">{filteredData[clickedMatchingUser]?.artists.user_name}</span>
                    <div>
                      <span className="job">{filteredData[clickedMatchingUser]?.artists.job_detail}</span>
                      <span className="date">{`${matchingDate.getFullYear()}.${matchingDate.getMonth() + 1}.${matchingDate.getDate()}`}</span>
                    </div>
                  </div>
                </div>
              }
              <div className="balloon">
                해당 내용으로 <span>매칭</span>이 시작되었습니다! 🤩
              </div>
            </div>
            {/* buttons position sticky시 매칭문구보다 밑에오게하기위해 child로 뺌  */}
            <MatchingDescrpition matchingData={filteredData[clickedMatchingUser]}>
              {matchingData.length !== 0 && innerWidth <= 720 && getButtons()}
            </MatchingDescrpition>
          </div>
        </div>
        :
        <div className="noContent">
          <span>🤔</span>
          <p>
            매칭중인 내용이 없습니다.<br />
            {user_privilege === 1 ? "포트폴리오를 업로드 해보시겠습니까?" : "아티스트를 둘러보시겠습니까?"}
          </p>
          {user_privilege === 1 ?
            <div className="blueButton" onClick={() => { navigate('/mypage/project') }}>
              내 작업 공유
            </div>
            :
            <div className="blueButton" onClick={() => { navigate('/artist') }}>
              아티스트 둘러보기
            </div>
          }
        </div>
      }
      {matchingData.length !== 0 && innerWidth > 720 && getButtons()}
    </Div>
  )
}

const Div = styled.div`
  width: 100%;
  height:100%;
  margin:0 auto;
  padding-right:40px;
  box-sizing:border-box;
  *{
    font-family: Pretendard;
    font-size: 16px;
  }

  > .title{
    text-align:left;
    margin:56px 0 66px;
    h2{
      font-size: 26px;
      font-weight: bold;
      margin-bottom:12px;
    }
    span{
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.02px;
      color: #606063;  
    }
  }

  .matchingstates::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  .matchingstates{
    display: flex;
    align-items: center;
    text-align:left;
    border-bottom: solid 1px #ebebeb;
    overflow: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    > span{
      display: inline-block;
      position:relative;
      height:26px;
      line-height:26px;
      margin-right:27px;
      padding-bottom:19px;
      font-weight: bold;
      color: #aaa;
      white-space: nowrap;
      >span{
        display: inline-block;
        width:26px;
        height:26px;
        background-color: #f4f4f4;
        border-radius:50%;
        text-align:center;
        margin-left:4px;
        color: #606063;
        letter-spacing: -0.02px;
      }
    }
    .active{
      color: #000;
      font-weight: 800;
      >span{
        color: #0053ff;
      }
    }
    .active:after{
      content:"";
      display:block;
      position:absolute;
      width:100%;
      margin-top:16px;
      border-bottom: solid 3px #000;
    }
  }

  .matchingHeader{
    flex:5;

    > p{  // 매칭 리스트
      margin:60px 0 18px;
      font-size: 18px;
      font-weight: bold;
      color: #333;
      text-align:left;
    }

    .matchingList{ // 매칭 신청한 user list
      margin-bottom:11px;

      .slideLeft, .slideRight{
        img{
          width:38px;
          height:38px;
        }
      }
      .slideLeft{
        top:${props => props.itemWidth / 2 - 38 / 2}px;
        left:calc(-38px/2);
      }
      .slideRight{
        top:${props => props.itemWidth / 2 - 38 / 2}px;
        right:calc(-38px/2);
      }
      .user{
        cursor:pointer;
        p{
          height: 15px;
          line-height:15px;
          font-size: 12px;
          font-weight: 500;
          color: #aaa;
          margin:0;
          padding:0px;
          transition: 0.2s;
        }
      }
      .user:hover{
        p{
          margin:0px;
          padding:0px;
          height: 21px;
          line-height:17px;
          font-size: 14px;
          font-weight: 800;
          color: #333;
        }
      }

      .artist{
        > img{
          width:${props => props.itemWidth - 12}px;
          height:${props => props.itemWidth - 12}px;
          display: block;
          object-fit: cover;
          border-radius: 50%;
          margin:6px 6px 13px 6px;
          transition: 0.2s;
        }
      }
      .artist:hover{
        > img{
          width:${props => props.itemWidth}px;
          height:${props => props.itemWidth}px;
          margin:0 0 6px 0;
        }
      }
      .client{
        >img{
          width:${props => props.itemWidth - 24}px;
          height: 62px;
          display: block;
          object-fit: contain;
          margin:8px 12px 15px 12px;
          transition: 0.2s;
          border: solid 1px #e8e8e8;
          box-sizing: border-box;
        }
      }
      .client:hover{
        > img{
          width:${props => props.itemWidth}px;
          height: 77px;
          margin:0 0 8px 0;
        }
      }

      .active{
        &.artist{
          > img{
            width:${props => props.itemWidth}px;
            height:${props => props.itemWidth}px;
            margin:0 0 6px 0;
          }
        }
        &.client{
          >img{
            width:${props => props.itemWidth}px;
            height: 77px;
            margin:0 0 8px 0;
          }
        }
        p {
          margin:0px;
          padding:0px;
          height: 21px; // 17 + 4px(after)
          line-height:17px;
          font-size: 14px;
          font-weight: 800;
          color: #333;
        }
        p:after{
          content:"";
          display:block;
          width: 35px;
          height: 1px;
          margin:0 auto;
          border-bottom: solid 3px #000;
        }
      }
    }
  }

  .noContent{
    margin-top:34px;
    margin-bottom:164px;
  }


  .matchingDescrpitionWrapper{
    border-radius: 4px;
    background-color: #f8f8f8;
    margin-top:34px;
    min-height:600px;
    padding:20px;
    box-sizing:border-box;
    
    .matchingDescrpitionHeader{
      display:flex;
      align-items: center;
      justify-content: space-between;
    }

    .info{
      margin-top:14px;
      text-align: left;
      display: flex;
      align-items: center;
      > img{
        width:74px;
        height:74px;
        display: inline-block;
        object-fit: cover;
        border-radius: 50%;
        margin-right:14px;
      }
      &.client{
        > img{
          width: 140px;
          height: 86px;
          object-fit: contain;
          border-radius: 4px;
          border: solid 1px #e8e8e8;
          box-sizing:border-box;
          margin-right:14px;
          background-color: #fff;
        }
      }
      >div{
        display: flex;
        span{
          display:inline-block;
          height: 44px;
          line-height: 44px;
          vertical-align: top;
        }
        .name{
          font-size: 26px;
          font-weight: bold;
          margin-right:10px;
        }
        >div{
          .job{
            font-size: 14px;
            font-weight: 600;
            color: #5d5d5d;
            margin-right:11px;
          }
          .job:after{
            content:"";
            width:1px;
            height: 11px;
            border-right: solid 1px #979797;
            margin-left:11px;
          }
          .date{
            font-size: 14px;
            font-weight: 500;
            color: #5d5d5d;
          }
        }
      }
    }

    .balloon {
      position: relative; // after에 대한 relative
      top: 5px; // .artistInfo .name에 높이 맞춤
      width: 260px;
      padding: 9px 20px;
      box-sizing:border-box;
      border-radius: 4px;
      background-color: #000;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      >span{
        font-size: 12px;
        font-weight: 500;
        color:#0084ff
      }
    }
    .balloon:after {
      border-top: 12px solid #000;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      top: 34px;
      left: 204px;
    }
  }

  .buttons{
    display: flex;
    justify-content: center;
    margin-bottom:94px;
    >div{
      cursor: pointer;
      display:inline-block;
      text-align: center;
      width: 180px;
      height:48px;
      line-height:48px;
      margin:28px 6px;
      font-weight: bold;
      color: #fff;
    }
    div:nth-child(1){
      border-radius: 4px;
      background-color: #0053ff;

    }
    div:nth-child(2){
      border-radius: 4px;
      border: solid 1px #dedede;
      color: #808080;
    }
    /* div:nth-child(3){
      border: 1px solid #7F7F7F;
    } */
  }

  @media (max-width: 1000px) {
    .matchingDescrpitionWrapper{
      .matchingDescrpitionHeader{
        display: block;
        .balloon{
          margin-left: auto;
          top:0;
        }
      }
    }
  }

  @media (max-width: 900px) {
    padding-right:0px;
    .matchingstates{
      > span{
        padding-left:14px;
        padding-right:14px;
        padding-bottom:14px;
        margin-right:0; // 768에서 안깨지는 마지막 margin
      }
      .active:after{
        display: none;
      }
      .active {
        border-bottom: 3px solid black;
        height: 45px;
        box-sizing: border-box;
      }
    }

    .matchingHeader{
      max-width:initial !important;
      > p{
        padding:0 18px;
        margin: 37px 0 16px;
        box-sizing:border-box;
      }
      .matchingList{
        padding-left:18px;
        padding-right:18px;
        display: flex; // 900부터 slide 제거
        overflow-x: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
      }
      .matchingList::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }
    }

    .matchingDescrpitionWrapper{
      .matchingDescrpitionHeader{
        display:flex;
        .balloon{
          top:5px;
          padding: 10px 0px 12px;
        }
      }
    }

    >.title{
      padding: 0 18px;
      box-sizing:border-box;
      margin: 10px 0 48px;
      h2{
        margin-top:0;
      }
    }
  }
  @media (max-width: 720px) {
    .noContent{
      margin-top:103px;
    }
    .matchingstates{
      > span{
        >span{
          background-color: white;
          border-radius:100%;
          margin-left:5px;
          width:initial;
        }
      }
    }
    .matchingHeader{
      p{
        margin-top:37px
      }
      .matchingList{
        .artist{
          > img{
            width:${props => props.itemWidth - 6}px;
            height:${props => props.itemWidth - 6}px;
            margin:3px 3px 13px 3px;
          }
        }
        .artist:hover{
          > img{
            margin:0 0 6px 0;
          }
        }
        .client{
          >img{
            width:${props => props.itemWidth - 18}px;
            height: 45px;
            margin:6px 9px 5px 9px;
          }
        }
        .client:hover{
          > img{
            width:${props => props.itemWidth}px;
            height: 56px;
            margin:0 0 8px 0;
          }
        }

        .active{
          &.artist{
            > img{
              width:47px;
              height:47px;
              margin:0 0 6px 0;
            }
          }
          &.client{
            >img{
              width:${props => props.itemWidth}px;
              height: 56px;
            }
          }
        }

      }
    }
    .matchingDescrpitionWrapper{
      padding:0;
      border-top:10px solid #f7f7f7;
      background-color: white;
      border-radius:0px;
      .matchingDescrpitionHeader{
        padding:24px 18px 0;
        display: block;
        .info{
          margin-top:0px;
          &.artist{
            > img{
              width:72px;
              height:72px;
            }
          }
          >div{
            display: block;
            span{
              height: inherit;
              line-height: inherit;
            }
            .name{
              font-size: 20px;
            }
            >div{
              margin-top:6px;
              .job{
                font-size: 13px;
                margin-right:10px;
              }
              .job:after{
                margin-left:10px;
              }
              .date{
                font-size: 13px;
              }
            }
          }
        }
    
        .balloon{
          width:100%;
          margin-top:16px;
        }
        .balloon:after{
          display: none;
        }
      }
    }
  }
  @media (max-width: 720px) {
    .buttons{
      margin-bottom:68px;
    }
  }

  @media (max-width: 520px) {
    .buttons{
      position: sticky;
      bottom: 0;
      background: white;
      z-index: 1;
      >div{
        width:158px;
        margin: 12px 6px;
      }
    }
  }
`