import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./auth";

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const location = useLocation()
  if (!user) {
    // user is not authenticated
    return <Navigate to="/start" state={{from:location.pathname}}/>;
  }
  return children;
};