import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { useAuth } from "../auth/auth";
import { useModal } from "../components/modal/modal";
import { useState, useEffect } from "react";

const ErrorHandler = ({ children }) => {
  const { logout } = useAuth()
  const location = useLocation();
  const navigate = useNavigate()
  const { modalShow, modalClose } = useModal()
  const [errorCode, setErrorCode] = useState(get(location.state, "errorStatusCode"))

  useEffect(()=>{
    setErrorCode(get(location.state, "errorStatusCode"))
  },[location.state])

  useEffect(()=>{
    if(!errorCode){return}
    switch (errorCode) {
      case "40101":
        logout()
        modalShow({ message: "로그인이 필요합니다.", submessage: '로그인하고 활동을 시작해보세요!', actionButton: <div className="blackButton" onClick={() => { modalClose() }}>닫기</div> })
        navigate('/start')
        break
  
      case "40103": // Duplicate username 단순 아이디 중복 확인
        modalShow({ message: "이미 존재하는 아이디입니다.", actionButton: <div className="blackButton" onClick={() => { modalClose() }}>다시 시도하기</div> })
        break

      case "40104": // 회원가입 승인 대기중
        navigate('/start/signup/complete',{ state: { from: '/' } })
        break
  
      case "40105": // Need Nice Authenticate
        modalShow({ message: "본인인증이 필요합니다.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
        break
  
      case "40106": // Already Registry User 회원가입 시 아이디 최종 중복 확인.
        modalShow({ message: "이미 가입된 유저입니다.", actionButton: <div className="blackButton" onClick={() => { modalClose(); navigate('start/find') }}>아이디/비밀번호 찾기</div> })
        break
  
      case "40107": // 정지계정
        modalShow({ message: "정지된 계정입니다.", submessage: '고객센터에 문의해주세요.', actionButton: <div className="blackButton" onClick={() => { modalClose(); }}>닫기</div> })
        break
  
      case "40201": // Not Enough Point
        modalShow({ message: "알 수 없는 에러입니다.", submessage: '에러가 지속된다면 관리자에게 문의해주세요.(에러코드: 40201)', actionButton: <div className="blackButton" onClick={() => { modalClose() }}>다시 시도하기</div> })
        break
  
      case "40301": // Please Retry
        modalShow({ message: "다시 시도해주세요.", submessage: '에러가 지속된다면 관리자에게 문의해주세요.(에러코드: 40301)', actionButton: <div className="blackButton" onClick={() => { modalClose() }}>다시 시도하기</div> })
        break

      case "40601": //
        navigate('/pageNotFound')
        break
  
      case "40602": // Already Data Existed, POST /client/matching
        modalShow({ message: "이미 존재하는 매칭입니다.", submessage: '추가 매칭을 원하신다면 고객센터로 문의해주세요.', actionButton: <div className="blackButton" onClick={() => { modalClose() }}>닫기</div> })
        break
  
      case "40603": // Included Special Char
        modalShow({ message: "포함시킬 수 없는 특수문자가 있습니다.", submessage: '에러가 지속된다면 관리자에게 문의해주세요.(에러코드: 40603)', actionButton: <div className="blackButton" onClick={() => { modalClose() }}>다시 시도하기</div> })
        break
  
      case "40604": // Account does not exist
        modalShow({ message: "존재하지 않는 계정입니다.", submessage: '관리자에게 문의해주세요.(에러코드: 40604)', actionButton: <div className="blackButton" onClick={() => { modalClose() }}>다시 시도하기</div> })
        break
  
      case "40605": // POST /client/matching, DELETE/user 에서 40602 구별하기 위해 만듬. 실제 back에서 40602 보내지만 front에서 DELETE/user 인 경우 40605로 보내 에러메세지 구체화함.
        modalShow({ message: "이미 삭제된 유저입니다.", submessage: '에러가 지속된다면 관리자에게 문의해주세요.(에러코드: 40605)', actionButton: <div className="blackButton" onClick={() => { modalClose() }}>다시 시도하기</div> })
        break

      case "delete": // user delete
        navigate('/deleteUser')
        break
      
      default:
        break;
    }
    window.history.replaceState({}, document.title) // site reload시 error message 남아있는것 방지.
    setErrorCode()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[errorCode])
  
  return children
};

export default ErrorHandler;
