import styled from "styled-components";
import { useEffect } from "react";
import { useWindowSize } from '../../customHook/useWindowSize';

export default function AddressModal({ content, setCloseButtonStyle }){

  const { innerWidth, innerHeight } = useWindowSize()

  useEffect(()=>{
		let style = {
			top: "0",
    	right: "-26px",
			filter:"invert(85%) sepia(89%) saturate(3%) hue-rotate(183deg) brightness(110%) contrast(97%)",
		}
		setCloseButtonStyle(style)
		return () => {setCloseButtonStyle({})}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])


  return(
    <Div width={innerWidth} height={innerHeight}>
      {content}
    </Div>
  )
}

const Div = styled.div`
  max-width: 400px;
  width: calc(${props => props.width}px * 0.8);
  height: calc(${props => props.height}px * 0.9);
  max-height: 400px;
  border-radius: 12px;
  background-color: #fff;
  overflow: auto;
  position: relative;
  z-index: 1;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

`
