import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CreativeImages from '../../components/creatives/creativeImages'

import { useAuth } from "../../auth/auth";
import { useBlock } from '../../customHook/useBlock';
import useAPI from '../../api/useAPI';
import ProfileAside from '../../components/profileAside';

import styled from 'styled-components';

export default function ArchiveDetail() {
  const { user } = useAuth();
  const { getAPI } = useAPI()
  const { setBlock } = useBlock()
  const [apiData, setApiData] = useState()
  let { archive_idx } = useParams();
  let user_idxForViewCount = user ? user.data.user_idx : 0 // view count용

  useEffect(() => {
    setBlock(true)
    const fetchAPI = async () => {
      let url = `/client/campaign?archive_idx=${archive_idx}&user_idx=${user_idxForViewCount}`
      let { data } = await getAPI(url)
      setApiData(data)
      setBlock(false)
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.pathname])
    // sideSearchModal에서 url바뀔때 rendering안되어 dependency 추가

  const getProject = (apiData) => {
    return (
      <CreativeImages 
        brand={apiData.archive_brand}
        name={apiData.archive_name}
        date={apiData.archive_start}
        images={apiData.sub_img}
        redirectUrl={`/brand/${apiData.user_idx}`}
        member={apiData.member}
        style={{paddingTop:'35px'}}
      />
    )
  }

  return (
    <Div>
      <div className="banner" />
      {apiData &&
        <div className="archiveDetailWrapper">
          {apiData && <ProfileAside profileData={apiData} type="archive" />}
          <div className="archiveArea">
            {getProject(apiData)}
          </div>
        </div>
      }
    </Div>
  );

}


const Div = styled.div`
  .banner {
    width:100%;
    height: 220px;
    background-image: linear-gradient(to top, #6b6b6b, #424242);
  }

  .archiveDetailWrapper{
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  
    .archiveArea{
      width:100%;
      min-width:324px;
      margin-left:43px;
      margin-bottom:35px;
    }
  }

  @media (max-width: 1500px) {
    .archiveDetailWrapper{
      width:95%;
    }
  }
 
  @media (max-width: 900px) {
    .archiveDetailWrapper{
      width:100%;
      display: block;
      > div{
        height: 362px; // profileAside가 위로 올라갔을때의 높이
        border-bottom:10px solid #f7f7f7;
        box-sizing:border-box;
      }
      .archiveArea {
        width:90%;
        margin:0 auto;
        height:initial;
        border-bottom:0;
      }
    }
  }
`