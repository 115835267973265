import styled from "styled-components"
import userImage from "../../images/userImage.svg"

// searchModal, search Result, archive Detail, matcing, projectForm

export default function ArtistSmallProfile({ className, artist, onClick, style }) {
  return (
    <Div onClick={onClick} style={style} className={className}>
      {artist &&
        <>
          <div className="profile_image">
            <img src={artist.user_profile || artist.member_profile || userImage} alt="profile_image" />
          </div>
          <div className="artistSmallProfileDetail">
            <div>{artist.user_name || artist.member_name}</div>
            <div className="occupation">{artist.job_detail}</div>
          </div>
        </>
      }
    </Div>
  )
}


const Div = styled.div`
  display: flex;
  align-items: center;
  margin: 11px 0;
  box-sizing: border-box;
  padding-left: 12px;
  width: 100%; 
  height: 108px;
  border-radius: 4px; 
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.1);
      
  &:hover{
    .profile_image{
      background-color: rgba(225, 225, 225, 0.7);
      transition: 0.2s;
    }
    .artistSmallProfileDetail{
      > div{
        transition: 0.2s;
        color: #b9b9b9;
      }
      .occupation{
        transition: 0.2s;
        color: #cececf;
      }
    }

  }
  .profile_image{
    margin: 10px 0px 10px 10px;
    border-radius:50%;
    img{
      display: block;
      width: 72px;
      height:72px;
      object-fit: cover;
      border-radius: 50%;
      z-index: -1; // for hover
      position: relative; // for hover
    }
  }

  .artistSmallProfileDetail{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 20px;
    > div{
      font-family: Pretendard;
      font-size:18px;
      font-weight: bold;
    }
    .occupation{
      font-family: Montserrat;
      font-size:14px;
      font-weight:500;
      margin-top:4px;
      color: #606063;
    }
  }
`