import ArrangeBar from "../../../components/selectSearchBar"
import { useState, useEffect } from "react"
import { useAuth } from "../../../auth/auth"
import { occupationIndex } from '../../../listsAndObjects';
import { toSnakeCase } from "../../../converter"
import PageNumber from "../../../components/pageNumber"
import styled from "styled-components"
import useAPI from "../../../api/useAPI"
import { useScrollToTop } from "../../../customHook/userScrollToTop"
import { useBlock } from "../../../customHook/useBlock";
import { useNavigate } from 'react-router-dom';
import { useModal } from "../../../components/modal/modal";
import ArtistCard from "../../../components/artistProfile/artistCard";
import checkIcon from "../../../images/checkIcon.svg"

export default function WishList() {

  const { user } = useAuth()
  const { getAPI, putAPI } = useAPI()
  const { scrollToTop } = useScrollToTop()
  const { setBlock } = useBlock()
  const { modalShow, modalClose } = useModal()
  const navigate = useNavigate()
  const [prefessionIndex, setProfessionIndex] = useState(0)
  const [sort, setSort] = useState(0) // 0 이름순, 1 등록순
  const [filter, setFilter] = useState(1)
  const [userLocation, setUserLocation] = useState('')
  const [selectPageno, setSelectPageno] = useState(0)
  const [apiData, setApiData] = useState([])
  const [reload, setReload] = useState(Math.random())
  const [isEditMode, setEditMode] = useState(false)
  const [editChecked, setEditChecked] = useState([])

  const [total_pageno, setTotal_pageno] = useState(1)

  let profession = Object.keys(toSnakeCase(occupationIndex)).find(key => toSnakeCase(occupationIndex)[key] === prefessionIndex) // photographer 등 직업
  let url = `/user/wishlist?profession=${prefessionIndex}&sort=${sort}&filter=${filter}&location=${userLocation}`

  useEffect(() => {
    setBlock(true)
    const fetchAPI = async () => {
      let { data } = await getAPI(url, user)
      setApiData(data?.artists)
      setTotal_pageno(data?.total_pageno)
      setSelectPageno(0)
      setBlock(false)
      setEditMode(false)
      setEditChecked([])
      scrollToTop()
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reload])



  useEffect(() => {
    if (!selectPageno) { return }
    setBlock(true)
    const fetchAPI = async () => {
      let newUrl = url + `&pageno=${selectPageno}`
      let { data } = await getAPI(newUrl, user)
      setApiData(data?.artists)
      setTotal_pageno(data?.total_pageno)
      setBlock(false)
      scrollToTop()
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno])

  // 2번 불러옴...

  const setProfession = (value) => {
    // photographer등의 value를 숫자로 변환 후 저장.
    if (value === 'artist') {
      setProfessionIndex(0)
      return
    }
    setProfessionIndex(toSnakeCase(occupationIndex)[value])
  }


  const deleteWishList = async (e, editChecked, user) => {
    e.stopPropagation()
    if (editChecked.length === 0) return
    if (user) {
      for (let i = 0; i < editChecked.length; i++) {
        let url = `/user/wishlist?idx=${editChecked[i]}`
        let { result } = await putAPI(url, user)
        if (result !== 'success') {
          modalShow({ message: `위시리스트 삭제에 실패했습니다.`, submessage: '잠시 후 다시 시도해주세요.', actionButton: <div onClick={() => { modalClose() }} className="blackButton">확인</div> })
          setReload()
          break
        }
      }
      setReload()
    }
  }


  const artistList = apiData?.map((artistData) => {
    if (isEditMode) {
      let checkedIndex = editChecked.indexOf(artistData.user_idx)
      return (
        <div key={artistData.user_idx} className="editBox"
          onClick={checkedIndex >= 0 ?
            () => {
              let newEditChecked = [...editChecked]
              newEditChecked.splice(checkedIndex, 1)
              setEditChecked(newEditChecked)
            }
            :
            () => { setEditChecked([...editChecked, artistData.user_idx]) }}
        >
          <div className="checkImage">
            <div style={checkedIndex >= 0 ? { backgroundImage: `url(${checkIcon})`, opacity: '1' } : { backgroundImage: `url(${checkIcon})`, opacity: '0' }}></div>
          </div>
          <div className="editButton" style={checkedIndex >= 0 ? { opacity: '1' } : {}} />
          <ArtistCard artistData={artistData} />
        </div>
      )
    } else {
      return (
        <ArtistCard key={artistData.user_idx} artistData={artistData} />
      )
    }
  })

  return (
    <Div>
      <div className="header">위시리시트</div>
      <div className="subHeader">관심있는 아티스트를 둘러보세요.</div>
      <div className="underLine" />
      <ArrangeBar urlPath={profession} isArtists={true} sort={sort} setSort={setSort} setSearchCategory={setProfession} searchCategory={profession} userLocation={userLocation} setUserLocation={setUserLocation} filter={filter} setFilter={setFilter}></ArrangeBar>
      {apiData.length > 0 ?
        <>
          <div className="editArea">
            {!isEditMode ?
              <div className="edit" onClick={() => { setEditMode(true) }}>
                편집
              </div>
              :
              <div>
                <span onClick={() => { setEditChecked([]) }}>선택해제</span>
                <span onClick={(e) => { deleteWishList(e, editChecked, user) }}>삭제</span>
                <span onClick={() => { setEditMode(false); setEditChecked([]) }}>취소</span>
              </div>
            }
          </div>
          <div className="artistList">{artistList}</div>
        </>
        :
        <div className="noContent">
          <span>🤔</span>
          <p>
            등록한 위시리스트가 없습니다.<br />
            아티스트를 둘러보시겠습니까?
          </p>
          <div className="blueButton" onClick={() => { navigate('/artist') }}>
            아티스트 둘러보기
          </div>
        </div>
      }
      {total_pageno >= 2 && <PageNumber style={{ marginTop: '40px' }} total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  margin:0 auto 120px;
  padding-right:40px;
  box-sizing:border-box;
  text-align:left;
  .delete{
    display: block !important;
  }
  .header{
    font-family: Pretendard;
    font-size: 26px;
    font-weight: bold;
    margin:56px 0 12px;
  }
  .subHeader{
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.02px;
    color: #606063;
  }
  .underLine{
    margin:38px 0 32px;
    border-top: solid 1px #000;
  }
  .editArea{
    margin:18px 0 16px;
    text-align:right;
    *{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.04px;
    }
    .edit{
      color: #0053ff;
      cursor:pointer;
    }
    span{
      margin-left:20px;
      color: #999;
      cursor:pointer;
    }
  }
  .artistList{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px 20px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    .editBox{
      position: relative;
      cursor:pointer;
      .checkImage{
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: solid 1px #dedee1;
        background-color: #fff;
        top:12px;
        left:12px;
        z-index:2;
        > div{
          transition:0.2s;
          height:100%;
          width:100%;
        }
      }
      .editButton{
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index:1;
        opacity: 0;
        transition: 0.2s;
      }
    }
    .editBox:hover{
      .editButton{
        opacity: 1;
      }
    }
  }
  .noContent{
    margin-top:28px;
  }
  @media (max-width: 900px) {
    padding: 0 18px;
    box-sizing:border-box;
  }

  @media (max-width: 720px) {
    .artistList{
      width:100%;
      grid-template-columns: 1fr 1fr;
      margin-top: 31px;
    }
    .editArea{
      margin-top: -30px;
    }
    .noContent{
      margin-top:76px;
    }
  }
`
