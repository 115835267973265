import { useState } from 'react';
import { useForm } from "react-hook-form";
import { emailRegExp, passwordRegExp } from '../../../components/regex';
import { useAuth } from '../../../auth/auth';
import { useLocation } from 'react-router-dom';
import useAPI from '../../../api/useAPI';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../../components/modal/modal';
import { useBlock } from '../../../customHook/useBlock';
import MainLogo from '../../../images/logo.svg'

export default function Login() {

  const { login } = useAuth();
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [wrongInfo, setWrongInfo] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const { getAPI, postAPI } = useAPI()
  const { modalShow, modalClose } = useModal()
  const { setBlock } = useBlock()

  const location = useLocation()

  const onSubmit = async (submitData) => {
    setBlock(true)
    setWrongInfo(false)

    const response = await postAPI(`/jwt/login`, false, submitData)

    if (response.result) {
      let user = await response.data
      let { data, result } = await getAPI(`/user/profile`, user)
      if (result === "success") {
        user.user_profile = data.user_profile
        login(user, rememberMe, location.state?.from)
        // login으로 redirect 되기전의 page 정보. ProtectedRoute에서 저장됨.jwt인증 실패하여 재 로그인해야할때만 저장됨
        setWrongInfo(false)
      } else {
        modalShow({ message: "프로필 이미지를 불러오는데 오류가 있습니다. 관리자에게 문의해주세요", actionButton: <div className="blackButton" onClick={() => { modalClose() }}>다시 시도하기</div> })
      }
      // return
    }
    if (response.responseCode === '40102') {
      setWrongInfo(true)
    }
    setBlock(false)
  }

  let handleOnChange = () => {
    setRememberMe(!rememberMe)
  }

  return (
    <Form id="loginForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="mainLogo">
        <img src={MainLogo} alt="logo" />
      </div>

      <label htmlFor="user_id">아이디</label>
      <input type="email" placeholder="아이디를 입력해주세요" id="user_id" {...register("user_id", { required: true, pattern: { value: emailRegExp }, maxLength: 60 })} />
      {errors.user_id?.type === 'pattern' && <span>올바른 이메일 형식을 입력해주세요.</span>}
      {errors.user_id?.type === 'maxLength' && <span>60자 이내로 입력해주세요.</span>}
      {errors.user_id?.type === 'required' && <span>이메일을 입력해주세요.</span>}

      <label htmlFor="user_pw">비밀번호</label>
      <input type="password" autoComplete="off" placeholder="비밀번호를 입력해주세요" name="user_pw" {...register("user_pw", { required: true, pattern: { value: passwordRegExp } })} />
      {errors.user_pw?.type === 'pattern' && <span>영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요.</span>}
      {errors.user_pw?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
      
      <div className="autoLoginAndFind">
        <div className="checkbox">
          <input id="자동로그인" type="checkbox" onChange={() => handleOnChange()}/>
          <label htmlFor="자동로그인">로그인 유지</label>
        </div>
        <div className="find">
          <div onClick={() => { navigate('/start/find?type=id',{ state: { from: '/' } }) }}>아이디 찾기</div>
          <div className="bar"/>
          <div onClick={() => { navigate('/start/find?type=password',{ state: { from: '/' } }) }}>비밀번호 찾기</div>
        </div>
      </div>
      <button form='loginForm' type="submit" className="blackButton">로그인 하기</button>
      {wrongInfo && <span>아이디 혹은 비밀번호가 틀렸습니다.</span>}
      <p>지금 회원가입하고  커리어를 성장시켜보세요!</p>
      <div className="whiteButton" onClick={() => { navigate('/start/signup/TOS',{ state: { from: '/' } })}}>회원가입 하기</div>
    </Form>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding:85px 0 105px;
  width: 360px;
  margin: 0 auto;

  .mainLogo{
    margin-bottom:30px;
    >img{
      width: 150px;
      height: 35px;
      display: block;
      margin: 0 auto;
    }
  }

  > span{
    color:#EB5757;
    display: block;
    height:20px;
    font-size: 14px;
    margin:2px 0 -22px;
    text-align:left;
  }

  input{
    height:48px;
  }
  label{
    margin-top:20px;
  }

  .autoLoginAndFind{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:20px 0 40px;

    .find{
      display: flex;
      align-items: center;
      >div{
        cursor:pointer;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.22px;
        color: #999;
      }
      .bar{
        cursor:inherit;
      }
      .bar:after{
        content:'';
        display: inline-block;
        width: 1px;
        height: 11px;
        background-color: #999;
        margin: 0px 8px;
      }
    }
  }

  .blackButton{
    font-weight: bold;
    letter-spacing: -0.25px;
  }

  >p{
    margin:52px 0 18px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #292929;
  }

  .whiteButton{
    width: 100%;
    height: 56px;
    border-radius: 28px;
    border: solid 1px #000;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.25px;
    color: #000;
  }

  @media (max-width: 720px) {
    width: 324px;
    padding-top:48px;
  }

`

