
import Header from './header';
import CreativeItem from '../../components/archive/creativeItem';
import SearchList from '../../components/search/searchList'
import useQuery from '../../api/useQuery';
import { useBlock } from '../../customHook/useBlock';
import styled from "styled-components"
import ArtistSmallProfile from '../../components/profile/artistSmallProfile';
import { useNavigate } from 'react-router-dom';
import InspirationItem from '../../components/inspiration/inspirationItem';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ARROW_left from '../../images/arrow_left.svg'
import ARROW_right from '../../images/arrow_right.svg'
import { useWindowSize } from '../../customHook/useWindowSize';

function Sections() {
  const [apiData] = useQuery({ url: `/user/main` })
  const navigate = useNavigate()
  const { BlockUI } = useBlock()
  const { innerWidth } = useWindowSize()
  const isTabletSize = innerWidth <= 1250

  // eslint-disable-next-line array-callback-return
  const inspirationItem = apiData?.inspiration.map((inspirationData, index) => {
    if (!isTabletSize) {
      return (
        <InspirationItem
          key={inspirationData.inspiration_idx}
          InspirationData={inspirationData}
        ></InspirationItem>
      )
    } else {
      if (index < 3) {
        return (
          <InspirationItem
            key={inspirationData.inspiration_idx}
            InspirationData={inspirationData}
          ></InspirationItem>
        )
      }
    }
  });

  // eslint-disable-next-line array-callback-return
  const artistItem = apiData?.artists.map((artist, index) => {
    // ArtistSmallProfile Component의 style이 slick 내부의 style과 겹쳐 없어져서 div 필요.
    if (!isTabletSize) {
      return (
        <div key={artist.user_idx}>
          <ArtistSmallProfile artist={artist} key={artist.user_idx}
            onClick={() => { navigate(`/artist/${artist.user_idx}`) }}
          />
        </div>
      )
    } else {
      if (index < 8) {
        return (
          <div key={artist.user_idx}>
            <ArtistSmallProfile artist={artist} key={artist.user_idx}
              onClick={() => { navigate(`/artist/${artist.user_idx}`) }}
            />
          </div>
        )
      }
    }

  })

  // eslint-disable-next-line array-callback-return
  const creativeItem = apiData?.archive.map((archive, index) => {
    if (!isTabletSize) {
      return (
        <CreativeItem key={archive.archive_idx} creativeData={archive} hoverImage={true}></CreativeItem>
      )
    } else {
      if (index < 5) {
        return (
          <CreativeItem key={archive.archive_idx} creativeData={archive} hoverImage={true}></CreativeItem>
        )
      }
    }
  })

  function NextArrow({ currentSlide, slideCount, slidesToShow, onClick }) {
    return (
      <div onClick={onClick} className={"next slick-arrow" + (currentSlide === slideCount - slidesToShow ? " slick-disabled" : "")}>
        <img src={ARROW_right} alt="arrow_right" />
      </div>
    );
  }

  // https://codesandbox.io/s/react-slick-custom-arrows-accessibility-myqq0yz54y?from-embed=&file=/src/index.js:1777-1799

  function PrevArrow({ currentSlide, onClick }) {
    return (
      <div onClick={onClick} className={"prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")}>
        <img src={ARROW_left} alt="arrow_left" />
      </div>
    );
  }

  const insperationSettings = {
    className: "inspiration_slick",
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    appendDots: (dots) => (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: 'dots_custom',
    nextArrow: <NextArrow slidesToShow={3} />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  };

  const artistSetting = {
    className: "artist_slick",
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    nextArrow: <NextArrow slidesToShow={4} />,
    prevArrow: <PrevArrow />,
    dots: true,
    appendDots: (dots) => (
      <div
        style={{
          width: '100%',
          height: '30px',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '19px', // 떨어진 거리 40px - ul margin 10px - item margin bottom 11px
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: 'dots_custom',
  }

  const archiveSetting = {
    className: "archive_slick",
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow slidesToShow={4} />,
    prevArrow: <PrevArrow />,
  }

  return (
    <Div>
      {apiData ?
        <>
          <div className="sectionItem first">
            <Header
              headerTitle="Inspiration"
              link="/inspiration"
            ></Header>
            <div className="inspirationWrapper">
              {!isTabletSize ?
                <Slider  {...insperationSettings}>
                  {inspirationItem}
                </Slider>
                :
                <div className="inspiration">
                  {inspirationItem}
                </div>
              }
            </div>
          </div>
          <div className="sectionItem second">
            <Header
              headerTitle="Artists"
              headerDescription="Discover Fablous Artists"
              link="/artist"
            ></Header>

            <div className="artistSmallProfileWrapper" >
              {!isTabletSize ?
                <Slider {...artistSetting}>
                  {artistItem}
                </Slider>
                :
                <div className="artistSmallProfile">
                  {artistItem}
                </div>
              }
            </div>

          </div>
          <div className="sectionItem third">
            <Header
              headerTitle="Category"
            ></Header>
            <SearchList className={"mainpage"}></SearchList>
          </div>
          <div className="sectionItem" style={{ border: 'none' }}>
            <Header
              headerTitle="Archive"
              headerDescription="Look Amazing Work"
              link="/archive"
            ></Header>
            <div className="creativeItemWrapper">
              {!isTabletSize ?
                <Slider {...archiveSetting}>
                  {creativeItem}
                </Slider>
                :
                <div className="creativeItem">
                  {creativeItem}
                </div>
              }
            </div>

          </div>
        </>
        :
        <BlockUI />
      }
    </Div>
  );
}



export default Sections;

const Div = styled.div`
  max-width: 1400px;
  width:100%;
  margin: 0 auto 182px;

  .slick-slider{
    // slick 공통 css
    .slick-slide{
      position: relative;
    }
    .slick-disabled {
      cursor: default !important;
      >img{
        opacity: 0.5;
      }
    }
    .slick-arrow{
      cursor:pointer;
      margin: 0 14px;
      img{
        width: 12px !important;
        height: 18px !important;
      }
    }
    .next{
      position: absolute;
      top:-66px;
      right:0px;
    }
    .prev{
      position: absolute;
      top:-66px;
      right:0px;
      img{
        margin-right:calc(28px + 15px); // 사이간격 + 이미지 너비
      }
    }
  }

  .artistSmallProfileWrapper{
    margin-top:-11px; // slick item의 margin-top 11px 잡혀있어서 빼야함.
    .slick-slider{
      .next, .prev{
        top:-55px; // -66px - (-11)px
      }
    }
    .dots_custom li.slick-active button {
      background-color: #000;
    }
    .dots_custom li button {
      background-color: #d8d8d8;
    }
  }

  .inspiration_slick{
    margin: 0 -14px;
    .slick-slide{
      padding: 0 14px;
      box-sizing:border-box;
    }
  }
  .artist_slick{
    margin: 0 -10px;
    .slick-slide{
      padding: 0 10px;
      box-sizing:border-box;
    }
  }
  .archive_slick{
    margin: 0 -10px;
    .slick-slide{
      padding: 0 10px;
      box-sizing:border-box;
    }
  }

  .sectionItem{
    border-bottom: 1px solid #f2f2f2; // 구분선
  }
  .first{
    margin-top: 8px;
    padding-bottom: 76px; // -16 -> design 72px - margin top 80px(Header component)
  }
  .second{
    padding-bottom:97px;  // 116 -> design 72px - margin bottom 11px(slick list) - dot area 30px
  }
  .third{
    padding-bottom: 114px;
  }


  @media (max-width: 1500px) {
    .sectionItem{
      .inspirationWrapper, .artistSmallProfileWrapper, .mainpage, .creativeItemWrapper{
        width:90%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: 1250px) {
    .sectionItem{
      .inspirationWrapper{
        overflow: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        .inspiration{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap:0 18px;
          > div{
            min-width:260px;
          }
        }
      }
      .inspirationWrapper::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }

      .artistSmallProfileWrapper{
        width:100%;
        /* margin: 0 -10px; // 왜필요한지몰라서 잠시 제거 */
        overflow: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        .artistSmallProfile{
          /* width: 1122px; */
          margin: 0 auto;
          padding: 0 10px;
          
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap:0 14px;
          > div{
            min-width:270px;
            /* max-width:270px; */
          }
        }
      }
      .artistSmallProfileWrapper::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }

      .creativeItemWrapper{
        overflow: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        .creativeItem{
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-gap:0 18px;
          > div{
            width:246px;
          }
        }
      }
      .creativeItemWrapper::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }
    }
  }

  @media (max-width: 1250px) {
    .sectionItem{
      .inspirationWrapper{
        width:100%;
        .inspiration{
          margin:0 36px;
        }
      }
      .artistSmallProfileWrapper{
        width:100%;
        .artistSmallProfile{
          margin:0 26px; // 그림자부분 padding 10이있어 26해야 더하면 36이되어 inspirationWrapper과 간격 같음
        }
      }
      .mainpage{
        width:100%;
        .searchListItemsForTablet{
          margin-left:36px;
          > div:last-child{
            padding-right:36px;
          }
        }
      }
      .creativeItemWrapper{
        width:100%;
        .creativeItem{
          margin-left:36px;
          > div:last-child{
            padding-right:36px;
          }
        }
      }
    }
  }

  @media (max-width:1194px) { // artistSmallProfile width가 170이 되는 마지막지점
    .sectionItem{
      .artistSmallProfileWrapper{
        .artistSmallProfile{
          > div:nth-child(4){
            padding-right:26px;
          }
          > div:last-child{
            padding-right:26px;
            margin-bottom: 20px; // blur 다 보이게하기위해
          }
        }
      }
    }
  }
  

  @media (max-width: 900px) {
    .sectionItem{
      .inspirationWrapper{
        .inspiration{
          margin:0 18px;
        }
      }
      .artistSmallProfileWrapper{
        .artistSmallProfile{
          margin:0 8px; // 그림자부분 padding 10이있어 8해야 더하면 18이되어 inspirationWrapper과 간격 같음
          > div:nth-child(4){
            padding-right:18px;
          }
          > div:last-child{
            padding-right:18px;
          }
        }
      }
      .mainpage{
        .searchListItemsForTablet{
          margin-left:18px;
          > div:last-child{
            padding-right:18px;
          }
        }
      }
      .creativeItemWrapper{
        .creativeItem{
          margin-left:18px;
          > div:last-child{
            padding-right:18px;
          }
        }
      }
    }
  }

  @media (max-width:853px) { // inspiration item이 min-width인 260이되는 지점
    .sectionItem{
      .inspirationWrapper{
        .inspiration{
          margin-right:0px;
          > div:last-child{
            padding-right:18px;
          }
        }
      }
    }
  }
  @media (max-width: 72px) {
    margin-bottom:88px;
  }

  @media (max-width: 480px) {
    margin-bottom:88px;
    .sectionItem{
      padding-bottom: 42px !important;
    }
  }


`
