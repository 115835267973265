import { useEffect } from "react"
import styled from "styled-components"
import ProgressBar from './progressBar'
import { useState } from "react"
import { useNavigate } from "react-router-dom"

export default function SignupType({ setSignupType, isOptionalAgreeChecked }) {

  const [userType, setActiveNextButton] = useState(false)
  const navigate = useNavigate()

  useEffect(()=>{
    isOptionalAgreeChecked === null && navigate('/start/signup/TOS',{ state: { from: '/' } })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isOptionalAgreeChecked])

  let setType = (type) => {
    if (userType === type) {
      setActiveNextButton(false)
      return
    }
    setSignupType(type)
    setActiveNextButton(type)
  }

  return (
    <Div>
      <div className="header">회원가입</div>
      <ProgressBar phase={2} />
      <div className="subHeader">가입하실 회원유형을 <br /> 선택해주세요.</div>

      <div className="checkbox">
        <input id="client" type="checkbox" checked={userType === "client"} readOnly />
        <label htmlFor="client" onClick={() => { setType("client") }} style={userType === 'client' ? { border: 'solid 1px #000' } : { border: 'solid 1px #e2e2e3' }}>
          <p>
            💼 Client
          </p>
          <p>
            브랜드, 매거진, 광고 대행사, 프로덕션, 에이전시, PR 
          </p>
        </label>
      </div>

      <div className="checkbox">
        <input id="artists" type="checkbox" checked={userType === "artists"} readOnly />
        <label htmlFor="artists" onClick={() => { setType("artists") }} style={userType === 'artists' ? { border: 'solid 1px #000' } : { border: 'solid 1px #e2e2e3' }}>
          <p>
            🎨 Artist
          </p>
          <p>
            포토, 영상, 모델, 헤어, 메이크업, 스타일리스트, 로케이션
          </p>
        </label>
      </div>

      {userType ?
        <div className="blackButton" onClick={() => { navigate('/start/signup/form',{ state: { from: '/' } }) }}>다음으로</div>
        :
        <div className="blackButton unActiveButton">다음으로</div>
      }

    </Div>
  )
}

const Div = styled.div`
  width:360px;
  padding-bottom:121px;

  .checkbox{
    margin-bottom:14px;
    [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
      top: 37px;
      left:24px;
    }
    [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
      top: 41px;
      left:28px;
    }
    label{
      display: inline-block;
      width: 100%;
      height: 94px;
      border-radius: 8px;
      border: solid 1px #e2e2e3;
      padding:25px;
      box-sizing: border-box;
      transition:0.2s;

      cursor:pointer;
      p{
        padding:0;
        margin:0;
        margin-left:32px;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.19px;
        color: #999;
      }
      p:first-child{
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.22px;
        color: #292929;
        margin-bottom:4px;
      }
    }
  }

  .blackButton{
    margin-top:54px;
  }

  @media (max-width: 720px) {
    width:324px;
    margin:0 auto;
    .checkbox{
      [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
        left:18px;
      }
      [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
        left:22px;
      }
      label{
        padding:25px 17px;
        p{
          margin-left:19px;
        }
      }
    }
  }
`