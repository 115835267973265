import { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import useAPI from "../../api/useAPI";


export default function NiceCallBack(){
  const { getAPI } = useAPI()
  const [searchParams] = useSearchParams();


  useEffect(()=>{

    let token_version_id = encodeURIComponent(searchParams.get('token_version_id'))
    let enc_data = encodeURIComponent(searchParams.get('enc_data'))
    let integrity_value = encodeURIComponent(searchParams.get('integrity_value'))
    let url = `/user/nice-callback?token_version_id=${token_version_id}&enc_data=${enc_data}&integrity_value=${integrity_value}`
    const fetchAPI = async () => {
      let { result } = await getAPI(url)
      if(result){
        window.opener.postMessage({path: window.location.pathname, check: result, token: searchParams.get('token_version_id')});
        window.close();
      }
    }
    fetchAPI()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return(
    <></>
  )
}