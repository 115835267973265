import { useState } from 'react'
import Login from './login'
import TOS from './signup/TOS'
import SignupType from './signup/signupType'
import SignupForm from './signup/signupForm'
import styled from 'styled-components'
import Find from './find'
import AfterSignup from './signup/afterSignup'
import { Route, Routes } from "react-router-dom";
import PageNotFound from '../pageNotFound'

function Signup() {
  const [isOptionalAgreeChecked, setOptionalAgreeChecked] = useState(null)
  const [signupType, setSignupType] = useState("")

  return (
    <Div>
      <div>
        <div>
          <Routes>
            <Route index element={<Login />} />
            <Route path="/signup/TOS" element={<TOS setOptionalAgreeChecked={setOptionalAgreeChecked} />}></Route>
            <Route path="/signup/type" element={<SignupType setSignupType={setSignupType} isOptionalAgreeChecked={isOptionalAgreeChecked}/>}></Route>
            <Route path="/signup/form" element={<SignupForm signupType={signupType} isOptionalAgreeChecked={isOptionalAgreeChecked}/>}></Route>
            <Route path="/signup/complete" element={<AfterSignup signupType={signupType}/>}></Route>
            <Route path="/find" element={<Find />}></Route>
            <Route path="/*" element={<PageNotFound />}></Route>
          </Routes>
        </div>
      </div>
    </Div>
  );
}

export default Signup;

const Div = styled.div`
  width:100%;
  background-color: #f9f9f9;
  padding:120px 0;
  >div{
    max-width:640px;
    border-radius: 20px;
    margin: 0 auto;
    background-color: #fff;
    text-align: center;
    >div{
      width:360px;
      margin: 0 auto;
    }
  }

  input{
    width: 100%;
    height: 44px;
    border-radius: 4px;
    border: solid 1px #dedee1;
    font-family: Pretendard;
    font-size: 14px;
    letter-spacing: -0.22px;
    color: #303033;
    padding:14px;
    box-sizing:border-box;
  }
  input::placeholder{
    color: #b1b3b3;
  }
  input:focus{
    border: solid 1px #0053ff;
  }

  label{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    display: block;
    letter-spacing: -0.22px;
    color: #292929;
    text-align: left;
    margin:34px 0 8px;
    > span{
      color:#e94d54;
    }
  }

  .header{
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    padding-top:90px;
  }

  .subHeader{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -0.31px;
    color: #292929;
    text-align: left;
    margin-bottom: 36px;
  }

  .blackButton{
    width: 100%;
    height: 56px;
    transition:0.2s;
  }
  .unActiveButton{
    cursor:inherit;
    background-color: #c4c4c3 !important;
    color: #fff !important;
    transition:0.2s;
  }


  @media (max-width: 720px) {
    padding:0;
    >div{
      max-width:inherit;
      border-radius: 0px;
    }
    .header{
      padding-top: 48px;
      font-size: 30px;
    }
    .subHeader{
      text-align:center;
    }

  }

`





