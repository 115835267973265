import styled from "styled-components"
import { useState } from "react";
import { useModal } from "./modal";
import { useWindowSize } from '../../customHook/useWindowSize';
import Select from "../select/select";
import { occupationList } from '../../listsAndObjects';

// projectForm campaign member search
export default function AddCampaginMemberModal({ searchKeyword, callback }) {

  const [nonMemberJob, setNonMemberJob] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const { modalClose } = useModal()
  const { innerWidth, innerHeight } = useWindowSize()

  const artistCategoryOptions = []
  for (let i = 0; i < occupationList.length; i++) {
    artistCategoryOptions.push({ value: occupationList[i], label: occupationList[i] })
  }

  // 등록하기
  const selectArtists = (artist) => {
    if(!nonMemberJob){
      setErrorMessage('등록 하시려는 유저의 직무를 선택해주세요')
      return
    }
    let unEnrolledArtist = { member_idx: null, member_name: artist, job_detail: nonMemberJob }
    callback(unEnrolledArtist)
    setNonMemberJob()
    modalClose()
  }

  return (
    <Div width={innerWidth} height={innerHeight}>
      <div className="message">비회원 아티스트 등록</div>
      <div className="nonMemberEnrollWrapper">
        <div className="nonMemberEnroll">
          <p>전문 아티스트 이름</p>
          <div className="name">{searchKeyword}</div>
          <p>직무 카테고리</p>
          <div className="selectItem">
            <Select
              onChange={(e) => { setNonMemberJob(e.value) }}
              options={artistCategoryOptions}
              placeholder="카테고리를 선택해주세요"
              menuListHeight={'221px'}
            />
          </div>
        </div>
        <p className="error">
          {errorMessage}
        </p>
        <div className="blackButton" onClick={() => {selectArtists(searchKeyword) }}>등록하기</div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  max-width: 400px;
  width: calc(${props => props.width}px * 0.8);
  height: calc(${props => props.height}px * 0.9);
  max-height: 549px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 12px;
  padding:48px 20px 22px;
  position:relative;
  overflow: auto;
  .error{
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    color: #EB5757;
  }
  .nonMemberEnrollWrapper{
    min-height: 447px;
    position:relative; // blackButton
  }
  .nonMemberEnroll{
    display: flex;
    flex-direction:column;
    align-items:start;
    max-width: 324px;
    margin: 0 auto;
    .name{
      width: 100%;
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      background-color: #f7f7f7;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      color: #303033;
      height:44px;
      line-height:44px;
      padding:0 16px;
      box-sizing: border-box;
      text-align: left;
    }
    .selectItem{
      width: 100%;
      height:44px;
      .css-1fr1gmj-menu{
        padding:0px !important;
      }
    }
    > p{
      margin:28px 0 10px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
    }
  }
  .blackButton{
    position:absolute;
    bottom:0px;
    left:calc(50% - 172px/2);
  }
  
  @media (max-width: 420px) {
    width: calc(${props => props.width}px * 1);
    height: calc(${props => props.height}px * 1);
    max-height:initial;
    max-width:inherit;
    border-radius:0;
    padding:0;

    .nonMemberEnrollWrapper{
      min-height:469px;
    }

    .message{
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
      padding:18px 0;
      border-bottom:1px solid #f7f7f7;
    }
    .blackButton{
      width: 324px !important;
      left: calc(50% - 324px/2);
      margin-bottom: 22px;
    }
  }
`