import { useAuth } from "../auth/auth";

export default function useHeader() {
  
  const { shouldStoreInLocalStorage, tokenValidation } = useAuth();

  const headers = async ( user ) => {
    if (user && user.access_token) {
      let tokenValidate = tokenValidation(user, shouldStoreInLocalStorage)
      let changedUserInfo = await tokenValidate()
      if(changedUserInfo){
        return  { 
          'Authorization': `${changedUserInfo.token_type} ${changedUserInfo.access_token}`, 
          'content-type': 'application/json',
          'Accept': 'application/json',
        };
      } else {
        return { 
          'Authorization': `${user.token_type} ${user.access_token}`, 
          'content-type': 'application/json',
          'Accept': 'application/json'
        };
      } 
    } else {
      return  {
        'content-type': 'application/json',
        'Accept': 'application/json'
      };
    }
  }
  return {headers}
}


