import styled from "styled-components";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useAPI from "../../../api/useAPI";
import Select from "../../../components/select/select";
import { useAuth } from "../../../auth/auth";
import { useNavigate } from "react-router-dom";
import { useModal } from '../../../components/modal/modal';
import { useBlock } from "../../../customHook/useBlock";
import { questionCategory } from "../../../listsAndObjects";

export default function QuestionAsk({ setReload }) {

  const { register, handleSubmit, formState: { errors }, control, setValue, resetField } = useForm();
  const { user } = useAuth()

  let navigate = useNavigate()
  const { modalShow, modalClose } = useModal()
  const { setBlock } = useBlock()

  const { postAPI, uploadImageAPI } = useAPI()
  const [question_category, setQuestion_category] = useState('')
  const [uploadedFile, setUploadedFile] = useState()

  const questionategoryOptions = []
   
  for (let i = 0; i < questionCategory.length; i++) {
    questionategoryOptions.push({ value: i+1, label:questionCategory[i]})
    // <Select/> 에서 defaultValue find시 value가 0이면 인식 못하는듯..
  }

  const onSubmit = async (submitData) => {
    if(submitData.question_file.length !== 0){
      const uploadResponse = await uploadImageAPI(submitData.question_file[0]) // API 내부에 setBlock 있음
      if (uploadResponse.result === 'success') {
        submitData.question_file = uploadResponse.data.img_url
      } else {
        modalShow({ message: `이미지 업로드에 실패하였습니다. 관리자에게 문의해주세요.`, actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
        setBlock(false)
        return
      }
    } else {
      submitData.question_file = null
    }
    setBlock(true)
    let url = `/user/question`
    let { result } = await postAPI(url, user, submitData)
    if (result === "success") {
      setQuestion_category('')
      setValue("question_title", '')
      setValue("question_board", '')
      navigate('/mypage/question')
      setReload(Math.random())
    }
    setBlock(false)
  }

  return (
    <Div>
      <div className="underLine" />
      <form className="questionForm" id='questionForm' onSubmit={handleSubmit(onSubmit)}>
        <label>문의유형 <span>*</span></label>
        <div style={{ width:'100%',maxWidth: "380px", minWidth:"324px", height: "46px",marginBottom:'30px' }}>
          <Controller
            control={control}
            name="question_category"
            rules={{ required: true }}
            valueAsNumber={true}
            defaultValue={question_category} // onSubmit의 data.question_category 의 default value
            render={() => (
              <Select
                placeholder="문의 유형을 선택해주세요"
                onChange={(param) => { setQuestion_category(param.value);resetField("question_category");setValue("question_category", param.value) }}
                options={questionategoryOptions}
                defaultValue={questionategoryOptions.find(option => option.value === question_category)}
              />
            )}
          />
        </div>
        {errors.question_category?.type === 'required' && <p>문의 유형을 선택해주세요</p>}

        <label>제목 <span>*</span></label>
        <input type="text" placeholder="내용을 입력해주세요.(50자 이내)" {...register("question_title", { required: true, maxLength: 100 })}></input>
        {errors.question_title?.type === 'required' && <p>문의제목을 입력해주세요.</p>}
        {errors.question_title?.type === "maxLength" && <p>50자 이내로 입력해주세요.</p>}

        <label>문의내용 <span>*</span></label>
        <textarea placeholder="내용을 입력해주세요.(2500자 이내)" {...register("question_board", { required: true, maxLength: 5000 })} />
        {errors.question_board?.type === 'required' && <p>문의내용을 입력해주세요.</p>}
        {errors.question_board?.type === "maxLength" && <p>2500자 이내로 입력해주세요.</p>}

        <label>첨부파일</label>
        <div className="uploadFile">
          <input type="file"  id="file" accept="image/*, .zip, .pdf" value={undefined} {...register("question_file", {
            validate: { lessThan30MB: (files) => (files[0]?.size < 30000000 || files.length === 0) },
            onChange:(e)=>{setUploadedFile(e.target.files[0])}
          })} />
          <div>{uploadedFile?.name || <div>선택된 파일이 없습니다.</div>}</div>
          <label className="blackButton" htmlFor="file">첨부파일</label> 
        </div>
        {errors.question_file?.type === 'lessThan30MB' && <p>30MB 이하의 이미지를 업로드해주세요.</p>}

        {uploadedFile && 
          <div className="preview">
            {uploadedFile.type.includes('image') && <img src={URL.createObjectURL(uploadedFile)} alt="enrolled file" />}
          </div>
        }
        <div className="submit">
          <button className="blueButton" form='questionForm' type="submit">제출하기</button>
        </div>
        <div className="explain">*내용을 남겨주시면, 담당 직원이 확인 후 답변을 남겨드리겠습니다.</div>
      </form>
    </Div>
  )
}


const Div = styled.div`
  margin-bottom: 184px;
  span{
    color:#EB5757;
  }

  form{
    text-align: left;
    > label{
      display: block;
      margin-bottom: 10px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
    }

    >p{
      margin:-25px 0 5px;
      color:#EB5757;
      height:20px;
      font-size:14px;
    }
    select, input, textarea{
      max-width: 380px;
      min-width:324px;
      width:100%;
      height: 46px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      padding: 0 16px;
      margin-bottom: 30px;
      box-sizing: border-box;
      font-family: Pretendard;
      font-size: 14px;
      color: #303033;
    }
    textarea{
      height: 200px;
      resize: none;
      padding: 15px 16px;
    }

    input::placeholder, textarea::placeholder{
      font-size: 14px;
      color: #afafaf;
    }

    .submit{
      .blueButton{
        max-width: 380px;
        width:100%;
        min-width:324px;
        height: 48px;
        margin:8px 0 12px;
      }
    }
    .explain{
      font-family: Pretendard;
      font-size: 12px;
      color: #999;
    }

    input[type="file"]{
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      overflow: hidden;
      border: 0;
    }

    .uploadFile{
      display: flex;
      margin-bottom:30px;
      > div{
        width: 282px;
        height: 46px;
        border-radius: 4px;
        border: solid 1px #dcdcdc;
        box-sizing:border-box;
        font-family: Pretendard;
        font-size: 14px;
        color: #303033;
        display: flex;
        align-items:center;
        padding: 0 16px;
        > div{
          font-family: Pretendard;
          font-size: 14px;
          color: #afafaf;
        }
      }
      >label{
        width: 92px;
        height: 46px;
        margin-left:6px;
        font-size: 14px;
      }
    }
    .preview{
      img{
        max-width:380px;
        width:100%;
        min-width:324px;
      }
    }
  }

  @media (max-width: 720px) {
    margin-bottom: 80px;
  }

`;

