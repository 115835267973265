
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../customHook/useWindowSize';

function Footer() {
  const { innerWidth } = useWindowSize()
  const location = useLocation()
  const shouldDisappear = location.pathname === '/mypage/project/update' || location.pathname === '/mypage/project/add' || location.pathname.includes('/start')
  // upload, login, signup
  return (
    <>
      {shouldDisappear ?
        <></>
        :
        <Div>
          <div className="flexWrapper">
            <div className="flexLeft">
              <div className="links">
                {/* <a href="https://flawless-penguin-a5f.notion.site/6e00a1a05407489ebe293acea3ae9daf" target="_blank" rel="noreferrer">이용약관</a><SeperateBar /> */}
                <a href="https://flawless-penguin-a5f.notion.site/6e00a1a05407489ebe293acea3ae9daf">이용약관</a><SeperateBar />
                <a href="https://flawless-penguin-a5f.notion.site/9f88b664d4f34418b853b17adcbd3f45" target="_blank" rel="noreferrer">개인정보처리방침</a><SeperateBar />
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSefkisAPK0VGS874grvkhQuhQGy46fR9g72-Wr4eySVtk5y4A/viewform" target="_blank" rel="noreferrer">제휴문의</a><SeperateBar />
                <Link to="/QNA">Q&A</Link>
              </div>
              <div className="information">
                상호명: Jabble<span />
                사업장소재지 : 서울특별시 종로구 종로 266 - A동 서울패션허브 창업뜰<span />{(innerWidth <= 1100 && innerWidth > 900) && <br />}
                사업자등록번호 : 839-19-01689{(innerWidth > 1100 || innerWidth <= 900) && <br />}{(innerWidth <= 1100 && innerWidth > 900) && <span />}
                고객센터 : help@jabble.kr<span />
                {/* 이메일 : help@jabble.me */}
              </div>
            </div>
            <div className="flexRight">
              <div className="kakaoChannel">
                <div className="title">카카오톡 문의</div>
                <div className="description">
                  10:00 ~ 19:00 (점심시간 13:00 ~ 14:00)<br />
                  주말, 공휴일 휴무
                </div>
                <div className="blackButton">
                  <a href="https://pf.kakao.com/_NApXxj" target="_blank" rel="noreferrer">문의하기</a>
                </div>
              </div>
            </div>
          </div>
        </Div>
      }
    </>

  );
}

export default Footer;

const Div = styled.div`
  width:100%;
  min-height:280px;
  margin:0 auto;
  color:#222;
  background-color: #000;
  display: flex;
  align-items:center;

  > .flexWrapper{
    display:flex;
    justify-content:space-between;
    max-width:1400px;
    width:100%;
    margin: 0 auto;
    .flexLeft{
      .links{
        height:18px;
        a{
          font-family:Pretendard; 
          font-size: 15px;
          color: #949494;
          letter-spacing: -0.28px;
          font-weight:bold;
        }
      }
      .information{
        margin-top:16px;
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.83;
        letter-spacing: -0.23px;
        color: #949494;
        > span{
          margin:5px;
        }
      }
    }
    .flexRight{
      display: flex;
      justify-content:flex-end;
      .kakaoChannel{
        .title{
          font-family:Pretendard; 
          font-size: 15px;
          font-weight: bold;
          letter-spacing: -0.28px;
          color: #fff;
          margin-bottom:6px;
        }
        .description{
          font-family:Pretendard; 
          font-size: 14px;
          letter-spacing: -0.26px;
          line-height: 1.43;
          color: #b2b2b2;
          margin-bottom:22px;
        }
        .blackButton{
          width: 324px;
          height: 44px;
          background-color: #252525;
          margin: 0 auto;
          > a{
            display: inline-block;
            width:100%;
            height:100%;
            text-align:center;
            line-height: 44px;
            font-family: Pretendard;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: -0.28px;
            color: #fff;
          }
        }
      }
    }

  }


  @media (max-width: 1500px) {
    > .flexWrapper{
      width:90%
    }
  }


  @media (max-width: 900px) {
    > .flexWrapper{
      width:324px;
      min-width:324px;
      height:initial;
      flex-direction:column;
      align-items:center;
      .flexLeft{
        .links{
          margin-top:40px;
          a{
            font-size: 13px;
          }
        }
        .information{
          font-size: 10px;
          line-height: 1.13;
        }
      }
      .flexRight{
        width:100%;
        margin-top:40px;
        .kakaoChannel{
          width:100%;
          padding-bottom: 40px;
          .title{
            font-size: 13px;
          }
          .description{
            font-size: 12px;
          }
        }
      }
    }
  }
  

  /* @media (max-width: 800px) {
    > .flexWrapper{
      display: inherit;
      margin-top:60px;
      .flexRight{
        justify-content:space-around;
        margin-bottom: 60px;
        width:inherit;
        .aboutBusiness{
          display: block;
          margin-right:12px;
        }
      }
      .flexLeft{
        text-align:center;
        width:inherit;
      }
    }
  } */

  /* @media (max-width: 480px) {
    margin:88px auto 0;
    .information{
      font-size:0.8rem;
    }
    .kakaoChannel{
      .description{
        margin:10px 0 10px !important;
      }
      button{
        height:40px ;
        line-height:40px;
      }
    }
  } */
`

const SeperateBar = styled.span`
  &::before {
    content:'|';
    display: inline-block;
    width: 1px;
  }
  vertical-align: bottom;
  font-weight: bold;
  display: inline-block;
  margin:0 15px;
  @media (max-width: 480px) {
    margin:0 10px;
  }
`