import React, { useState, useEffect, useRef } from 'react';
import SearchList from './searchList'
import SearchBarExpanding from './searchBarExpanding'
import { getSearchHistoryInLocalStorage, setSearchHistoryInLocalStorage, deleteSearchHistoryInLocalStorage, deleteAllSearchHistoryInLocalStorage } from '../../localStorage/localStorage';
import SearchResult from './searchResult';
import useSearchProvider from '../../customHook/useSearchProvider';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import SearchIcon from '../../images/search.svg'

export default function SearchBar() {
  const [showSearchRecommendations, setSearchRecommendations] = useState(false);
  let [searchParams] = useSearchParams();
  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '')
  const [searchHistory, changeSearchHistory] = useState(getSearchHistoryInLocalStorage())
  const navigate = useNavigate()

  const { artists, archive, setSearch } = useSearchProvider()

  const modalRef = useRef()
  useEffect(() => {
    // 해당 component mount시 실행
    function handler(event) {
      if (!modalRef.current?.contains(event.target)) {
        setSearchRecommendations(false)
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler) // 해당 component unmount시 실행(clean up). EventListent 제거함.
  }, [])

  useEffect(() => {
    changeSearchHistory(getSearchHistoryInLocalStorage())
  }, [showSearchRecommendations]) // smallSearchInputForNav와 동기화용. showSearchRecommendations에 따라 매번 불러오는게 비효율적일것 같으면 eventListner 고려


  useEffect(() => {
    searchParams.get('keyword') ? setKeyword(searchParams.get('keyword')) : setKeyword('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get('keyword')])

  const setSearchHistory = () => {
    if (keyword === '') { return }
    let searchKeyword = { keyword: `${keyword}`, time: Date.now() }
    const newSearchHistory = setSearchHistoryInLocalStorage(searchKeyword, searchHistory)
    changeSearchHistory(newSearchHistory)
  }

  const deleteSearchHistory = (e, index) => {
    e.stopPropagation();
    const newSearchHistory = deleteSearchHistoryInLocalStorage(index, searchHistory)
    changeSearchHistory(newSearchHistory)
  }

  const deleteAll = (e) => {
    e.stopPropagation();
    deleteAllSearchHistoryInLocalStorage()
    changeSearchHistory([])
  }

  const clickSearchHistory = (e, keyword) => {
    e.stopPropagation();
    navigate(`/search?keyword=${keyword}`)
    setSearchRecommendations(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchHistory()
    navigate(`/search?keyword=${keyword}`)
    setSearchRecommendations(false)
  }

  const handleChange = (e) => {
    setKeyword(e.target.value)
    setSearch(e.target.value)
    setSearchRecommendations(true)
  }


  return (
    <Div ref={modalRef}>
      <form onSubmit={(e) => { handleSubmit(e) }}>
        <label htmlFor="SearchInput">
          <img className="search-icon" alt="search icon" src={SearchIcon} />
          <img className="bar-icon" alt="bar icon" src={require('../../images/bar.png')} />
        </label>
        <input
          id="SearchInput"
          autoComplete="off"
          onClick={() => setSearchRecommendations(!showSearchRecommendations)}
          placeholder="아티스트 또는 콘텐츠를 검색해보세요"
          value={keyword}
          onChange={(e) => handleChange(e)}
        />
      </form>
      {showSearchRecommendations &&
        <div className="SearchRecommendations">
          {keyword ?
            <div className="searchResult">
              <div>
                <p>Artists</p>
                <SearchResult results={artists} type='Artists' />
              </div>
              <div>
                <p>Archive</p>
                <SearchResult results={archive} type='Archive' />
              </div>
            </div>
            :
            <div className="SearchRecommendationsInner">
              <div>
                <div className="SearchHeader" style={{marginBottom:'14px'}}>카테고리</div>
                <SearchList className={"searchBar"}></SearchList>
              </div>
              <div className="recentSearch">
                <div className="SearchTop">
                  <div className="SearchHeader">최근 검색어</div>
                  {Array.isArray(searchHistory) && searchHistory.length > 0 &&
                    <div className="deleteAll" onClick={(e) => deleteAll(e)}>전체삭제</div>
                  }
                </div>
                <SearchBarExpanding
                  searchHistory={searchHistory}
                  deleteSearchHistory={deleteSearchHistory}
                  clickSearchHistory={clickSearchHistory}
                ></SearchBarExpanding>
              </div>
            </div>}
        </div>
      }
    </Div>
  );
}

const Div = styled.div`
  margin:32px auto 0;
  max-width: 860px;
  height: 66px;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  position:relative; // SearchRecommendations
  
  form{
    width:100%;
    height:100%;
    display: flex; // place holder 세로가운데정렬 위해
    border-radius: 4px;
    overflow: hidden;
    
    > label{
      width:87px;
      height:100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left:32px;
      
      .search-icon{
        width:24px;
        height:24x;
      }
      .bar-icon{
        padding-left: 30px;
        width:1px;
        height:28px;
      }
    }
    
    > input{
      border:0px;
      width:100%;
      font-size: 18px;
      font-family: Montserrat;
      font-weight: 500;
      padding-left: 32px;
      box-sizing: border-box;
    }
    > input::placeholder{
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      color: #767676;
    }
  }

  
  .SearchRecommendations{
    width: 100%;
    min-height: 320px;
    position: absolute;
    top: 80px; // input 창 크기만큼 밑으로 내림
    background-color: #FFF;
    box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    z-index: 1;
    padding-bottom:20px;
    padding:28px 32px;
    box-sizing:border-box;
  
    .SearchRecommendationsInner {
      width: 100%;
  
      .recentSearch{
        .SearchTop{
          display:flex;
          justify-content:space-between;
          align-items:center;
          margin: 40px 0px -2px;
        }
      }
      .SearchHeader {
        font-family: Pretendard;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.03px;
        color: #292929;
        text-align:left;
      }
      .deleteAll{
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.02em;
        color: #999;
      }
    }
    .searchResult{
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: center;
    }
    .searchResult > div{
      width: 100%;
      overflow-y: auto;
      p{
        margin:0;
        text-align:left;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.03px;
        color: #292929;
      }
    }
  }

`