import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import styled from "styled-components";
import { useState, useEffect } from 'react';

export default function PageNumber({ total_pageno, selectPageno, setSelectPageno, style }) {

  const [page, setPage] = useState(selectPageno);

  useEffect(()=>{
    setPage(selectPageno)
  },[selectPageno])  

  const handleChange = (e, value) => {
    setPage(value);
    setSelectPageno(value)
  };

  return (
    <Div style={style}>
      <Stack spacing={2}>
        <Pagination count={total_pageno} page={page} shape="rounded" variant="outlined" onChange={handleChange} />
      </Stack>
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  justify-content: center;
  margin:40px auto;
`;