import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';
import { useModal } from '../modal/modal'
import { GMTtoKSTconverter } from '../../converter';
import { useNavigate } from 'react-router-dom';
import playButton from "../../images/playButton.svg"
import UserImage from '../../images/userImage.svg'
import { useWindowSize } from '../../customHook/useWindowSize';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CreativeImages({ brand, name, date, images, redirectUrl, member, style }) {
  const { projectMemberModal, carouselModal } = useModal()
  const { innerWidth } = useWindowSize()
  const navigate = useNavigate()
  const itemCount = innerWidth <= 480 ? 1 : innerWidth > 900 && innerWidth <= 1200 ? 4 : 5 


  let launchingDate = GMTtoKSTconverter(date + `.000Z`)

  const isVideo = (item) => {
    return !item.includes("https://d3azijzmx402dn.cloudfront.net/")
  }

  const settings = {
		className: "center",
		infinite: true,
		slidesToShow: 1,
		arrows: false,
		swipeToSlide: true,
		centerPadding: '0px',
    dots: true,
    appendDots: (dots) => (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: 'dots_custom',
	};


  const creativeImage = (image, i) => { // 900 ~ 1200일때 이미지 4개 rendering, 나머지 5개 rendering
    if (i < itemCount) { // itemCount 갯수만큼만 rendering
      return (
        <div key={uuidv4()} className={`creative${i + 1}`} onClick={()=>{carouselModal(images, i)}}
          style={isVideo(image) ? {backgroundImage: `url(https://img.youtube.com/vi/${image}/maxresdefault.jpg)`, objectFit: "inherit" }:{backgroundImage: `url(${image})` }}
        >
          {isVideo(image) && <div className="playImg"><img src={playButton} alt="play img"/></div>}
        </div>
      )
    }
  }

  const getCreativeImages = (images) => {
    if(itemCount === 1){  // 480이하 일때 이미지 1개 rendering
      return(
        <Slider {...settings}>
        {images.map((image, i) => {
          if(!isVideo(image)){
            return(
              <div className="projectsImages" key={uuidv4()}  onClick={()=>{carouselModal(images, i)}}>
                <img src={image} alt="projectsImages"/>
              </div>
            )
          } else {
            return(
              <>
                <div className="videoThumbnail" key={uuidv4()} onClick={()=>{carouselModal(images, i)}} style={{backgroundImage: `url(https://img.youtube.com/vi/${image}/maxresdefault.jpg)`}}>
                  <div className="playImg"><img src={playButton} alt="play img"/></div>
                </div>
              </>
            )
          }	
        })}
      </Slider>
      )
    }
    else {
      return (
        <div className="creativeList">
        {images.map((image, i) => { return(creativeImage(image, i))})}
        {itemCount === 5 && images.length === 4 && <div className={`creative${5}`} />} 
      </div>
      // 이미지 5개 rendering인데 등록한 이미지가 4개밖에 없을때 회색으로 하나 채워줌.
      )
    } 
  }

  const getParticipatedMember = (member) => {
    if (!member || member.length === 0) { return }
    return (
      <div className="participatedMember" onClick={() => { projectMemberModal(member) }}>
        <div className="text">
          콘텐츠 제작 참여자<span>{member.length}</span>
        </div>
        <div className="memberImageList">
          {member.map((m, i) => {
            return (
              <MemberImageStyled key={uuidv4()} index={member.length - (i)} length={member.length}>
                <img src={m.user_profile || UserImage} alt="member" />
                <div />
              </MemberImageStyled>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Div length={images.length} itemCount={itemCount} width={innerWidth}>
      <div className="imageArea" style={style}>
        <div className="name">{name}</div>
        <div className="brandAndDate">
          <div className="brand">{brand}</div>
          <div className="date">
            {`${launchingDate.getFullYear()}.${launchingDate.getMonth() + 1}.${launchingDate.getDate()}`}
          </div>
        </div>
        {getCreativeImages(images)}
        {getParticipatedMember(member)}
      </div>
      <div className="viewMore" onClick={() => { navigate(redirectUrl) }}>목록으로</div>
    </Div>
  )
}

const MemberImageStyled = styled.div`
  position:relative;
  width: 28px;
  height: 28px;
  margin-left:-8px;
  &:first-child{
    margin-left:0px;
  }
  img{
    width: 28px;
    aspect-ratio:1 / 1;
    border-radius: 50%;
    position:absolute;
    object-fit:cover;
    z-index:${props =>props.index * 2 - props.length * 2 }; // 5개 2 4 6 8 10 -> -8 -6 -4 -2 0
  }
  >div{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color:white;
    position:absolute;
    left:2px;
    z-index:${props =>props.index * 2 - 1 - props.length * 2};
  }
`

const Div = styled.div`

  .imageArea{
    padding-top:35px;
    border-bottom: solid 1px #e2e2e2;
  }
  .viewMore{
    margin-top: 47px;
    margin-bottom: 57px; // 128px(total) - 71px(artistDetailWrapper의 marginBottom)
  }
  .brandAndDate{
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .brand{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    color: #5d5d5d;
    margin-bottom:22px;
  }
  .name{
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    margin-bottom:8px;
  }
  .date{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    color: #aaa;
    margin-bottom:22px;
  }

  .participatedMember{
    height:50px; // 28 -> 50, 반응형 scroll생길때 scroll bar 이미지 밑에 뜨게하기위해
    margin-top:-18px; // participatedMember 가 없을때 creativeList의 padding-bottom이 40px임. 있을때 간격이 34px이라 -6, -12(memberImageList 윗공간) 필요.
    padding-bottom:53px;
    display: flex;
    align-items:center;
    .text{
      min-width: 171px;
      cursor:pointer;
    }
    .text, *{
      font-family: Pretendard;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.05px;

      > span{
        color:#0053ff;
        margin:0 16px 0 5px;
      }
    }
    .memberImageList{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      overflow-y: auto;
    }
  }

  // 1200이상일때 5개 보여짐
  .creativeList{
    padding-bottom:40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap:10px;
    grid-template-areas:
      "creative1 creative1 creative2 creative3"
      "creative1 creative1 creative4 creative5"
    ;
    > div{
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width:100%;
      aspect-ratio:1 / 1;
      max-width:256px;
      cursor:pointer;
    }

    .playImg{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 85px;
        height: 91px;
      }
    }

    .creative1{
      grid-area: creative1;
      grid-column:1/3;
      grid-row:1/3;
      max-width:524px;
      aspect-ratio:1 / 1;
      border-radius: 8px 0 0 8px;
    }
    .creative2{
      grid-area: creative2;
    }
    .creative3{
      grid-area: creative3;
      border-radius: 0 8px 0 0;
    }
    .creative4{
      grid-area: creative4;
      position:relative;
    }
    .creative4:before{
      content:${props => `"+${props.length - 4}"`};
      background-color: rgba(0, 0, 0, 0.4);
      position:absolute;
      width:100%;
      height:100%;
      aspect-ratio:1 / 1;
      display: ${props => props.length > 4 && props.itemCount === 4 ? "flex" : "none"};
      justify-content:center;
      align-items:center;
      font-family: Montserrat;
      font-size: 28px;
      font-weight: 600;
      color: #fff;
    }
    .creative5{
      grid-area: creative5;
      border-radius: 0 0 8px 0;
      background-color: ${props => props.length === 4 && "#ededed"}; // 5개 rendering인데 item 4개 일때 빈 회색
      cursor: ${props => props.length === 4 && "default"};
      position:relative;
    }
    .creative5:before{
      content:${props => `"+${props.length - 5}"`};
      border-radius: 0 0 8px 0;
      background-color: rgba(0, 0, 0, 0.4);
      position:absolute;
      width:100%;
      height:100%;
      aspect-ratio:1 / 1;
      display: ${props => props.length > 5 && props.itemCount === 5 ? "flex" : "none"};
      justify-content:center;
      align-items:center;
      font-family: Montserrat;
      font-size: 28px;
      font-weight: 600;
      color: #fff;
    }
  }

  .slick-list{
    max-height:${props => props.width*0.9+'px'};
    min-height:324px;
    margin: 0 auto;
    border-radius: 8px;
    .slick-slide{ // border-radius:8px; 위해
      display:block;
      .projectsImages{
        border-radius:8px;
        overflow: hidden;
        > img{
          aspect-ratio:1 / 1;
          width:100%;
          cursor:default;
          object-fit:cover;
          margin:0 auto;
        }
      }
      .videoThumbnail{
        background-size: cover;
        background-repeat:no-repeat;
        aspect-ratio: 1 / 1;
        background-position:center;
        border-radius:8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // 900~1200일때 4개 보여짐
  @media (max-width: 1200px) {
    .creativeList{
      max-width: 700px;
      margin: 0 auto;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "creative1 creative2"
        "creative3 creative4"
      ;
      > div{
        max-width:initial;
      }

      .creative1{
        max-width:initial;
        grid-column:1/1;
        border-radius: 8px 0 0 0;
      }
      .creative2{
        border-radius: 0 8px 0 0;
      }
      .creative3{
        border-radius: 0 0 0 8px;
      }
      .creative4{
        border-radius: 0 0 8px 0;
      }
    }
  }

  @media (max-width: 1100px) {
    .brandAndDate{
      justify-content: flex-start;
    }
    .brand{
      font-size: 13px;
      display: flex;
      align-items: center;
      // .부분 가운데로
    }
    .brand:after{
      content:'';
      height:3px;
      width:3px;
      background-color: #d8d8d8;
      display: inline-block;
      border-radius:50%;
      margin:0 6px;
    }
    .name{
      font-size: 22px;
    }
    .date{
      font-size: 13px;
    }
  }
  
  
  // 900~480일때 4개 보여짐
  @media (max-width: 900px) {
    .imageArea{
      padding-top:0px;
    }
    .creativeList{
      max-width: initial;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
      "creative1 creative1 creative2 creative3"
      "creative1 creative1 creative4 creative5"
    ;
      > div{
        max-width:256px;
      }

      .creative1{
        max-width:initial;
        grid-column:1/3;
        grid-row:1/3;
        max-width:524px;
        border-radius: 8px 0 0 8px;
      }
      .creative2{
        border-radius: 0;
      }
      .creative3{
        border-radius: 0 8px 0 0;
      }
      .creative4{
        border-radius: 0;
      }
    }
  }

  @media (max-width: 720px) {
    .participatedMember{
      padding-bottom:30px;
    }
    .creativeList{
      grid-gap:5px;
    }
  }

  @media (max-width: 480px) {
    .imageArea{
      border:0;
    }
    .participatedMember{
      margin-top: 42px;
      border-top: solid 1px #ededed;
      padding-top: 8px;
      padding-bottom:0px;
    }
  }
`
