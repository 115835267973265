import styled from 'styled-components';
import { useState } from 'react';
import useAPI from '../../api/useAPI';
import { useAuth } from '../../auth/auth';
import { useModal } from './modal';
import { useWindowSize } from '../../customHook/useWindowSize';
import { useLocation, useNavigate } from "react-router-dom";

export default function DeleteUserModal() {
  const labelTexts = ['서비스가 별로여서', ' 자주 사용하지 않아서', '내 커리어에 도움이 되지 않아서', '다른 서비스가 편해서', '기타']
  const [checked, setChecked] = useState([false, false, false, false, false])
  const [taxtValue, setTextValue] = useState("")
  const { deleteAPI } = useAPI()
  const { user, logout } = useAuth()
  const { modalShow, modalClose, deleteUserModal } = useModal()
  const location = useLocation();
  const navigate = useNavigate()
  const { innerWidth, innerHeight } = useWindowSize()

  let handleOnChange = (index) => {
    const updatedCheckedState = checked.map((checked, i) => i === index ? !checked : checked)
    setChecked(updatedCheckedState);
  }

  let onDelete = () => {
    let reason = []
    const result = checked.filter((checked, index) => checked && reason.push(labelTexts[index]));
    if (result.length === 0) {
      modalShow({ message: '탈퇴 사유를 선택해주세요.', actionButton: <div className="blackButton" onClick={() => deleteUserModal()}>다시 시도하기</div> })
      return
    }

    if (checked[checked.length - 1]) { // 기타가 체크된 경우  
      if (taxtValue === "") {
        modalShow({ message: '기타 내용을 입력해주세요.', actionButton: <div className="blackButton" onClick={() => deleteUserModal()}>다시 시도하기</div> })
        return
      }
      reason.pop()
      reason.push(taxtValue)
    }

    modalShow({ 
      message:"정말 탈퇴하시겠습니까?",
      submessage: "회원탈퇴 시 사용자 계정의 모든 정보가 삭제됩니다.", 
      actionButton:
      <div style={{display:'flex'}}>
        <div className="whiteButton" style={{width:"164px", marginRight:'10px'}} onClick={() => modalClose()}>취소</div>
        <div className="blackButton" style={{width:"164px"}} onClick={() => deleteMember(reason)}>회원탈퇴</div>
      </div> 
   })
  }

  let deleteMember = async (reason) => {
    let url = `/user?user_withdraw=${JSON.stringify(reason)}&token=${user.access_token}`
    let { result } = await deleteAPI(url, user)
    if (result === 'success') {
      modalClose()
      logout()
      navigate(location.pathname, {
        state: { errorStatusCode: 'delete' }
      });
    }
  }

  return (
    <Div width={innerWidth} height={innerHeight}>
      <div className="message">회원탈퇴 하기</div>
      <div className="submessage">JABBLE 서비스를 정말 탈퇴하시나요 ? <br />(탈퇴 사유를 알려주세요)</div>
      <div className="checkbox round">
        {labelTexts.map((value, index) => (
          <p key={value}>
            <input
              id={value}
              type="checkbox"
              onChange={() => handleOnChange(index)}
            />
            <label htmlFor={value}>{value}</label>
          </p>
        ))}
      </div>
      <textarea placeholder="내용을 입력해주세요.(100자 이내)" value={taxtValue} onChange={(e) => { setTextValue(e.target.value) }} />
      <div className="blackButton" onClick={() => { onDelete() }}>회원탈퇴</div>
    </Div>
  )
}

const Div = styled.div`
  max-width:480px;
  width: calc(${props => props.width}px * 0.9);
  height: calc(${props => props.height}px * 0.9);
  max-height:552px;
  overflow: auto;
  background-color: #fff;
  border-radius: 12px;
  box-sizing:border-box;
  padding:54px 50px 27px;
  .submessage{
    margin-bottom:20px;
  }
  textarea{
    width: 100%;
    height: 120px;
    resize: none;
    padding:14px;
    box-sizing: border-box;
    margin-bottom:18px;
    margin-top:12px;
    border-radius: 4px;
    border: solid 1px #e4e4e4;
  }
  textarea::placeholder{
    font-family: Pretendard;
    font-size: 14px;
    color: #b4b4b4;
  }

  @media (max-width: 420px) {
    width: calc(${props => props.width}px * 1);
    height: calc(${props => props.height}px * 1);
    border-radius:0;
    padding:0 0 20px;
    .message{
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
      padding:18px 0;
      border-bottom:1px solid #f7f7f7;
      font-size: 17px;
    }
    .submessage{
      font-size: 14px;
      text-align: left;
      margin:24px 18px 32px;
    }

    .checkbox{
      margin-left:18px;
      margin-right:18px;
    }
    textarea{
      margin-left:18px;
      margin-right:18px;
      width:calc(100% - 36px);
    }
  }
`