import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useModal } from '../../../components/modal/modal'
import ProgressBar from './progressBar'
import { useNavigate } from 'react-router-dom'

export default function TOS({ setOptionalAgreeChecked }) {
  const [checked, setChecked] = useState([false, false, false, false])
  const [checkedAll, setCheckedAll] = useState(false)
  const { modalShow, modalClose } = useModal()
  const [isActiveNextButton, setActiveNextButton] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    let isOptionalAgreeChecked = checked[3]
    return setOptionalAgreeChecked(isOptionalAgreeChecked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (checked[0] && checked[1] && checked[2]) setActiveNextButton(true)
    else setActiveNextButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  let labelTexts = [
    "[필수] 만 14세 이상입니다",
    <>[필수] 이용약관 동의<a href='https://flawless-penguin-a5f.notion.site/6e00a1a05407489ebe293acea3ae9daf' target="_blank" rel="noreferrer">[보기]</a></>,
    <>[필수] 개인정보 수집 및 이용 동의<a href='https://flawless-penguin-a5f.notion.site/96ebde3dc898435aaf119856069a82fe' target="_blank" rel="noreferrer">[보기]</a></>,
    "[선택] 광고성 정보 수신 및 마케팅 활용 동의"]

  let handleOnChange = (index) => {
    const updatedCheckedState = checked.map((checked, i) => i === index ? !checked : checked)
    setChecked(updatedCheckedState);
  }

  let handleAllChange = () => {
    setChecked(new Array(labelTexts.length).fill(!checkedAll))
    setCheckedAll(!checkedAll)
  }

  let nextPhase = () => {
    isActiveNextButton ? navigate('/start/signup/type',{ state: { from: '/' } }) : modalShow({ message: '필수 항목에 동의해주세요.', actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> });
  }

  return (
    <Div>
      <div className="header">회원가입</div>
      <ProgressBar phase={1} />
      <div className="subHeader">Jabble 서비스 이용약관에<br />동의해주세요</div>
      <div className="checkbox">
        <div className="checkAll">
          <p>
            <input
              id="checkAll"
              type="checkbox"
              onChange={() => handleAllChange()}
            />
            <label htmlFor="checkAll">모두 동의(선택 정보 포함)</label>
          </p>
        </div>
        <div className="underLine" />
        <div>
          {labelTexts.map((value, index) => (
            <p key={index}>
              <input
                id={index}
                type="checkbox"
                checked={checked[index]}
                onChange={() => handleOnChange(index)}
              />
              <label htmlFor={index}>{value}</label>
            </p>
          ))}
        </div>
      </div>
      {!isActiveNextButton ?
        <div className="blackButton unActiveButton">다음으로</div>
        :
        <div className="blackButton" onClick={() => { nextPhase() }}>다음으로</div>
      }
    </Div>
  )
}


const Div = styled.div`
  width:360px;
  padding-bottom:121px;
  .checkbox{
    p{
      margin:20px 0;
    }
    .underLine{
      width: 100%;
      height: 1px;
      background-color: #f4f4f4;
      margin: 25px 0;
      box-sizing:border-box;
    }
    .checkAll{
      label{
        font-weight: bold !important;
      }
    }
    
    a{ // 보기 버튼
      display: inline-block;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.22px;
      color: #292929;
      margin-left:4px;
    }
  }
  .blackButton{
    margin-top:42px;
  }

  @media (max-width: 720px) {
    width:324px;
    margin:0 auto;
  }
`