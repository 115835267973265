import { useState } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import DaumPostcode from 'react-daum-postcode';
import Radio from "../radio/radio";
import { useModal } from '../modal/modal';
import { useAuth } from "../../auth/auth";
import { usePopup } from "./popup";
import useAPI from "../../api/useAPI";
import Select from "../select/select";
import { archiveCategory } from '../../listsAndObjects';
import { stringToSnakeCase } from '../../converter';
import DatePicker from "react-multi-date-picker";
import calenderIcon from '../../images/calenderIcon.svg';
import { useWindowSize } from "../../customHook/useWindowSize";
import { Link } from "react-router-dom";
import { dateRegExp } from "../regex";


export default function MatchingPopUp({ artist }) {
  const { register, handleSubmit, formState: { errors }, control, getValues, setValue, resetField } = useForm();
  const { modalShow, modalClose, addressModal, modalCloseLockScroll } = useModal()
  const [matching_address, setMatching_address] = useState(undefined);
  const { user } = useAuth()
  const { PopupClose } = usePopup()
  const { postAPI } = useAPI()

  const { innerWidth, innerHeight } = useWindowSize()

  const archiveCategoryOptions = []
  const archiveKeyList = Object.keys(archiveCategory)

  for (let i = 0; i < archiveKeyList.length; i++) {
    archiveCategoryOptions.push({ value: stringToSnakeCase(archiveKeyList[i]), label: archiveKeyList[i] })
  }

  const selectAddress = (data) => {
    setMatching_address(data.address)
    resetField("matching_address")
    modalCloseLockScroll();
  }

  let handleRadioChange = (id, value) => {
    resetField(id)
    setValue(id, value)
  }

  const onSubmit = async (data) => {
    data.matching_scope === "글로벌" ? data.matching_scope = true : data.matching_scope = false
    // required value false 불가능해 label로 사용
    data.artists_idx = artist
    data.matching_process = 1
    data.matching_type = JSON.stringify(data.matching_type)

    let url = `/client/matching`
    let { result } = await postAPI(url, user, data)
    if (result === "success") {
      PopupClose()
      modalShow({ message: <>견적 신청이 완료되었습니다. <br />신청 내역 검토 후 연락 드리겠습니다</>, actionButton: <Link to='/mypage/matching' onClick={() => { modalClose(); }} className="blackButton">매칭 상태 보기</Link> })
    }
    PopupClose()
  }

  return (
    <Div innerWidth={innerWidth} innerHeight={innerHeight}>
      <div className="matching">
        <div>
          <div className="header">
            📝 세부내용 작성
          </div>
        </div>

        <form id='matchingForm' onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>캠페인 카테고리 <span>*</span></label>
            <div style={{ height: "44px" }}>
              <Controller
                control={control}
                name="matching_category"
                rules={{ required: true, maxLength: 60 }}
                valueAsNumber={true}
                render={(field) => ( // field 필요한가?
                  <Select
                    {...field}
                    onChange={(param) => { resetField('matching_category'); setValue("matching_category", param.value) }}
                    options={archiveCategoryOptions}
                    placeholder="카테고리"
                  />
                )}
              />
              {errors.matching_category?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px' }}>캠페인 카테고리를 선택해주세요.</span>}
              {errors.matching_category?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px' }}>영문60자, 한글30자 이내로 작성해주세요.</span>}
            </div>
          </div>

          <div>
            <label>캠페인 명  <span>*</span></label>
            <input type="text" placeholder="캠페인 명을 입력해주세요." {...register("matching_name", { required: true, maxLength: 60 })} />
            {errors.matching_name?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px' }}>캠페인 명을 입력해주세요.</span>}
            {errors.matching_name?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px' }}>영문60자, 한글30자 이내로 작성해주세요.</span>}
          </div>

          <div>
            <label>촬영 예상 일자 <span>*</span></label>
            <div className="inputWithDatePicker">
              <Controller
                control={control}
                name="matching_start"
                rules={{ required: true, validate: () => {return dateRegExp.test(document.getElementById('matching_start').value)} }}
                value={getValues("matching_start")}
                render={({ field }) => (
                  <DatePicker
                    id="matching_start"
                    inputClass="datePicker"
                    placeholder="YYYY/MM/DD"
                    format={"YYYY/MM/DD"}
                    value={getValues("matching_start")}
                    onChange={(date) => { field.onChange(new Date(date).toISOString()) }}
                    // https://thewebdev.info/2021/12/05/how-to-use-react-datepicker-with-react-hooks-forms/
                    selected={field.value}
                  />
                )}
              />

              <div className="chooseDate">
                <label htmlFor="matching_start">
                  <img src={calenderIcon} alt="calender icon" />
                </label>
              </div>
            </div>
            {errors.matching_start?.type === 'required' && <div style={{ color: "#EB5757", fontSize:'12px' }}><span>날짜를 선택해주세요.</span></div>}
          </div>

          <div>
            <label>촬영 예상 시간<span>*</span></label>
            <input type="text" placeholder="촬영 예상 시간을 입력해주세요.(숫자)" {...register("matching_hour", { required: true, valueAsNumber: true, validate: (matching_hour) => { return Number.isInteger(matching_hour) } })} />
            {errors.matching_hour?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px' }}>촬영 예상 시간을 입력해주세요.</span>}
            {errors.matching_hour?.type === 'validate' && <span style={{ color: "#EB5757", fontSize:'12px' }}>숫자만 입력해주세요. {"ex)2시간 30분 -> 2.5"}</span>}
          </div>

          <div className="address">
            <label>촬영 장소<span>*</span></label>
            <div className="flexWrapper">
              <input type="text" placeholder="기본주소" disabled={true} onChange={setValue("matching_address", matching_address)} {...register("matching_address", { required: true })} />
              <div onClick={() => { addressModal(<DaumPostcode onComplete={selectAddress} />) }}>검색</div>
            </div>
            {errors.matching_address?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px' }}>촬영 장소를 입력해주세요.</span>}
          </div>

          <div>
            <label style={{ marginTop: "8px" }}></label>
            <input type="text" placeholder="상세주소" {...register("matching_address_detail", { maxLength: 100 })} />
            {errors.matching_address_detail?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px' }}>영문100자, 한글50자 이내로 작성해주세요.</span>}
          </div>

          <div>
            <label>홈페이지 혹은 SNS 주소 <span>*</span></label>
            <input type="text" placeholder="홈페이지 혹은 SNS 주소를 입력해주세요." {...register("matching_homepage", { required: true, maxLength: 100 })} />
            {errors.matching_homepage?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px' }}>홈페이지 혹은 SNS 주소를 입력해주세요.</span>}
            {errors.matching_homepage?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px' }}>영문100자, 한글50자 이내로 작성해주세요.</span>}
          </div>

          <div>
            <p>캠페인 사용국가 <span>*</span></p>
            <Controller
              control={control}
              name="matching_scope"
              rules={{ required: true }}
              render={() => (
                <Radio
                  id="matching_scope"
                  labelTexts={["글로벌", "대한민국"]}
                  values={["글로벌", "대한민국"]}
                  onChange={handleRadioChange}
                />
              )}
            />
            {errors.matching_scope?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px' }}>캠페인 사용국가를 선택해주세요.</span>}
          </div>

          <div>
            <p>저작물 사용기간 <span>*</span></p>
            <Controller
              control={control}
              name="matching_range"
              rules={{ required: true }}
              valueAsNumber={true}
              render={() => (
                <Radio
                  id="matching_range"
                  labelTexts={["3개월", "6개월", "12개월"]}
                  values={[3, 6, 12]}
                  onChange={handleRadioChange}
                />
              )}
            />
            {errors.matching_range?.type === 'required' && <span style={{ color: "#EB5757", fontSize:'12px' }}>저작물 사용기간을 선택해주세요.</span>}
          </div>

          <div>
            <p>저작물 매체 사용범위<span>*</span></p>
            <div className="checkbox round">
              {["자사몰", "SNS", "온라인 입점몰"].map((value) => (
                <p key={value}>
                  <input
                    id={value}
                    type="checkbox"
                    value={value}
                    {...register("matching_type", {
                      validate: matching_type => Array.isArray(matching_type) && matching_type.length !== 0
                    })}
                  />
                  <label htmlFor={value}>{value}</label>
                </p>
              ))}
            </div>
            {errors.matching_type?.type === 'validate' && <span style={{ color: "#EB5757", fontSize:'12px' }}>매체 사용범위를 선택해주세요.</span>}

          </div>

          <div>
            <p>SNS 스폰서 광고 사용여부 </p>
            <Controller
              control={control}
              name="matching_sponser"
              defaultValue={false}
              render={() => (
                <Radio
                  id="matching_sponser"
                  labelTexts={["예", "아니요"]}
                  values={[true, false]}
                  onChange={handleRadioChange}
                />
              )}
            />
          </div>

          <div>
            <p>영상 촬영여부 </p>
            <Controller
              control={control}
              name="matching_filming"
              defaultValue={false}
              render={() => (
                <Radio
                  id="matching_filming"
                  labelTexts={["예", "아니요"]}
                  values={[true, false]}
                  onChange={handleRadioChange}
                />
              )}
            />
          </div>

          <div>
            <p>홈쇼핑 오픈마켓 및 배너 사용여부 </p>
            <Controller
              control={control}
              name="matching_banner"
              defaultValue={false}
              render={() => (
                <Radio
                  id="matching_banner"
                  labelTexts={["예", "아니요"]}
                  values={[true, false]}
                  onChange={handleRadioChange}
                />
              )}
            />
          </div>

          <div>
            <p>매장 POP 또는 와이드 사용여부 </p>
            <Controller
              control={control}
              name="matching_pop"
              defaultValue={false}
              render={() => (
                <Radio
                  id="matching_pop"
                  labelTexts={["예", "아니요"]}
                  values={[true, false]}
                  onChange={handleRadioChange}
                />
              )}
            />
          </div>

          <div>
            <p>인쇄룩북, 고객용 포스트카드 제작 사용 여부 </p>
            <Controller
              control={control}
              name="matching_card"
              defaultValue={false}
              render={() => (
                <Radio
                  id="matching_card"
                  labelTexts={["예", "아니요"]}
                  values={[true, false]}
                  onChange={handleRadioChange}
                />
              )}
            />
          </div>

          <div>
            <p>옥외광고 사용 여부</p>
            <Controller
              control={control}
              name="matching_outdoor"
              defaultValue={false}
              render={() => (
                <Radio
                  id="matching_outdoor"
                  labelTexts={["예", "아니요"]}
                  values={[true, false]}
                  onChange={handleRadioChange}
                />
              )}
            />
          </div>


          <div>
            <p>특이사항</p>
            <input type="text" placeholder="특이사항을 입력해주세요." {...register("matching_detail", { maxLength: 400 })} />
            {errors.matching_detail?.type === 'maxLength' && <span style={{ color: "#EB5757", fontSize:'12px' }}>영문400자, 한글20자 이내로 작성해주세요.</span>}
          </div>
        </form>
        <div className="buttons">
          <button className="whiteButton" onClick={() => { PopupClose() }}>취소</button>
          <button className="blackButton" form='matchingForm' type="submit">문의하기</button>
        </div>
      </div>
    </Div>

  )
}

const Div = styled.div`

  background-color: white;    
  max-width:580px;
  width: calc(${props => props.innerWidth}px * 0.9);
  max-height:900px;
  height: calc(${props => props.innerHeight}px * 0.9);
  overflow:auto;
  border-radius: 12px;
  margin: 0 auto;

  .checkbox{
    [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label{
      padding-left: 28px;
      margin-right:11px !important;
    }
    [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
      border-radius:50%;
      border: solid 1px #ddd;
    }
    [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
      border-radius: 50%;
    }
  }

  .matching{
    margin:0 auto;
    width:69%; // 400/580
    .header{
      font-family: Pretendard;
      font-size: 32px;
      font-weight: bold;
      padding-bottom:16px;
      margin-top:54px;
    }

    form{
      width: 100%;
      margin:28px 0 24px;
      select, input{
        border: solid 1px #e4e4e4;
        width:100%;
        height:44px;
        box-sizing:border-box;
        padding-left:16px;
        border-radius: 4px;
        font-family: Pretendard;
        font-size: 14px;
        letter-spacing: -0.32px;
        color: #353535;
      }

      input::placeholder{
        font-family: Pretendard;
        font-size: 14px;
        color: #b4b4b4;
      }
      > div{
        > label, > p{
          display: block;
          margin: 28px 0 10px;
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 600;
          color: #1f1f1f;
        }
        > p{
          margin: 34px 0 10px;
        }
      }
 

      label > span{
        color:#EB5757; // 강조 표시
      }
      p > span{
        color:#EB5757; // 강조 표시
      }

      >div > span{
        color:#EB5757; // 경고 메세지
      }
      // -> Date picker에서 색 안변하게 하기위해

      .inputWithDatePicker{
        display: flex;
        justify-content: space-between;
        position: relative;
        .rmdp-container {
          width:87%
        }
        .datePicker{
          max-width:380px;
          width:100%
        }
 
        .chooseDate{
          display: flex;
          background-color: #303033;
          border-radius: 4px;
          width: 44px;
          margin-left:8px;
          display: flex;
          justify-content:center;
          align-items:center;
          >label{
            margin:0;
          }

          img{
            /* width:18px;
            height:20px; */
          }
        }
      }

      .address{
        .flexWrapper{
          display: flex;
          justify-content: space-between;
          flex-direction:row;
          input{
            width:79%;
          }
          div{ // 검색 버튼
            width:18%;
            display: flex;
            justify-content:center;
            align-items:center;
            background-color: #303033;
            border-radius: 4px;
            cursor: pointer;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.32px;
            color: #fff;
          }
        }
      }
    }
   
    .buttons{
      margin-bottom: 24px;
      display: flex;
      justify-content:center;
      .whiteButton{
        width:120px;
        height: 40px;
        margin:0 10px;
      }
      .blackButton{
        width:120px;
        height: 40px;
        margin:0 10px;
      }
    }

    .checkbox, .radio{
      p{
        margin:4px 0 0;
      }
      label{
        margin-right: 14px;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.32px;
        color: #353535;
      }
    }
    .checkbox{
      display:flex;
      [type="checkbox"]:not(:checked) + label:before {
        width: 18px !important;
        height: 18px !important;
      }
      [type="checkbox"]:checked + label:after,
      [type="checkbox"]:not(:checked) + label:after {
        top: 4px;
        left: 4px;
      }
      [type="checkbox"]:not(:checked) + label{
        padding-left:28px;
        line-height:20px;
      }
    }
  }

  
  @media (max-width: 1070px) {
    .lower{
      grid-template-columns:1fr 1fr !important;
    }
  }

  @media (max-width: 720px) {
    .upper{
      grid-template-columns:1fr !important;
    }
  }

  @media (max-width: 570px) {
    .lower{
      grid-template-columns:1fr !important;

    }
  }

  @media (max-width: 420px) {
    width: calc(${props => props.innerWidth}px);
    height: calc(${props => props.innerHeight}px);
    border-radius:0;
    .matching{
      width:100%;
      padding:0 18px;
      box-sizing:border-box;
    }
  }
`