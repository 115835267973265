
import styled from "styled-components"
import { useNavigate } from 'react-router-dom';
import { inspirationCategory } from '../../listsAndObjects';


export default function InspirationItem({ InspirationData, className }) {
  const navigate = useNavigate()

  return (
    <Div onClick={() => { navigate(`/inspiration/${InspirationData.inspiration_idx}`) }}>
      <div className={className}>
        <div className="InspirationImageWrapper">
          <div className="InspirationImage" style={{ backgroundImage: `url(${InspirationData.inspiration_thumnail})` }}></div>
        </div>
        <div className="category">{inspirationCategory[InspirationData.inspiration_category - 1]}</div>
        <div className="textarea">
          <div className="title">{InspirationData.inspiration_main_title}</div>
          <div className="subTitle">{InspirationData.inspiration_sub_title}</div>
        </div>
      </div>
    </Div>

  );
}

const Div = styled.div`
  text-align:center;
  cursor:pointer;

  .category{
    width:100%;
    text-align:left;
    letter-spacing: -0.04px;
    color: #333;
    text-decoration: underline;
    margin-top:16px;
    font-weight: bold;
  }
  .InspirationImageWrapper{
    border-radius:4px;
    aspect-ratio: 1.618 / 1;
    width: 100%;
    overflow: hidden;
  }

  .InspirationImage {
    width: 100%;
    height:100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition:0.2s;
    overflow:hidden;
  }
  .InspirationImage:hover{
    transform:scale(1.1)
  }

  .title {
    margin:18px 0 12px;
    line-height: 24px;
    text-align:left ;
    font-weight: 600;
    font-family:'Pretendard';
    font-size: 21px;
    display: -webkit-box;
    max-width: 433px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .subTitle {
    text-align:left ;
    line-height: 26px;
    font-size: 15px;
    letter-spacing: -0.32px;
    color: #606063;
    font-family: 'Pretendard';
    display: -webkit-box;
    max-width: 433px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (max-width: 720px) {
    .category{
      font-size: 13px;
    }
    .title {
      margin: 6px 0 8px;
      font-size: 16px;
    }
    .subTitle {
      font-size: 13px;
    }
  }
  @media (max-width: 620px) {
    .inspirationPage{
      .category{
        font-size: 16px;
      }
      .title {
        margin:18px 0 12px;
        font-size: 21px;
      }
      .subTitle {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 480px) {
    .inspirationPage{
      .category{
        font-size: 13px;
      }
      .title {
        margin: 6px 0 8px;
        font-size: 16px;
        font-size: 16px;
      }
      .subTitle {
        font-size: 13px;
      }
    }
  }
`
