
import { useBlock } from "../customHook/useBlock"
import useHeader from "./useHeader"
import { useLocation, useNavigate } from "react-router-dom"

export default function useAPI() {
  const { headers } = useHeader()
  const location = useLocation()
  const navigate = useNavigate()
  const { setBlock } = useBlock()

  const getAPI = async (url, user) => {
    return await fetch("/api"+url, {
      method: "GET",
      credentials: 'same-origin',
      headers: await headers(user),
    }).then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
      .then(res => {
        if (res.status === 200) {
          return { data: res.body.data, result: res.body.result }
        }
        else {
          let { detail } = res.body
          let responseCode = detail.split('_')[0]
          let responseMessage = detail.split('_')[1]
          let result = false

          navigate(location.pathname, {
            state: { errorStatusCode: responseCode }
          });

          return { responseCode, responseMessage, result }
        }
      })
  }

  const postAPI = async (url, user, data) => {
    return await fetch("/api"+url, {
      method: "POST",
      credentials: 'same-origin',
      headers: await headers(user),
      body: JSON.stringify(data)
    })
      .then(async (res) => {
        return { status: res.status, body: await res.json() }
      }
      )
      .then(res => {
        if (res.status === 200) {
          if (url === '/jwt/login') {
            return { data: res.body, result: true }
          } else {
            return { data: res.body.data, result: res.body.result }
          }
        }
        else {
          let { detail } = res.body
          let responseCode = detail.split('_')[0]
          let responseMessage = detail.split('_')[1]
          let result = false
          navigate(location.pathname, {
            state: { errorStatusCode: responseCode }
          });
          return { responseCode, responseMessage, result }
        }
      })
  }

  const putAPI = async (url, user, data) => {
    return await fetch("/api"+url, {
      method: "PUT",
      credentials: 'same-origin',
      headers: await headers(user),
      body: JSON.stringify(data)
    })
      .then(async (res) => {
        return { status: res.status, body: await res.json() }
      })
      .then(res => {
        if (res.status === 200) {
          return { data: res.body.data, result: res.body.result }
        } else {
          let { detail } = res.body
          let responseCode = detail.split('_')[0]
          let responseMessage = detail.split('_')[1]
          let result = false
          navigate(location.pathname, {
            state: { errorStatusCode: responseCode }
          });
          return { responseCode, responseMessage, result }
        }
      })
  }

  const deleteAPI = async (url, user) => {
    return await fetch("/api"+url, {
      method: "DELETE",
      credentials: 'same-origin',
      headers: await headers(user),
    }).then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
      .then(res => {
        if (res.status === 200) {
          return { result: res.body.result }
        } else {
          let { detail } = res.body
          let responseCode = detail.split('_')[0]
          let responseMessage = detail.split('_')[1]
          let result = false
          if (responseCode === '40602'){
            navigate(location.pathname, {
              state: { errorStatusCode: '40605' }
            });
          } else {
            navigate(location.pathname, {
              state: { errorStatusCode: responseCode }
            });
          }
          return { responseCode, responseMessage, result }
        }
      })
  }

  const getQuality = (file) => {
    if(file.size >= 1500000){ // 1.5Mb
      return 1500000/file.size > 0.7 ? 0.7 : 1500000/file.size
      // 압축해도 1.5Mb 넘어가면 압축률 높게, 안넘어가면 0.7로
    } 
    else if(file.size < 300000){ // 300kb
      return 0.7
    } else {
      return 0.5
    }
  }

  const compressImage = async (file, quality) => {
    // Get as image data
    const imageBitmap = await createImageBitmap(file);

    // Draw to canvas
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imageBitmap, 0, 0);

    // Turn into Blob
    setBlock('이미지 압축 중...')
    const blobImage = await new Promise((resolve) =>
      canvas.toBlob(resolve, file.type, quality)
    );
    return new File([blobImage], file.name);
};

  const uploadImageAPI = async (img) => {
    if (typeof (img) === "string") {
      return { result: "success", data: { img_url: img } }
      // paremeter가 img url인 경우 api 호출 없이 return
    }
    const formData = new FormData();
    if(img.type === 'application/pdf'){
      formData.append("img", img);
    } else {
      const compressedFile = await compressImage(img, getQuality(img));
      formData.append("img", compressedFile);
    }
    setBlock('이미지 업로드 중...')
    return await fetch(`/api/function/upload-img`, {
      method: "PUT",
      body: formData,
    }).then(async (res) => {
      return { status: res.status, body: await res.json() }
    })
      .then(res => {
        if (res.status === 200) {
          return { data: res.body.data, result: res.body.result }
        } else {
          let { detail } = res.body
          let responseCode = detail.split('_')[0]
          let responseMessage = detail.split('_')[1]
          let result = false
          navigate(location.pathname, {
            state: { errorStatusCode: responseCode }
          });
          return { responseCode, responseMessage, result }
        }
      })
  }

  const uploadSeveralImageAPI = async (imgs) => {
    let fileObjects = []
    let imageUrls = []
    for (let i = 0; i < imgs.length; i++) {
      if (typeof (imgs[i]) === "object") {
        fileObjects.push(imgs[i])
        imageUrls.push(0)
      } else {
        imageUrls.push(imgs[i])
      }
    }
    if (fileObjects.length === 0) {
      return { result: "success", data: { imageUrls } }
      // paremeter array의 item이 모두 img url인 경우 api 호출 없이 return
    } else {
      const formData = new FormData();
      for (const img of fileObjects) {
        if(img.type === 'application/pdf'){
          formData.append("imgs", img);
        } else {
          const compressedFile = await compressImage(img, getQuality(img));
          formData.append(`imgs`, compressedFile)
        }
      }
      setBlock('이미지 업로드 중...')
      return await fetch(`/api/function/upload-imgs`, {
        method: "PUT",
        body: formData,
      }).then(async (res) => {
        return { status: res.status, body: await res.json() }
      })
        .then(res => {
          if (res.status === 200) {
            let { img_url_list } = res.body.data
            let img_url_list_index = 0;
            for (let i = 0; i < imageUrls.length; i++) {
              if (imageUrls[i] === 0) {
                imageUrls[i] = img_url_list[img_url_list_index]
                img_url_list_index = img_url_list_index + 1
              }
            }
            return { data: { imageUrls }, result: res.body.result }
          } else {
            let { detail } = res.body
            let responseCode = detail.split('_')[0]
            let responseMessage = detail.split('_')[1]
            let result = false
            navigate(location.pathname, {
              state: { errorStatusCode: responseCode }
            });
            return { responseCode, responseMessage, result }
          }
        })
    }
  }

  return { getAPI, postAPI, putAPI, deleteAPI, uploadImageAPI, uploadSeveralImageAPI }

}


