import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function DeleteComplete() {
  // 현재 errorhandler통해서 접근함..
  
  useEffect(()=>{
    document.documentElement.scrollTo({top: 0,left: 0,behavior: "instant"});
  },[])
  
  const navigation = useNavigate()
  return (
    <Div>
      <div className="notFound">🙇‍♂️</div>
      <div className="title">회원탈퇴 완료</div>
      <div className="description">
        JABBLE 서비스를 경험해 주셔서 감사합니다.<br/>
        더 나은 서비스로 찾아뵙겠습니다.
      </div>
      <div className="blackButton" onClick={()=>{navigation('/')}}>메인으로</div>
    </Div>
  )
}

const Div = styled.div`

  max-width:1400px;
  width:100%;
  margin:0 auto 317px;
  text-align:center;

  .notFound {
    width: 240px;
    height: 131px;
    margin: 287px auto 38px;
    font-size: 100px;
    font-weight: 800;
  }
  .title{
    width: 100%;
    height: 26px;
    margin: 38px auto 18px;
    font-size: 22px;
    font-weight: bold;
  }
  .description{
    width: 360px;
    height: 42px;
    margin: 18px auto 34px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.02px;
    color: #8d8d8d;
  }

  .blackButton{
    width: 172px;
    height: 48px;
    margin: 34px auto 0;
  }

  @media (max-width: 1500px) {
    width:90%;
  }

  @media (max-width: 1200px) {
    margin-bottom:222px;
    .notFound {
      margin-top: 222px;
    }
  }

  @media (max-width: 720px) {
    .notFound {
      margin-top: 122px;
    }
  }
`