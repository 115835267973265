import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useModal } from '../../../components/modal/modal';
import { useScrollToTop } from '../../../customHook/userScrollToTop';
import { useNavigate } from 'react-router-dom';
import { useBlock } from '../../../customHook/useBlock';
import useAPI from '../../../api/useAPI';
import DaumPostcode from 'react-daum-postcode';
import folderIcon from '../../../images/folderIcon.svg'
import styled from 'styled-components';
import { passwordRegExp } from '../../../components/regex';


export default function EditInfoForm({ signupType, user, userData, userInfo }) {
  // user: 로그인 시 return 값
  // userData: 정보수정위한 비번 검증 시 return 값
  // userInfo: mypage에서 받아온 유저 정보

  console.log(user,'user') // user.data.user_gender: true


  const { user_phone, user_file, user_address, user_address_detail, user_homepage, user_instagram, user_pw, user_account, user_bank, user_account_name } = userData

  const { register, handleSubmit, formState: { errors }, getValues, setValue, resetField } = useForm();

  const { putAPI } = useAPI()

  const navigate = useNavigate()

  const { addressModal, modalShow, modalClose, deleteUserModal } = useModal()
  const { scrollToTop } = useScrollToTop()
  const { setBlock } = useBlock()
  const [address, setAddress] = useState(user_address);
  const isArtist = signupType === 'artists'

  useEffect(() => {
    scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAddress = (data) => {
    setAddress(data.address)
    resetField("user_address")
    modalClose();
  }

  const onSubmit = async (data) => {
    setBlock(true)

    let url = `/user/mypage`
    let { result } = await putAPI(url, user, data)
    if (result === 'success') {
      modalShow({ message: `정보 수정이 완료되었습니다.`, submessage: 'JABBLE 서비스를 이용해주셔서 감사합니다.', actionButton: <div className="blackButton" style={{ width: "172px", height: "48px" }} onClick={() => { modalClose(); navigate(`/mypage/home`) }}>확인</div> })
    }
    setBlock(false)
  }

  return (
    <Div>

      <form id='editInfoForm' onSubmit={handleSubmit(onSubmit)}>
        <div className="header">프로필 정보</div>
        <div>
          <p>성명</p>
          <div className="userData">{userInfo.user_name}</div>
        </div>
        <div>
          <p>연락처</p>
          <div className="userData">{user_phone}</div>
        </div>
        <div>
          <p>성별</p>
          <div className="userData">{user.data.user_gender ? '남' : '여'}</div>
        </div>
        <div>
          <p>이메일</p>
          <div className="userData">{user.data.user_id}</div>
        </div>

        <div className="widthInput">
          <p>비밀번호 변경 <span>*</span></p>
          <input type="password" autoComplete="off" placeholder="새 비밀번호를 입력해주세요" defaultValue={user_pw} {...register("user_pw", { required: true, pattern: { value: passwordRegExp } })} />
          {errors.user_pw?.type === 'pattern' && <span>영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요.</span>}
          {errors.user_pw?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
        </div>
        <div>
          <p className="forWeb"></p>
          <input style={{ marginTop: "0px" }} type="password" autoComplete="off" placeholder="비밀번호를 재입력 해주세요" defaultValue={user_pw} {...register("user_pw_check", { required: true, validate: (user_pw_check) => { const { user_pw } = getValues(); return user_pw === user_pw_check } })
          } />
          {errors.user_pw_check?.type === 'validate' && <span>비밀번호가 일치하지 않습니다.</span>}
          {errors.user_pw_check?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
        </div>

        <div className="underline"></div>

        <div className="header">{isArtist ? "활동 정보" : "회사 정보"}</div>

        {isArtist &&
          <div>
            <p>전문분야</p>
            <div className="userData">{userInfo.job_detail}</div>
          </div>
        }
        {!isArtist &&
          <div>
            <p>회사(브랜드)명</p>
            <div className="userData">{userInfo.company_name}</div>
          </div>
        }

        {!isArtist &&
          <div>
            <p>비지니스 분야</p>
            <div className="userData">{userInfo.job_detail}</div>
          </div>
        }

        <div className="address">
          <p>주소{isArtist && ` 또는 활동지역`}</p>
          <div className="flexWrapper">
            <input type="text" disabled={true} defaultValue={user_address} onChange={setValue("user_address", address || user_address)} {...register("user_address")} />
            <div className="blackButton" onClick={() => { addressModal(<DaumPostcode onComplete={selectAddress} />) }}>검색</div>
          </div>
        </div>
        <div className="addressDetail">
          <p className="forWeb"></p>
          <input style={{ marginTop: "0px" }} type="text" placeholder="상세주소" defaultValue={user_address_detail} {...register("user_address_detail")} />
        </div>
        <div className="addressDetail">
          <p>웹사이트</p>
          <input type="text" defaultValue={user_homepage} placeholder="ex) www.jabble.com" {...register("user_homepage",
            { validate: (user_homepage) => { return !user_homepage || user_homepage.substring(0, 4) === 'www.'; } })}
          />
          {errors.user_homepage?.type === 'validate' && <span>' www. '으로 시작해야합니다.</span>}
        </div>

        <div className="widthInput">
          <p>인스타그램 계정</p>
          <input type="text" defaultValue={user_instagram} {...register("user_instagram")} />
        </div>
        <div className="uploadFile">
          {isArtist ?
            <p>사업자등록증 <br className="forWeb" />또는 포트폴리오</p> : <p>사업자등록증</p>
          }
          <a href={`${user_file}`} target="_blank" rel="noreferrer" ><img src={folderIcon} alt="icon" />보기</a>
        </div>

        {isArtist && <div className="underline"></div>}
        {isArtist && <div className="header">계좌 정보</div>}
        {isArtist &&
          <div className="widthInput">
            <p>은행명</p>
            <input type="text" defaultValue={user_bank} {...register("user_bank")} />
          </div>
        }
        {isArtist &&
          <div className="widthInput">
            <p>예금주</p>
            <input type="text" defaultValue={user_account_name} {...register("user_account_name")} />
          </div>
        }
        {isArtist &&
          <div className="widthInput">
            <p>계좌번호</p>
            <input type="text" defaultValue={user_account} {...register("user_account")} />
          </div>
        }
        <div>
          <p className="forWeb"></p>
          <button className="blueButton" form='editInfoForm' type="submit">저장하기</button>
        </div>
        <div>
          <p className="forWeb"></p>
          <div className="description forMobile">*별도로 수정사항이 있을 시 고객센터로 문의부탁드립니다.</div>
        </div>
        <div>
          <p className="forWeb"></p>
          <div className="userDelete" onClick={() => { deleteUserModal() }}>회원탈퇴</div>
        </div>
      </form>
    </Div>
  )
}

const Div = styled.div`
  margin: 0 auto 97px; // 114px - 17px(회원탈퇴 부분 밑에 영역 높이)
  text-align: left;

  form{  
    .header{
      margin: 32px 0 10px;
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 800;
      height:inherit;
    }
    > div{
      display: flex;
      align-items:center;

      p{
        width:107px;
        margin:0 24px 0 0;
        font-family: Pretendard;
        font-size: 14px;
        font-weight: bold;
        color: #4d4d4d;
        height:46px;
        line-height:46px;
      }
      .userData{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        color: #303033;
        height:46px;
        line-height:46px;
      }
    }

    .underline{
      margin:32px 0;
      width: 100%;
      height:1px;
      background-color:#ebebeb;
    }
  
    input{
      border: 1px solid #dcdcdc;
      width:380px;
      height:46px;
      box-sizing:border-box;
      border-radius: 4px;
      padding-left:16px;
      margin: 12px 6px 12px 0;
    }

    input::placeholder{
      font-family: Pretendard;
      font-size: 14px;
      color: #afafaf;
    }

    span{
      color:#EB5757;
    }
  
    .address{
      .flexWrapper{
        display: flex;
        align-items: center;
        flex-direction:row;
        input{
          width:282px;
          margin-right:6px !important;
        }
        .blackButton{
          width:92px;
          height:46px;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: -0.22px;
        }
      }
    }
    
    .uploadFile{
      p{
        line-height: 23px;
      }
      & > a{
        display:block;
        overflow-wrap: break-word;
        color: blue;
        text-decoration: underline;
        line-height:46px;
        height:46px;
        border-radius: 4px;
        border: solid 1px #dcdcdc;
        width: 380px;
        margin:12px 0;
        overflow: hidden;
        padding:0 16px;
        box-sizing:border-box;
        img{
          width: 16px;
          height: 15px;
          margin-right:15px;
        }
      }
    }
    .blueButton{
      width: 382px;
      height: 48px;
      margin-top:24px;
    }
    
    .description{
      font-family: Pretendard;
      font-size: 12px;
      color: #999;
    }
    .userDelete{
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      color: #1f1f1f;
      text-decoration: underline;
    }
  }


  @media (max-width: 900px) {
    margin-bottom:72px; // 89px - 17px(회원탈퇴 부분 밑에 영역 높이)
  }

  @media (max-width: 720px) {
    form{
      input{
        max-width:380px;
        min-width: 324px;
        width:100%;
        margin-right: 0;
      }
      .widthInput, .address, .addressDetail, .uploadFile{
        display: block;
        p{
          margin-top:12px;
          height: inherit;
          line-height: inherit;
        }
      }
      .forWeb{
        display:none;
      }
      .uploadFile{
        & > a{
          max-width:380px;
          min-width: 324px;
          width:100%;
        }
        p{
          width:100%;
        }
      }
      .forMobile{
        margin: 10px 0 28px;
      }
      .address{
        .flexWrapper{
          input{
            width:calc(100% - 98px);
            max-width: calc(380px - 98px);
            min-width: calc(324px - 98px);
          }
        }
      }
    }
  }

`