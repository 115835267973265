import { useState, useEffect } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { useForm, Controller } from "react-hook-form";
import { useModal } from '../../../components/modal/modal';
import { useBlock } from '../../../customHook/useBlock';
import Select from '../../../components/select/select';

import { occupationList, businessList, occupationIndex } from '../../../listsAndObjects';
import { getKeyByValue } from '../../../converter';
import { emailRegExp, passwordRegExp } from '../../../components/regex';

import SelectFile from '../../../images/selectFile.svg'
import useAPI from '../../../api/useAPI';

import ProgressBar from './progressBar'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


export default function SignupForm({ signupType, isOptionalAgreeChecked }) {
  const { register, handleSubmit, formState: { errors }, control, getValues, setValue, resetField } = useForm();

  const { modalShow, modalClose, addressModal } = useModal()
  const [confirmedUserEmaill, setConfirmedUserEmaill] = useState(null) // null 이면  이메일 중복 확인 안된것.
  const [address, setAddress] = useState('');
  const [uploadedFile, setUploadedFile] = useState([])

  const [token_version_id, setToken_version_id] = useState('')

  const { getAPI, postAPI, uploadSeveralImageAPI } = useAPI()
  const { setBlock } = useBlock()
  
  const navigate = useNavigate()

  const domain_name = window.location.protocol + "//" + window.location.host;

  useEffect(() => {
    if(!signupType){navigate('/start/signup/type',{ state: { from: '/' } })}
    function receiveMessage(e) {
      if (e.origin !== domain_name || e.data.path !== '/nice-callback') return
      // a.value = e.data.check; // 본인인증 완료시 완료됬다는 check 넘길 수 있음
      if (e.data.check) {
        setToken_version_id(e.data.token)
      } else {
        modalShow({ message: "본인인증에 실패하였습니다.", actionButton: <div className="blackButton" onClick={(e) => { modalClose(); onClickCertify(e) }}>다시 시도하기</div> })
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectAddress = (data) => {
    setAddress(data.address)
    resetField("address")
    modalClose();
  }

  const onClickCertify = async (e) => {
    e.preventDefault();
    let url = `/user/nice?domain_name=${domain_name}`
    const { data } = await getAPI(url)
    const { token_version_id, enc_data, integrity_value } = data
    window.open('', 'popup', "width=600,height=400,left=200,top=200");
    document.niceForm.target = "popup";
    document.niceForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
    document.niceForm.token_version_id.value = token_version_id;
    document.niceForm.enc_data.value = enc_data;
    document.niceForm.integrity_value.value = integrity_value;
    document.niceForm.submit();
  }

  const onSubmit = async (data) => {
    if (!confirmedUserEmaill) {
      modalShow({ message: '이메일 중복확인을 해주세요.', actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    if (!token_version_id) {
      modalShow({ message: '휴대전화 인증을 진행해주세요.', actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    data.user_check = isOptionalAgreeChecked
    data.token_version_id = token_version_id

    const uploadResponse = await uploadSeveralImageAPI(data.img_url) // API 내부에 setBlock 있음
    if (uploadResponse.result === 'success') {
      data.img_url = JSON.stringify(uploadResponse.data.imageUrls)
    } else {
      modalShow({ message: `이미지 업로드에 실패하였습니다. 관리자에게 문의해주세요.`, actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      setBlock(false)
      return
    }
    setBlock(true)

    let url = `/jwt/signup/${signupType}`
    postAPI(url, false, data)
    setBlock(false)
  }

  const checkID = async (e) => {
    e.preventDefault()
    if (confirmedUserEmaill) { return }
    let userId = getValues("user_id")
    if (!userId) {
      modalShow({ message: "이메일을 입력해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    if (userId.length > 60) {
      modalShow({ message: "60자 이내로 입력해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    const regex = new RegExp(emailRegExp);
    if (!regex.test(userId)) {
      modalShow({ message: "올바른 이메일 형식을 입력해주세요.", actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      return
    }
    setBlock('중복확인 중...')
    let url = `/jwt/check-id?user_id=${userId}`
    let { result } = await getAPI(url)
    if (result) {
      modalShow({ message: '사용 가능한 이메일입니다.', submessage: '입력하신 아이디로 사용하시겠습니까?', actionButton: <div className="blackButton" onClick={() => { setConfirmedUserEmaill(getValues("user_id")); modalClose() }}>사용하기</div> })
      setBlock(false)
    } else {
      modalShow({ message: <div style={{ color: '#e94d54' }}>이미 사용중인 이메일 입니다.</div>, submessage: '다른 이메일로 회원가입을 진행해주세요.', actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      setBlock(false)
    }
  }

  const jobOptions = []
  for (let i = 0; i < occupationList.length; i++) {
    jobOptions.push({ value: occupationIndex[occupationList[i]], label: occupationList[i] })
  }

  const businessOptions = []
  const businessValueList = Object.values(businessList)
  for (let i = 0; i < businessValueList.length; i++) {
    businessOptions.push({ value: occupationIndex[businessValueList[i]], label: getKeyByValue(businessList, businessValueList[i]) })
  }

  return (
    <Div>
      <div className="header" style={{ color: "#000" }}>회원가입</div>
      {ProgressBar && <ProgressBar phase={3} />}
      <div className="subHeader">가입하실 회원정보를<br />입력해주세요.</div>
      <form name="niceForm" id="niceForm" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb">
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" value="" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
      <form className="signupForm" id='signupForm' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>휴대폰 본인인증 <span>*</span></label>
        </div>
        <div>
          {!token_version_id ?
            <div className="blackButton niceButton" onClick={(e) => onClickCertify(e)}>휴대전화 인증</div>
            :
            <div className="whiteButton niceButton niceActive" onClick={(e) => { e.preventDefault() }}>인증완료</div>
          }
        </div>
        <div>
          <label>이메일 <span>*</span></label>
          <div className="inputWithButton">
            {confirmedUserEmaill && <input placeholder={confirmedUserEmaill} disabled={true} />}
            {!confirmedUserEmaill && <input type="email" placeholder="이메일을 입력해주세요." {...register("user_id", { required: true, pattern: { value: emailRegExp }, maxLength: 60 })} />}
            <div className={confirmedUserEmaill ? "grayButton" : "blackButton"} onClick={checkID} >중복확인</div>
          </div>
        </div>
        {errors.user_id?.type === 'pattern' && <span>올바른 이메일 형식을 입력해주세요.</span>}
        {errors.user_id?.type === 'maxLength' && <span>60자 이내로 입력해주세요.</span>}
        {errors.user_id?.type === 'required' && <span>이메일을 입력해주세요.</span>}

        <div>
          <label>비밀번호 <span>*</span></label>
          <input type="password" autoComplete="off" placeholder="영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요." {...register("user_pw", { required: true, pattern: { value: passwordRegExp } })} />
        </div>
        {errors.user_pw?.type === 'pattern' && <span>영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요.</span>}
        {errors.user_pw?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
        <div>
          <label>비밀번호 확인 <span>*</span></label>
          <input type="password" autoComplete="off" placeholder="영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요." {...register("user_pw_check", { required: true, validate: (user_pw_check) => { const { user_pw } = getValues(); return user_pw === user_pw_check } })
          } />
        </div>
        {errors.user_pw_check?.type === 'validate' && <span>비밀번호가 일치하지 않습니다.</span>}
        {errors.user_pw_check?.type === 'required' && <span>비밀번호를 입력해주세요.</span>}
        <div className="underLine" />

        {signupType === 'client' &&
          <div>
            <label>회사(브랜드)명 <span>*</span></label>
            <input type="text" placeholder="회사(브랜드)명" {...register("company_name", { required: true })} />
          </div>
        }
        {errors.company_name?.type === 'required' && <span>회사(브랜드)명을 입력해주세요.</span>}

        <div>
          <label>{signupType === 'artists' ? "전문분야" : "비지니스분야"}<span>*</span></label>
          <div style={{ height: '44px' }}>
            <Controller
              control={control}
              name="job"
              rules={{ required: true }}
              valueAsNumber={true}
              render={() => (
                <Select
                  placeholder={`${signupType === 'artists' ? "전문분야" : "비지니스분야"}를 선택해주세요.`}
                  onChange={(param) => { resetField('job'); setValue("job", param.value) }}
                  options={signupType === 'artists' ? jobOptions : businessOptions}
                />
              )}
            />
          </div>
        </div>
        {errors.job?.type === 'required' && <span>{signupType === 'artists' ? "전문분야" : "비지니스분야"}를 선택해주세요.</span>}

        <div>
          <label>주소 {signupType === 'artists' && '또는 활동 지역'} <span>*</span></label>
          <div className="inputWithButton">
            <input type="text" placeholder="기본주소" disabled={true} onChange={setValue("address", address)} {...register("address", { required: true })} />
            <div className="blackButton" onClick={() => { addressModal(<DaumPostcode onComplete={selectAddress} />) }}>주소검색</div>
          </div>
        </div>
        <div>
          <input style={{ marginTop: '10px' }} type="text" placeholder="상세주소" {...register("address_detail")} />
        </div>
        {errors.address?.type === 'required' && <span>주소를 검색해주세요.</span>}


        <div>
          <label>
            {signupType === 'artists' ?
              "포트폴리오 웹사이트"
              :
              "회사(브랜드) 웹사이트"
            }
          </label>
          <input type="text" {...register("homepage")} />
        </div>
        {signupType === 'artists' &&
          <div>
            <label>인스타그램 계정</label>
            <input type="text" {...register("instagram")} />
          </div>
        }
        {signupType === 'client' &&
          <div>
            <label>회사(브랜드) 인스타그램 계정</label>
            <input type="text" {...register("instagram")} />
          </div>
        }

        <div className="uploadFile">
          {signupType === 'artists' ?
            <label>사업자등록증 또는 포트폴리오<span>*</span></label>
            :
            <label>사업자등록증 <span>*</span></label>
          }
          <input type="file" id="uploadFile" multiple accept="image/*, .zip, .pdf" value={undefined} {...register("img_url", {
            required: true, validate: { lessThan30MB: (files) => {
              let size = 0;
              for (let i = 0; i < files.length; i++) {size += files[i].size;}
              if(size > 30000000){return false} else {return true}
            } },
            onChange: (e) => { setUploadedFile(e.target.files) }
          })} />
          <label className="uploadFileLabel" htmlFor='uploadFile'>
            {uploadedFile.length === 0 ? <><img src={SelectFile} alt="file icon" />사업자등록증{signupType === 'artists' ? " 또는 포트폴리오 파일첨부" : " 파일첨부"}</>
            : uploadedFile.length === 1 ? uploadedFile[0].name : `${uploadedFile[0].name}외 ${uploadedFile.length-1}개`
            }
          </label>
        </div>
        {errors.img_url?.type === 'required' && <span>파일 또는 이미지를 업로드해주세요.</span>}
        {errors.img_url?.type === 'lessThan30MB' && <span> 이미지들의 총 합은 30MB를 넘을 수 없습니다.</span>}

        <div className="tip">
          {signupType === 'artists' &&
            "• 사업자 등록증이 있는 경우 사업자 등록증을 업로드해주세요."
          }
          {signupType === 'artists' && <br/>}
          • 파일은 이미지, PDF 또는 Zip 형태로 업로드 할 수 있습니다 (30MB 이하)
        </div>
      </form>
      <button className="blackButton" style={{ marginTop: '64px' }} form='signupForm' type="submit">다음으로</button>
    </Div>
  )
}

const Div = styled.div`
  width: 360px;
  padding-bottom:60px;

  .signupForm{
    .niceButton{
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.22px;
      height:44px;
    }
    .niceActive{
      border: solid 1px #0053ff;
      background-color: #fff;
      color: #0053ff;
      cursor:inherit;
    }

    .inputWithButton{
      display: flex;
      justify-content: space-between;
      input{
        width:260px;
      }
      div{
        width: 92px;
        height: 44px;
      }
      .blackButton{
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.22px;
      }
      .grayButton{
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.22px;
        color: #b1b3b3;
      }
    }

    .underLine{
      width: 100%;
      height: 1px;
      background-color: #dbdbdb;
      margin:40px 0;
    }

    .uploadFile{
      input{
        display: none;
      }
      .uploadFileLabel{
        width: 100%;
        height: 46px;
        border-radius: 4px;
        border: solid 1px #dedee1;
        box-sizing:border-box;
        display: flex;
        align-items:center;
        padding: 0 14px;
        margin:0;
        font-family: Pretendard;
        font-size: 14px;
        color: #b1b3b3;
        letter-spacing: -0.22px;
        font-weight: normal;
        img{
          width: 16px;
          height: 15px;
          margin-right:5px;
        }
      }
    }

    > span{ // 경고 메세지
      color:#EB5757;
      display: block;
      height:20px;
      font-size: 14px;
      margin:2px 0 -22px;
      text-align:left;
    }
    
    .tip{
      width: 100%;
      font-family: Pretendard;
      font-size: 11px;
      font-weight: normal;
      line-height: 1.64;
      letter-spacing: -0.17px;
      color: #0053ff;
      text-align:left;
      margin:20px 0 -12px;
    }
  }

  @media (max-width: 720px) {
    width: 324px;
    margin:0 auto;

    .signupForm{
      .inputWithButton{
        input{
          width:234px;
        }
        div{
          width: 83px;
        }
      }
    }
  }

`
