

import styled from "styled-components";
import { useWindowSize } from '../../customHook/useWindowSize';

export default function FilterModal({isArtist, category, arrange, region}) {

  const { innerWidth, innerHeight } = useWindowSize()

  return (
    <Div width={innerWidth} height={innerHeight} isArtist={isArtist}>
      <div>
        <h2>
          필터 및 정렬
        </h2>
        {isArtist ?
          <>
            <p>아티스트 직업</p>
            <div>
              {category}
            </div>
            <p>지역</p>
            <div>
              {region}
            </div>
            <p>정렬</p>
            <div>
              {arrange}
            </div>
          </>  
          :
          <>
          <p>아카이브 카테고리</p>
          <div>
            {category}
          </div>
          <p>정렬</p>
          <div>
            {arrange}
          </div>
        </>  
        }
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  max-width: 500px;
  max-height:${props => props.isArtist ? 640 : 520}px;
  width:${props => props.width*0.9}px;
  height:${props => props.height*0.9}px;
  border-radius: 12px;
  box-sizing:border-box;
  overflow-y: auto;
  position:relative;

  >div{
    min-height:${props => props.isArtist ? 640 : 520}px;
    >h2{
      margin:0;
      padding:18px 0;
      box-sizing:border-box;
      font-family: Pretendard;
      font-size: 17px;
      font-weight: bold;
      letter-spacing: normal;
      position:sticky;
      top:0;
      height:56px;
      background-color: white;
      z-index:100;
    }
    >p{
      padding: 0 18px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.22px;
      color: #4d4d4d;
      text-align:left;
    }
    >div{
      padding: 0 18px;
      height:48px;
      margin-bottom:40px;
    }
  }

  @media (max-width: 420px) {
    width:${props => props.width}px;
    height:${props => props.height}px;
    max-height:initial;
    border-radius: 0px;
  }
`