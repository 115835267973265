import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react'
import MainLogo from '../../../images/logo.svg'
import ProgressBar from './progressBar'
import styled from 'styled-components';
import { useScrollToTop } from '../../../customHook/userScrollToTop';


export default function AfterSignup({signupType}) { // artists, client
  let navigate = useNavigate()
  const { scrollToTop } = useScrollToTop()

  useEffect(() => {
    scrollToTop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routeChange = () => {
    let path = `/`
    navigate(path)
  }

  return (
    <Div>
      <div>
        <div className="header">회원가입 완료</div>
        <ProgressBar phase={4}/>
        <div className="subHeader"><span>회원가입 요청</span>이<br/> 완료되었습니다.</div>
        <div className="logo"><img src={MainLogo} alt="logo"/></div>
        <div className="text">
          {`${signupType === 'artists' ? "아티스트" : "클라이언트"} 회원 신청과 함께 입력해주신 정보와 서류들의 
            심사를 거쳐 승인 결과를 영업일 기준 3일 이내에 
            문자(SMS)를 통하여 안내해 드립니다.

            가입에 대한 심사가 진행되는 동안에는 
            매칭서비스 이용 제한과 함께 로그인 하실 수 없습니다.
          `}
        </div>
        <div className="blackButton" onClick={() => {routeChange()}}>메인으로</div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  width:100%;
  padding:120px 0;
  >div{
    max-width:640px;
    border-radius: 20px;
    margin: 0 auto;
    background-color: #fff;
    text-align: center;
    padding-bottom:121px;
    >div{
      width:100%;
      margin: 0 auto;
    }
  }
  .header {
    font-family: Pretendard;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    padding-top:90px;
    margin-bottom:32px;
  }

  .subHeader, .subHeader >span{
    font-family: Pretendard;
    font-size: 28px;
    font-weight: normal;
    line-height: 1.21;
    letter-spacing: -0.44px;
    color: #292929;
    margin: 32px auto 54px;
    text-align:center !important;
  }
  .subHeader > span{
    font-weight: bold;
  }

  .logo{
    padding-bottom:10px;
    width: 94px;
    height: 22px;
    margin:0 auto 34px;
  }

  .text{
    width:100%;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: -0.2px;
    text-align: center;
    color: #999;
    margin-bottom:82px;
    white-space: pre-line
  }

  .blackButton{
    width: 100%;
    max-width:360px;
    height: 56px;
    font-weight: bold;
    letter-spacing: -0.25px;
  }

  @media (max-width: 720px) {
    padding:0px;
    width:324px;
    background-color: #fff;
    margin: 0 auto;
    >div{
      max-width:inherit;
      border-radius: 0px;
    }
    .header{
      padding-top: 48px;
      font-size: 30px;
    }
    .text{
      font-size: 13px;
    }
  }

`