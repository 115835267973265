
import styled from "styled-components";
import { useWindowSize } from '../../customHook/useWindowSize';
import UserImage from '../../images/userImage.svg'
import { useModal } from './modal';


export default function MatchingModal({ data, callback }) {

  const { innerWidth, innerHeight } = useWindowSize()
  const { modalClose } = useModal()

  return (
    <Div width={innerWidth} height={innerHeight}>
      <div className="matchingModal">
          <div className="copy">
            <div className="emoji">🙌</div>
            <div className="title">아티스트와<br/>매칭하기</div>
            <div className="description">캠페인 제작/촬영을 위해 당신만의<br/>아티스트와 매칭하세요!</div>
          </div>
          <div className="explain">
            <div className="aboutArtist">
              <div className="artistProfile">
                <img src={data.user_profile || UserImage} alt="profile"/>
                <div>
                  <div className="name">{data.user_name}</div>
                  <div className="job">{data.job_detail}</div>
                </div>
              </div>
              <div className="aboutMe">
                <div className="title">About ME</div>
                <div className="description">{data.user_myself ? data.user_myself : '등록된 자기소개가 없습니다'}</div>
              </div>
            </div>
            <div className="buttons">
              <div className="grayButton" onClick={()=>{modalClose()}}>취소</div>
              <div className="blackButton" onClick={() => { callback(true);modalClose() }} >견적 및 스케줄 문의</div>
            </div>
          </div>
        </div>
    </Div>

  )
}

const Div = styled.div`
  max-width: 926px;
  width: calc(${props => props.width}px * 0.9);
  height: calc(${props => props.height}px * 0.9);
  max-height: 530px;
  background-color: white;
  overflow:scroll;
  border-radius: 12px;
  overflow: hidden;
  text-align:left;

  .matchingModal {
    display: flex;
    height: 100%;

    .copy {
      width:38%;
      display: flex;
      flex-direction:column;
      padding:68px 10px 0 54px;
      box-sizing: border-box;
      .emoji{
        font-size: 36px;
      }
      .title{
        font-family: Pretendard;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.43;
        margin:7px 0 16px;
      }
      .description{
        font-family: Pretendard;
        font-size: 14px;
        line-height: 1.71;
        color: #999;
      }
    }
    
    .explain {
      width:62%;
      background-color: #f7f7f7;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom:24px;
      overflow: auto;
      .aboutArtist{
        margin: 80px auto 32px;
        width: 414px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);
        box-sizing: border-box;
        padding:24px 28px;
        height:100%;
        /* min-height: 318px; */

        .artistProfile{
          display: flex;
          align-items: center;
          padding-bottom:24px;
          border-bottom: solid 1px #eee;
          >img{
            width: 54px;
            height: 54px;
            border-radius:50%;
            object-fit:cover;
            margin-right:12px;
          }
          >div{
            .name{
              font-family: Pretendard;
              font-size: 18px;
              font-weight: bold;
              color: #000;
              margin-bottom:6px;
            }
            .job{
              font-family: Montserrat;
              font-size: 13px;
              font-weight: 500;
              color: #606063;
            }
          }
        }
      }

      .aboutMe{
        .title {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: bold;
          color: #292929;
          margin:24px 0 24px;
        }

        .description {
          font-family: Pretendard;
          font-size: 14px;
          line-height: 1.71;
          color: #5d5d5d;
        }
      }

      .buttons{
        width:414px; // 반응형때 바꾸기
        margin:0 auto;
        display:flex;
        justify-content:space-between;
        .grayButton{
          width:32%;
          background-color: #dadada;
          font-size: 15px;
          font-weight: 600;
          letter-spacing: -0.35px;
          color: #424242;
        }
        .blackButton{
          width:65%;
          height: 48px;
          font-size: 15px;
          margin:0;
          font-size: 15px;
          font-weight: 600;
          letter-spacing: -0.35px;
        }
      }
    }
  }

  @media (max-width: 900px) {
    width:400px;
    height: calc(${props => props.height}px * 0.9);
    max-height: initial;

    .matchingModal {
      display: block;
      overflow: auto;
      .copy {
        width:100%;
        padding:36px 0 24px;
        width: 324px;
        margin:0 auto;
      }
      .explain{
        width:100%;
        padding:28px 0 24px;
        .aboutArtist{
          margin:0 auto;
          width: 324px;
          .aboutMe{
            min-height: 100px;
          }
        }
        .buttons{
          width: 324px;
          margin-top:32px;
        }
      }
    }
  }
  
  @media (max-width: 420px) {
    width: calc(${props => props.width}px * 1);
    height: calc(${props => props.height}px * 1);
    border-radius:0;
  }
`