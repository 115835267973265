

import styled from "styled-components"
import { usePagination } from "../../customHook/usePagination";
import { useBlock } from "../../customHook/useBlock";
import { useEffect, useState } from "react";
import InspirationItem from "../../components/inspiration/inspirationItem";

function InspirationList() {

  const [cursor, setCursor] = useState(0)
  const { inspirationData ,setInspirationData, isInspirationChanged } = usePagination()
  const { setBlock } = useBlock()
  let url = `/user/inspiration`

  useEffect(() => {
    setBlock(true)
    setInspirationData(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!cursor) { return }
    let newUrl = url + `?cursor=${cursor}`
    setBlock(true)
    setInspirationData(newUrl)
    // 이전데이터에 새 데이터 더해서 data return하는 함수
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor])

  const viewMore = (lastItem) => {
    setCursor(lastItem.inspiration_idx)
  }

  return (
    <Div>
      <div className="banner">
        <h1>인스퍼레이션</h1>
        <p>광고 콘텐츠 제작에 있어, 자블만의 칼럼을 통해 영감을 얻어보세요.</p>
      </div>
      <div className="inspirationItemList">
        {inspirationData?.map((InspirationData) => {
          return (<InspirationItem key={InspirationData.inspiration_idx} InspirationData={InspirationData} className="inspirationPage"/>)
        })}
      </div>
      {isInspirationChanged && inspirationData?.length % 9 === 0 && inspirationData?.length >= 9 && <div className="viewMore" onClick={() => {viewMore(inspirationData[inspirationData.length - 1])}}>더보기</div>}
    </Div>
  );
}

export default InspirationList;

const Div = styled.div`
  max-width: 1400px;
  min-width:324px;
  width: 100%;
  margin:0 auto 148px;

  .viewMore{
    margin-top:62px;
  }
  
  .banner{
    border-bottom: solid 1px #ededed;
    padding: 72px 0 42px;
    box-sizing: border-box;
    h1{
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 600;
      margin: 0 0 22px;
    }
    p{
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -0.02px;
      color: #424242;
      margin: 0;
    }
  }
  .inspirationItemList{
    margin-top:52px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 72px 24px;
  }


  @media (max-width: 1500px) {
    width:90%;
  }

  @media (max-width: 720px) {
    .banner{
      padding: 28px 0 23px;
      h1{
        font-size: 24px;
      }
      p{
        font-size: 12px;
      }
    }
    .inspirationItemList{
      margin-top:24px;
      grid-gap: 32px 12px;
    }
  }
  @media (max-width: 620px) {
    margin:0 auto 88px;
    .viewMore{
      margin-top:32px;
    }
    .inspirationItemList{
      grid-template-columns: 1fr;
    }
  }


`
