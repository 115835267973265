import Banner from '../../components/banner/banner'
import ArchiveList from '../../components/archive/archiveList'
import ArrangeBar from "../../components/selectSearchBar";

import {useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../../auth/auth";
import { usePagination } from '../../customHook/usePagination';
import { useBlock } from '../../customHook/useBlock';
import { archiveCategory } from '../../listsAndObjects';
import { stringToSnakeCase, convertKeysToLowercase } from '../../converter';
import { useWindowSize } from "../../customHook/useWindowSize";
import styled from 'styled-components'

export default function Archive() {

  const { user } = useAuth();
  const location = useLocation()
  const navigate = useNavigate()
  const { archiveData, setArchiveData, isArchiveChanged} = usePagination()
  const { innerWidth } = useWindowSize()

  const [sort, setSort] = useState(0)
  const [filter, setFilter] = useState(1)
  const [cursor, setCursor] = useState(0)
  const { setBlock } = useBlock()


  let archiveType = location.pathname.replace('/','') // Lookbook, Campaign 등등
  let archiveTypeIndex = archiveType === 'archive'? 0 : convertKeysToLowercase(archiveCategory)[archiveType]

  let user_idx = user? user.data.user_idx : 0
  let url = `/user/archive?user_idx=${user_idx}&category=${archiveTypeIndex}&sort=${sort}&filter=${filter}`

  useEffect(()=>{
    setBlock(true)
    setArchiveData(url)
    setCursor(0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[url])

  useEffect(()=>{
    if(!cursor){return}
    let newUrl = url+`&cursor=${cursor}`
    setBlock(true)
    setArchiveData(newUrl)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cursor])


  const changeArchiveType = (value) => {
    navigate(`/${stringToSnakeCase(value)}`)
  }

  const viewMore = (lastItem) => {
    sort ? setCursor(lastItem.archive_start) : setCursor(lastItem.archive_name)
  }

  return (
    <Div>
      <Banner location="archive"></Banner>
      {innerWidth > 720 ?
      <>
        <h2>다양한 브랜드의 최신 트렌드를 감상해보세요!</h2>
        <div className="arrangeBar">
          <ArrangeBar urlPath={archiveType} sort={sort} setSort={setSort} setSearchCategory={changeArchiveType} filter={filter} setFilter={setFilter}></ArrangeBar>
        </div>
      </>
      :
      <>
       <div className="arrangeBar">
          <p>Archive</p>
          <ArrangeBar urlPath={archiveType} sort={sort} setSort={setSort} setSearchCategory={changeArchiveType} filter={filter} setFilter={setFilter}></ArrangeBar>
        </div>
      </>
      }
     
      <div className="archive">
        {archiveData && <ArchiveList archiveDatas={archiveData}></ArchiveList>}
      </div>
      {isArchiveChanged && archiveData?.length % 12 === 0 && archiveData?.length >= 12 && 
        <div className="viewMore" onClick={() => { user? viewMore(archiveData[archiveData.length-1]) : navigate('/start')}}>더보기</div>
      }
    </Div>
  );
}

const Div = styled.div`
  margin-bottom:146px;
  > h2{
    font-family: Pretendard;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: -0.03px;
    color: #1b1b1b;
    margin:62px auto 28px;
    max-width: 1400px;
    width: 100%;
  }
  .archive{
    min-width: 324px;
    > div{      
      border-top: 1px solid #ededed;
      padding-top:40px;
      margin: 46px auto 0;
    }
  }
  .viewMore{
    margin-top:62px;
  }
  @media (max-width: 1500px) {
    .archive{
      width: 90%;
      margin:0 auto;
    }
    h2{
      width: 90%;
    }
    .arrangeBar{
      width:90%;
      margin:0 auto;
    }
  }

  @media (max-width: 720px) {
    margin-bottom:84px;
    .arrangeBar{
      display:flex;
      justify-content:space-between;
      align-items:center;
      margin:28px auto -15px;
      > p{
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: normal;
        margin:0;
      }
      > div{
        min-width:inherit;
        margin:0;
      }
    }
  }

  @media (max-width: 420px) { // grid item 1fr 되는 지점
    .viewMore{
      margin-top:32px;
    }
  }

`