
import styled from 'styled-components';
import { useState } from 'react';
import useAPI from '../../api/useAPI';
import { useWindowSize } from '../../customHook/useWindowSize';
import { useAuth } from "../../auth/auth";
import { useModal } from '../../components/modal/modal';

export default function ReportModal({ user_idx }) {

  const labelTexts = ['거짓 정보 게시', '저작물 외 음란물 게시', '검증되지 않은 이용자', '기타']
  const [checked, setChecked] = useState([false, false, false, false])
  const [taxtValue, setTextValue] = useState("")
  const { innerWidth, innerHeight } = useWindowSize()
  const [errors, setErrors] = useState('')
  const { putAPI } = useAPI()
  const { user } = useAuth();
  const { modalShow, modalClose } = useModal()

  let handleOnChange = (index) => {
    const updatedCheckedState = checked.map((checked, i) => i === index ? !checked : checked)
    setChecked(updatedCheckedState);
  }

  let onSubmit = async () => {
    let reason = []
    const validateCheck = checked.filter((checked, index) => checked && reason.push(labelTexts[index]));
    if (validateCheck.length === 0) {
      setErrors('신고 사유를 선택해주세요.')
      return
    }

    if (checked[checked.length - 1]) { // 기타가 체크된 경우  
      if (taxtValue === "") {
        setErrors('기타 내용을 입력해주세요.')
        return
      }
      reason.pop()
      reason.push(taxtValue)
    }
    setErrors('')
    let url = `/user/report`
    let data = {
      "user_idx": user_idx,
      "report_detail": JSON.stringify(reason)
    }
    let { result } = await putAPI(url, user, data)
    if (result === 'success') {
      modalShow({
        message: `신고가 완료되었습니다`, actionButton: <div className="blackButton" onClick={() => modalClose()}>닫기</div>
      })
    }
  }

  return (
    <Div width={innerWidth} height={innerHeight}>
      <div className="header" style={{ color: "#000" }}>신고사유</div>
      <div className="contentWrapper">
        <div className="checkbox round">
          {labelTexts.map((value, index) => (
            <p key={value}>
              <input
                id={value}
                type="checkbox"
                onChange={() => handleOnChange(index)}
              />
              <label htmlFor={value}>{value}</label>
            </p>
          ))}
        </div>
        <textarea placeholder="내용을 입력해주세요.(100자 이내)" value={taxtValue} onChange={(e) => { setTextValue(e.target.value) }} />
        {errors && <span>{errors}</span>}
        <div className="blackButton" onClick={() => { onSubmit() }}>신고하기</div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  max-width:500px;
  max-height:501px;
  width: calc(${props => props.width}px * 0.8);
  height: calc(${props => props.height}px * 0.9);
  border-radius: 12px;
  background-color: white;
  padding:0 50px;
  box-sizing:border-box;
  overflow: auto;
  .header{
    padding-top: 24px;
    padding-bottom: 21px;
    font-family: Pretendard;
    font-size: 26px;
    font-weight: 500;
  }

  & > span{
    color:#EB5757;
  }

  .checkbox{
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label{
      padding-left: 32px;
      line-height: 24px;
    }
    [type="checkbox"]:not(:checked) + label:before {
      width: 18px;
      height: 18px;
    }
    [type="checkbox"]:not(:checked) + label:after {
      top: 6px;
      left: 6px;
    }
  }
  textarea{
    margin-top:12px;
    width: 100%;
    height: 160px;
    border: solid 1px #e4e4e4;
    resize: none;
    padding:10px;
    box-sizing: border-box;
    padding-bottom:32px;
    border-radius: 4px;
    font-family: Pretendard;
    font-size: 14px;
  }
  
  textarea::placeholder{
    font-family: Pretendard;
    font-size: 14px;
    color: #b4b4b4;
  }

  textarea:focus-visible{
    outline: solid 1px black;
  }

  .blackButton{
    width: 172px;
    height: 48px;
    margin:18px auto 27px !important;
  }

  @media (max-width: 420px) {
    width: calc(${props => props.width}px * 1);
    height: calc(${props => props.height}px * 1);
    border-radius:0;
    padding:0;
    .header{
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
      padding:18px 0;
      border-bottom:1px solid #f7f7f7;
      font-size: 17px;
    }
    .contentWrapper{
      width: 324px;
      margin: 0 auto;
    }
  }
`