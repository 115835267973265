

import styled from "styled-components"
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import useQuery from '../../api/useQuery'
import { inspirationCategory } from '../../listsAndObjects'
import { GMTtoKSTconverter } from '../../converter'
import { useBlock } from '../../customHook/useBlock'
import parse from 'html-react-parser'

export default function InspirationDetail() {

  const { inspiration_idx } = useParams() // 해당 user_idx로 detail 불러옴
  const navigate = useNavigate()
  const { BlockUI } = useBlock()

  const [apiData] = useQuery({ url: `/user/inspiration/detail?idx=${inspiration_idx}` })
  let date = apiData && GMTtoKSTconverter(apiData.inspiration_datetime + `.000Z`)

  return (
    <Div>
      {apiData ?
        <>
          <div className="banner" style={{ backgroundImage: `url(${apiData.inspiration_thumnail})` }}>
            <div>
              <div className="textarea">
                <div className="category">{inspirationCategory[apiData.inspiration_category - 1]}</div>
                <div className="title">{apiData.inspiration_main_title}</div>
                <div className="subTitle">{apiData.inspiration_sub_title}</div>
                <div className="dateAndAuthor">
                  <span className="date">  
                    {`${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}`}
                  </span>
                  <span className="author">
                    {apiData.inspiration_writer}
                  </span>
                </div>

              </div>
            </div>
          </div>
          <div className="board">{parse(apiData.inspiration_board)}</div>
          <div className="viewMore" onClick={() => { navigate('/inspiration') }}>목록보기</div>
        </>
        :
        <BlockUI />
      }
    </Div>
  );
}

const Div = styled.div`
  text-align:center;
  margin:0 auto 146px;

  .viewMore{
    margin-top:2px; // 62px - 60(padding) = 2px
  }

  .banner{
    width: 100%;
    min-width:324px;
    height: 560px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    >div{
      width:100%;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(2px);
      height: 560px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .textarea{
      max-width:800px;
      width: 90%;
      *{
        font-family: Pretendard;
        color: #fff;
        text-align:left;
      }
      .category{
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.05px;
        height:21px;
        line-height: 21px;
        text-decoration: underline;
      }
      .title{
        font-size: 46px;
        font-weight: bold;
        margin:21px 0 42px;
      }
      .subTitle{
        font-size: 16px;
        letter-spacing: -0.34px;
        line-height: 1.75;
        margin-bottom:68px;
        max-width:433px;
        width:100%;
      }
      .dateAndAuthor{
        span{
          font-size: 18px;
          font-weight: 500;
          }
        .date{
          font-family: Montserrat;
        }
        .date:after{
          content:"";
          display: inline-block;
          width:1px;
          height:10px;
          border-right: solid 1px #fff;
          margin:0 18px;
        }
        .author{
          font-family: Pretendard;
        }
      }
    }
  }

  .board{
    max-width: 800px;
    min-width:324px;
    width:90%;
    margin: 0 auto;
    padding:60px 0;
    img{
      width: 100%;
      max-width: 800px;
    }
    > p{
      height:16px;
    }
  }
  @media (max-width: 900px) {
    .banner{
      height:380px;
      >div{
        height:380px;
      }
      .textarea{
        .category{
          font-size: 13px;
        }
        .title{
          font-size: 28px;
          margin:16px 0 20px;
        }
        .subTitle{
          font-size: 12px;
          letter-spacing: -0.03px;
          line-height: 1.67;
          margin-bottom:32px;
        }
        .dateAndAuthor{
          span{
            font-size: 13px;
            }
          .date:after{
            margin:0 10px;
          }
        }
      }
    }
    .board{
      img{
        max-width: 100%;
      }
    }
  }

  @media (max-width: 720px) {
    margin:0 auto 84px;
    .viewMore{
      margin-top:-20px; // -20 = 40px - 60(padding)
    }
  }
`
