import { Route, Routes } from "react-router-dom";
import Project from './project';
import ProjectForm from './projectForm';


export default function ProjectRoute() {

  return (
    <Routes>
      <Route index element={<Project />} />
      <Route path="/add" element={<ProjectForm />}></Route>
      <Route path="/update" element={<ProjectForm />}></Route>
    </Routes>
  )
}


