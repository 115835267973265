
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import useAPI from '../../api/useAPI';
import { useWindowSize } from '../../customHook/useWindowSize';
import { useAuth } from "../../auth/auth";
import { useModal } from '../../components/modal/modal';
import { useForm } from "react-hook-form";
import Button from '../button';

export default function AccountModal({user_idx}) {

  const { innerWidth } = useWindowSize()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { getAPI, putAPI } = useAPI()
  const { user } = useAuth();
  const { modalShow, modalClose } = useModal()
  const [apiData, setApiData] = useState()
  

  // apiData : user_account, user_account_name, user_bank

  let onSubmit = async (data) => {
    let url = `/api/user/account`
    let { result } = await putAPI(url, user, data)
    if (result === "success") {
      modalShow({ message: `계좌번호가 정상적으로 등록되었습니다.`, actionButton: <div onClick={() => { modalClose() }} className="blackButton">닫기</div> })
    }
  }


  useEffect(()=>{
    const fetchAPI = async () => {
      let url = `/api/user/account?user_idx=${user_idx}`
      let { data } = await getAPI(url, user)
      setApiData(data)
    }
    fetchAPI()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return (
    <Div innerWidth={innerWidth}>
      {apiData && 
      
      <div>
        <div className="header" style={{ color: "#000" }}>계좌 등록</div>
        <form id='accountChangeForm' onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>은행명</label>
            <input type="text" placeholder="은행명을 입력해주세요" defaultValue={apiData.user_bank} {...register("user_bank", { required: true })} />
            {errors.user_bank?.type === 'required' && <span className="errorMessage">은행명을 입력해주세요.</span>}
          </div>
          <div>
            <label>예금주</label>
            <input type="text" placeholder="예금주 입력해주세요" defaultValue={apiData.user_account_name} {...register("user_account_name", { required: true })} />
            {errors.user_account_name?.type === 'required' && <span className="errorMessage">예금주를 입력해주세요.</span>}
          </div>
          <div>
            <label>계좌번호</label>
            <input type="text" placeholder=" '-' 없이 숫지만 입력해주세요" defaultValue={apiData.user_account} {...register("user_account", { 
              required: true,  
              pattern: {
                value: /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
              }
            })}/>
            {errors.user_account?.type === 'required' && <span className="errorMessage">계좌번호를 입력해주세요.</span>}
            {errors.user_account?.type === 'pattern' && <span className="errorMessage">숫자만 입력해주세요.</span>}

          </div>
        </form>
        <Button style={{ margin: "20px auto 10px" }} form='accountChangeForm' type="submit">등록하기</Button>
      </div>
      }
    </Div>
  )
}

const Div = styled.div`
  max-width:380px;
  width: calc(${props => props.innerWidth}px * 0.8);
  margin: 0 auto 40px;
  background-color: white;
  & > div{
    width: 90%;
    margin:0 auto;
    height: 526px;
    .header{
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 1.5rem;
    }

    form{  
    display:grid;
    grid-template-columns: 1fr;
    gap: 20px 20px;
    text-align: left;
    margin: 0px auto 20px;

    > div{
      max-width:360px;
      height:100px;
    }
  
    input{
      border: 1px solid #ECECEC;
      width:100%;
      height:50px;
      box-sizing:border-box;
      padding-left:10px;
      margin-bottom:5px;
    }

    input::placeholder{
      font-size: 0.875em;
      color:#777777;
    }

    label{
      display: block;
      height:20px;
      margin-bottom: 10px;
    }
    .errorMessage{
      color:#EB5757;
    }

    
  }

  }
`