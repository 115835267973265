import PageNumber from "../../../components/pageNumber";
import { useNavigate } from "react-router-dom";
import { questionCategory } from "../../../listsAndObjects";
import { useWindowSize } from "../../../customHook/useWindowSize";
import { GMTtoKSTconverter } from "../../../converter";
import styled from "styled-components";

export default function Question({ questionList, total_pageno, selectPageno, setSelectPageno }) {

  let navigate = useNavigate()
  const { innerWidth } = useWindowSize()

  return (
    <Div hasQuestion={questionList.length !== 0 && innerWidth > 720}>
      <div className="questionButtonContainer">
        <div className="blackButton" onClick={() => { navigate('/mypage/question/ask') }}>1:1 문의하기</div>
      </div>
      <>
        {innerWidth > 720 ?
          <table>
            <thead>
              <tr className="tableHead">
                <th>번호</th>
                <th>제목</th>
                <th>유형</th>
                <th>처리상태</th>
                <th>등록일</th>
              </tr>
            </thead>
            <tbody>
              {questionList?.map((question, index) => {
                let questionDate = GMTtoKSTconverter(question.question_datetime + `.000Z`)
                return (
                  <tr key={question.question_idx}>
                    <td>{questionList.length - index}</td>
                    <td className="title" onClick={() => { navigate('/mypage/question/answer', { state: { question } }) }}>{question.question_title}</td>
                    <td>{questionCategory[question.question_category - 1]}</td>
                    <td>{question.question_type ? <span style={{ color: '#0053ff' }}>답변완료</span> : '답변대기'}</td>
                    <td>{`${questionDate.getFullYear()}.${questionDate.getMonth() + 1}.${questionDate.getDate()}`}</td>
                  </tr>
                )
              })
              }
            </tbody>
          </table>
          :
          <div className="questionList">
            {questionList?.map((question) => {
              let questionDate = GMTtoKSTconverter(question.question_datetime + `.000Z`)
                return (
                  <div key={question.question_idx} onClick={() => { navigate('/mypage/question/answer', { state: { question } }) }}>
                    <div>
                      <span className="date">{`${questionDate.getFullYear()}.${questionDate.getMonth() + 1}.${questionDate.getDate()}`}</span>
                      <span>{questionCategory[question.question_category - 1]}</span>
                    </div>
                    <div className="title">
                      {question.question_title}
                    </div>
                    <div className="questionType">{question.question_type ? <span style={{ color: '#0053ff' }}>답변완료</span> : '답변대기'}</div>
                  </div>
                )
              })
              }
          </div>
        }
        {questionList.length === 0 &&
          <div className="noQuestionList">
            등록된 내용이 없습니다.
          </div>
        }
      </>
      {total_pageno >= 2 && <PageNumber total_pageno={total_pageno} setSelectPageno={setSelectPageno} selectPageno={selectPageno} />}
    </Div>
  )
}


const Div = styled.div`

  min-height: ${props => props.hasQuestion ? '840' : '216'}px; // 296px - 80(margin-bottom)
  margin-bottom:80px;

  .questionButtonContainer{
    max-width: 1180px;
    display: flex;
    justify-content: flex-end;
    position:relative;
    top:-44px; // button 높이만큼
    .blackButton{
      width: 108px;
      height: 44px;
    }
  }

  table{
    border-collapse: collapse;
    width: 100%;
    margin:-6px auto 120px; // 원래 간격 38 = 44(버튼 높이) - 6
    .tableHead{
      th{
        font-family: Pretendard;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: -0.2px;
        color: #5a5a5a;
      }
      height:50px;
      background-color: #f5f5f5;
    }
    tr{
      height:60px;
      color: #222222;
      th{
        border-top: solid 1px #4e4e4e;
        border-bottom:solid 1px #e0e0e0;
      }
      td{
        border-top:solid 1px #e0e0e0;
        border-bottom:solid 1px #e0e0e0;
      }
      td, span{ // span : 답변완료 부분
        font-family: Pretendard;
        font-size: 14px;
        letter-spacing: -0.22px;
        color: #888;
      }
      .title{
        color: #222;
        cursor:pointer;
      }
      .whiteButton{
        width: 58px;
        height: 32px;
        font-size: 13px;
        font-weight: 500;
        color: #818181;
      }
    }
    th:nth-child(1){
      width:10%;
    }
    th:nth-child(2){
      width:45%;
    }
    th:nth-child(3){
      width:15%;
    }
    th:nth-child(4){
      width:15%;
    }
    th:nth-child(5){
      width:15%;
    }
    td:nth-child(2){
      text-align: left;
      padding-left:10px;
      overflow: hidden;
    }
  
  }


  .noQuestionList{
    border-bottom: 1px solid #e0e0e0;
    height:67px;
    line-height:67px;
    width: 100%;
    margin: -120px auto 0;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.22px;
    color: #222;
  }

  @media (max-width: 720px) {
    .questionButtonContainer{
      top:0;
      justify-content:flex-start;
      position: inherit;
      margin-top: 26px;
    }
    .questionList{
      margin-top:22px;
      border-top:solid 1px #000;
      > div{
        cursor:pointer;
        border-bottom: solid 1px #e0e0e0;
        padding:18px 0;
        >div{
          text-align:left;
          span{
            font-family: Pretendard;
            font-size: 13px;
            letter-spacing: -0.2px;
            color: #888;
          }
          .date{
            margin-right:12px;
          }
        }
        .title{
          font-family: Pretendard;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.46;
          letter-spacing: -0.2px;
          color: #222;
          margin:8px 0 10px;
        }
        .questionType{
          font-family: Pretendard;
          font-size: 13px;
          letter-spacing: -0.2px;
          color: #888;
        }
      }
    }
    .noQuestionList{
      margin-top:0;
    }
  }
`;

