import styled from 'styled-components';
// import { v4 as uuidv4 } from 'uuid';




export default function Radio({labelTexts, values, id, onChange, style}) {
  // const key = uuidv4()
  return (
    <Div style={style} className="radio">
      {labelTexts.map((labelText, index)=>{
        return(
          <p key={id+index}>
            <input 
              type="radio" 
              id={id+index} 
              name={id}
              onChange={() => {onChange(id, values[index])}}
            />
            <label htmlFor={id+index}>{labelText}</label>
          </p>
        )       
      })}
    </Div>
  )
}

const Div = styled.div` 
display: flex !important; 
text-align: left;
label{
  margin-right:14px;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #222;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

`
// https://codepen.io/manabox/pen/raQmpL