
import { Route, Routes } from "react-router-dom";
import Question from './question';
import QuestionAnswer from './questionAnswer'
import QuestionAsk from './questionAsk'
import { useAuth } from "../../../auth/auth";
import { useScrollToTop } from "../../../customHook/userScrollToTop";
import { useBlock } from "../../../customHook/useBlock";
import useAPI from "../../../api/useAPI";
import { useEffect, useState } from "react";
import styled from "styled-components";

export default function QuestionRoute() {
  const { user } = useAuth()
  const [selectPageno, setSelectPageno] = useState(1)
  const [total_pageno, setTotal_pageno] = useState(1)

  const { getAPI } = useAPI()
  const { scrollToTop } = useScrollToTop()
  const { BlockUI } = useBlock()
  const [questionList, setQuestionList] = useState([])
  const [reload, setReload] = useState(Math.random())
  // ask 등록 후 반영

  useEffect(() => {
    const fetchAPI = async () => {
      let url = `/user/question?pageno=${selectPageno}`
      let { data }  = await getAPI(url, user)
      setQuestionList(data?.question)
      setTotal_pageno(data?.total_pageno)
      scrollToTop()
    }
    fetchAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPageno, user, reload])

  return (
    <Div>
      <div className="header">1:1 문의</div>
      <div className="subHeader">궁금한 것을 물어보세요</div>
      {questionList ? 
        <Routes>
        <Route index element={<Question questionList={questionList} total_pageno={total_pageno} selectPageno={selectPageno} setSelectPageno={setSelectPageno}/>} />
        <Route path="/ask" element={<QuestionAsk setReload={setReload}/>}></Route>
        <Route path="/answer" element={<QuestionAnswer setReload={setReload}/>}></Route>
      </Routes>
      :
      <BlockUI/>
      }
    </Div>
  )
}

const Div = styled.div`
  padding-right:40px;
  @media (max-width: 900px) {
    padding:0 18px;
  }
`
