import { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useScrollLock } from "../../customHook/useScrollLock";
import ModalContent from "./modalContent";
import DeleteConfirmModal from "./deleteConfirmModal";
import AddressModal from "./addressModal";
import MatchingModal from "./matchingModal";
import ProfileChangeModal from "./profileChangeModal";
import ReportModal from "./reportModal";
import ShareModal from "./shareModal";
import AddCampaginMemberModal from "./addCampaginMemberModal";
import styled from "styled-components";
import AccountModal from "./accountModal";
import SubImageModal from "./subImageModal";
import ProjectMemberModal from "./projectMemberModal";
import AddWishAndMoodbardModal from "./addWishAndMoodbardModal";
import CarouselModal from "./carouselModal";
import closeButton from '../../images/closeButton.svg'
import DeleteUserModal from "./deleteUserModal";
import FilterModal from "./filterModal";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {

  const { lockScroll, unlockScroll } = useScrollLock();

  const [isShow, setShow] = useState(false)
  const [visible, setVisible] = useState(false)
  // setShow(false)로 바로 닫아버리면 rerendering돼서 fadeout animation 안먹힘.
  // setVisible(false) 후 일정시간 후 setShow하면 그시간동안 fadeout animation 적용 가능.

  const [content, setContent] = useState('')
  const [closeButtonStyle, setCloseButtonStyle] = useState({})

  useEffect(() => {
    let timeoutId;
    visible ? setShow(true) : timeoutId = setTimeout(() => setShow(false), 100);

    const keyDownHandler = event => {
      if(!visible){return}
      event.key === 'Escape' && modalClose()
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      if (timeoutId !== undefined) {clearTimeout(timeoutId);}
      document.removeEventListener('keydown', keyDownHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const addressModal = useCallback((content) => {
    setVisible(true)
    setContent(<AddressModal content={content} setCloseButtonStyle={setCloseButtonStyle}/>)
    lockScroll()
  }, [lockScroll])

  const deleteModal = useCallback((value, callback) => {
    setVisible(true)
    setContent(<DeleteConfirmModal deleteIndex={value} handleDelete={callback} />)
    lockScroll()
  }, [lockScroll])

  const matchingModal = useCallback((data, callback) => {
    setVisible(true)
    setContent(<MatchingModal data={data} callback={callback} />)
    lockScroll()
  }, [lockScroll])

  const profileChangeModal = useCallback(() => {
    setVisible(true)
    setContent(<ProfileChangeModal/>)
    lockScroll()
  }, [lockScroll])

  const reportModal = useCallback((user_idx) => {
    setVisible(true)
    setContent(<ReportModal user_idx={user_idx}/>)
    lockScroll()
  }, [lockScroll])

  const shareModal = useCallback((profileData, type) => {
    setVisible(true)
    setContent(<ShareModal profileData={profileData} type={type}/>)
    lockScroll()
  }, [lockScroll])

  const accountModal = useCallback((user_idx) => {
    setVisible(true)
    setContent(<AccountModal user_idx={user_idx}/>)
    lockScroll()
  }, [lockScroll])
  
  const subImageModal = useCallback((index, callback) => {
    setVisible(true)
    setContent(<SubImageModal index={index} callback={callback}/>)
    lockScroll()
  }, [lockScroll])

  const projectMemberModal = useCallback((member) => {
    setVisible(true)
    setContent(<ProjectMemberModal member={member}/>)
    lockScroll()
  }, [lockScroll])

  const addWishAndMoodbardModal = useCallback((message, subMessage, actionButton) => {
    setVisible(true)
    setContent(<AddWishAndMoodbardModal message={message} subMessage={subMessage} actionButton={actionButton}/>)
    lockScroll()
  }, [lockScroll])

  const carouselModal = useCallback((images, index) => {
    setVisible(true)
    setContent(<CarouselModal images={images} index={index} setCloseButtonStyle={setCloseButtonStyle}/>)
    lockScroll()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockScroll])

  const deleteUserModal = useCallback(() => {
    setVisible(true)
    setContent(<DeleteUserModal/>)
    lockScroll()
  }, [lockScroll])

  const filterModal = useCallback((isArtist, category, arrange, region) => {
    setVisible(true)
    setContent(<FilterModal isArtist={isArtist} category={category} arrange={arrange} region={region}/>)
    lockScroll()
  }, [lockScroll])

  const modalShow = useCallback((content) => {
    setVisible(true)
    setContent(<ModalContent modalContent={content} />)
    lockScroll()
  }, [lockScroll]);

  const addCampaginMemberModal = useCallback((searchKeyword, callback ) => {
    setVisible(true)
    setContent(<AddCampaginMemberModal searchKeyword={searchKeyword} callback={callback} />)
    lockScroll()
  }, [lockScroll]);

  const modalClose = useCallback(() => {
    setVisible(false)
    setCloseButtonStyle({}) // close시 style 모두 제거됨.
    unlockScroll()
  }, [unlockScroll]);

  const modalCloseLockScroll = useCallback(() => {
    setVisible(false)
  }, []);

  const value = useMemo(
    () => ({ modalShow, deleteModal, matchingModal, profileChangeModal, reportModal, shareModal, addressModal, accountModal, subImageModal, projectMemberModal, addWishAndMoodbardModal, carouselModal, deleteUserModal, addCampaginMemberModal, filterModal, modalClose, modalCloseLockScroll, closeButtonStyle }),
    /*eslint-disable */
    []
    /*eslint-enable */
  );

  return (
    <ModalContext.Provider value={value}>
      {isShow &&
        <Div visible={visible}
          onClick={(e) => {e.stopPropagation();modalClose()}}
        // close modal when outside of modal is clicked
        >
          <div className="modalContent"
            onClick={e => { e.stopPropagation() }}
          // modal content click시 닫지 않도록.
          >
            {content}
            <img className="modalClose" alt="close" style={closeButtonStyle} src={closeButton} onClick={() => {modalClose()}}/>
          </div>
        </Div>
      }
      {children}
    </ModalContext.Provider>

  )

};

export const useModal = () => {
  return useContext(ModalContext);
};


const Div = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  animation: ${props => props.visible ? 'fadeIn' : 'fadeOut'} 0.1s ease-out;

  .modalContent {
    position: relative;
    text-align:center;
  }

  .modalClose {
    fill: white;
    position: absolute;
    top: 18px;
    right: 20px;
    width: 18px;
    height: 18px;
    z-index:100;
    cursor:pointer;
  }

  .message, .message > * {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
    color: #000;
  }
  .submessage, .submessage > *{
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    color: #999;
    margin-top:10px;
  }

  .whiteButton{
    width: 172px;
    height: 48px;
    border-radius: 4px;
    margin: 0 auto;
  }

  .blackButton{
    width: 172px;
    height: 48px;
    border-radius: 4px;
    margin: 0 auto;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media (max-width: 720px) {
    .message, .message > * {
    font-size: 17px;
    }
    .submessage, .submessage > *{
      font-size: 12px;
    }
  }
`