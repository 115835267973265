import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { occupationList } from "../../listsAndObjects";
import { stringToSnakeCase } from "../../converter";
import { useNavigate, useLocation } from "react-router-dom";

function Nav() {
  const [showArtistDropdown, setArtistDropdown] = useState(false)
  const navigation = useNavigate()
  const location = useLocation()
  const modalRef = useRef()
  const artistRef = useRef()

  useEffect(() => {
    // 해당 component mount시 실행
    function handler(event) {
      if (!modalRef.current?.contains(event.target) && !artistRef.current?.contains(event.target)) {
        setArtistDropdown(false)
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler) // 해당 component unmount시 실행(clean up). EventListent 제거함.
  }, [])

  const isArtistPage = () => {
    // artists 는 각각 url이 달라서 찾는 함수
    if(location.pathname.includes('/artist')){
      return true
    }
    for(let i = 0; i<occupationList.length; i++){
      if(location.pathname.includes(occupationList[i].toLowerCase()) === true){
        return true
      }
    }
    return false
  }

  return (
    <Div>
      <div className="NavItem" onClick={() => { setArtistDropdown(!showArtistDropdown) }} ref={artistRef} >
        <div>
          <div style={{marginRight: "14px"}}>Artists</div>
          <img className="arrow-icon" alt="arrow icon" src={require('../../images/arrow.png')} style={showArtistDropdown ? {transform:"rotate(180deg)"} : {transform:"rotate(0deg)"} }/>
        </div>
        <div className="underLine" style={isArtistPage() ? {} : {display:"none"}}/>
      </div>
      <div id="archive" className="NavItem" >
        <Link to="/archive">Archive</Link>
        <div className="underLine" style={location.pathname.includes('/archive') ? {} : {display:"none"}} />
      </div>
      <div className="NavItem">
        <Link to="/inspiration">Inspiration</Link>
        <div className="underLine" style={location.pathname.includes('/inspiration') ? {} : {display:"none"}}/>
      </div>
      {showArtistDropdown &&
        <div className="artistDropdown" ref={modalRef}>
          <div onClick={() => { setArtistDropdown(!showArtistDropdown); navigation(`/artist`) }}>all Artists</div>
          {occupationList.map((occupation) => {
            return <div key={occupation} onClick={() => { setArtistDropdown(!showArtistDropdown); navigation(`/${stringToSnakeCase(occupation)}`) }}>{occupation}</div>
          })}
        </div>
      }
    </Div>
  );
}

export default Nav;

const Div = styled.div`
  position:relative;
  display: flex;
  align-items:center;


  .NavItem{
    cursor: pointer;
    padding:0 24px;
    width: 100%;
    height: 80px;
    line-height:80px;
    position:relative;
    a, div{
      display: block;
      font-family: Montserrat;
      font-size: 18px;
      font-weight: 600;
    }
    .underLine{
      position:absolute;
      width:calc(100% - 24px); // padding의 절반인 12px를 양옆으로 하기 위해
      height:3px;
      background-color:black;
      top:calc(100% - 2px);
      left:12px;
    }
  }
  .NavItem:after{
    content:"";
    display: block;
    height:0px;
    width:calc(100% + 24px);
    margin: 0 auto;
    background-color: black;
    position:relative;
    top:0px;
    left:-12px;
    transition:0.2s;
  }

  .NavItem:hover:after{
    height:3px;
    top:-2px;
  }
  
  .arrow-icon{
    width:14px;
    height:8px;
    position: absolute;
    top: 36px;
    left: 93px;
    transition:0.2s;
  }
  
  .artistDropdown{
    position: absolute;
    top: calc(1px + 100%);
    background: white;
    width: 190px;
    padding: 10px 0px;
    border-radius: 4px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #edeeef;
    div{
      cursor:pointer;
      text-align: left;
      padding: 15px 0px 15px 17px;
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      color: #5d5d5d;
    }
    div:hover{
      background-color: #000;
      color: #fff;
    }
  }

  @media (max-width: 1250px) {
    display: none;
  }
`