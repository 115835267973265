import styled from "styled-components"
import { useModal } from "./modal"
import { useState } from "react"
import imageUpload from "../../images/imageUpload.svg"
import videoUpload from "../../images/videoUpload.svg"

export default function SubImageModal({ index, callback }) {

  const { modalClose } = useModal()
  const [isVideo, setVideo] = useState(false)
  const [youtubeURL, setYoutubeURL] = useState("")
  const [embedID, setEmbedID] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    callback(embedID)
    modalClose()
  }

  const handleOnChange = (e) => {
    let url = e.target.value
    url = url.replace("https://youtu.be/", '');
    url = url.replace("https://www.youtube.com/embed/", '');
    url = url.replace("https://www.youtube.com/watch?v=", '');
    url = url.split('&')[0];
    setEmbedID(url)
    setYoutubeURL(e.target.value)
  }

  return (
    <Div>
      <div className="message">
        콘텐츠를 선택하여 업로드 하세요
      </div>
      {!isVideo ?
        <>
          <div className="flexContainer">
            <div className="uploadOption">
              <input id={`files${index}`} className="hidden" type="file" multiple accept="image/*" onChange={(e) => { callback(e, index)}} />
              <label htmlFor={`files${index}`}>
                <img src={imageUpload} alt="img upload icon" />
              </label>
              이미지 업로드
            </div>
            <div className="uploadOption" onClick={() => { setVideo(true) }}>
              <label>
                <img src={videoUpload} alt="video upload icon" />
              </label>
              영상 URL 업로드
            </div>
          </div>
        </>
        :
        <>
          <form onSubmit={handleSubmit} id='uploadForm'>
            <div className="submessage">
              Youtube에 등록된 영상만 가능합니다.
            </div>
            <label htmlFor="youtube">URL입력</label>
            <input id="youtube" type="text" name="youtube" placeholder="해당 유튜브 영상의 url을 입력해주세요" value={youtubeURL} onChange={(e)=>{handleOnChange(e)}}></input>
          </form>
          {embedID && <img className="thumbnail" src={`https://img.youtube.com/vi/${embedID}/mqdefault.jpg`} alt="youtube Thumbnail" />}
          <div className="buttons">
            <div className="whiteButton" onClick={()=>{setVideo(false)}}>이전</div>
            {embedID && <button className="blackButton" form="uploadForm" type="submit">업로드 하기</button>}
          </div>
        </>
      }
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:48px 20px 24px;
  box-sizing:border-box;
  border-radius: 12px;

  .hidden{
    display: none;
  }

  .flexContainer {
    display: flex;
    justify-content: space-between;
    margin: 28px 34px 30px;
    > .uploadOption {
      display: flex;
      flex-direction: column;
      width:136px;
      height:160px;
      border-radius: 8px;
      border: solid 1px #ddd;
      box-sizing:border-box;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.32px;
      color: #353535;
      margin: 0 10px;
      cursor: pointer;
      transition:0.2s;
      label{
        cursor: pointer;
      }
      img{
        width: 80px;
        height:80px;
        margin:27px 0 8px;
      }
    }
    > .uploadOption:hover {
      border: solid 1px #0053ff;
    }
  }
  form{
    width:100%;
    input{
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      height: 44px;
      padding:0 16px;
      width:100%;
      box-sizing:border-box;
      font-family: Pretendard;
      font-size: 14px;
    }
    input::placeholder{
      font-family: Pretendard;
      font-size: 14px;
      color: #afafaf;
    }
    label{
      display:inline-block;
      width:100%;
      text-align:left;
      margin:24px 0 10px;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
    }
  }

  .thumbnail{
    width: 180px;
    height: 180px;
    margin-top: 28px;
    border-radius: 4px;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin:28px 0 0;
  }
  @media (max-width: 420px) {
    width:324px;
    .flexContainer{
      margin: 18px 0 28px;
    }
  }
`
