import { occupationList, archiveCategory, addressList } from '../../listsAndObjects';
import { stringToSnakeCase } from '../../converter';
import Select from '../select/select';
import styled from 'styled-components';
import arrangeIcon from '../../images/arrange.svg'
import { useWindowSize } from '../../customHook/useWindowSize';
import { useModal } from '../modal/modal';

function ArrangeBar({ urlPath, setSearchCategory, filter, setFilter, sort, setSort, userLocation, setUserLocation, isArtists }) {

  const { innerWidth } = useWindowSize()
  const { filterModal, modalClose } = useModal()

  const sortOptions = [
    { value: 'nameDes', label: '이름순 내림차순' },
    { value: 'nameAsc', label: '이름순 오름차순' },
    { value: 'registerDes', label: '등록순 내림차순' },
    { value: 'registerAsc', label: '등록순 오름차순' },
  ]

  const addressOptions = [{ value: '', label: '전체 지역' }]

  for (let i = 0; i < addressList.length; i++) {
    addressOptions.push({ value: addressList[i], label: addressList[i] })
  }

  const artistCategoryOptions = [{ value: 'artist', label: '전체 아티스트' }]
  for (let i = 0; i < occupationList.length; i++) {
    artistCategoryOptions.push({ value: stringToSnakeCase(occupationList[i]), label: occupationList[i] })
  }


  const archiveCategoryOptions = [{ value: 'archive', label: '전체 카테고리' }]
  const archiveKeyList = Object.keys(archiveCategory)

  for (let i = 0; i < archiveKeyList.length; i++) {
    archiveCategoryOptions.push({ value: stringToSnakeCase(archiveKeyList[i]), label: archiveKeyList[i] })
  }

  const getSortValue = (sort, filter) => {
    if(sort === 0 && filter === 1){
      return "nameDes"
    }
    if(sort === 0 && filter === 2){
      return "nameAsc"
    }
    if(sort ===1 && filter === 1){
      return "registerDes"
    }
    if(sort === 1 && filter === 2){
      return "registerAsc"
    }
  }
  
  const sortValueChange = (value) => {
    switch (value) {
      case "nameDes": setSort(0); setFilter(1); break;
      case "nameAsc": setSort(0); setFilter(2); break;
      case "registerDes": setSort(1); setFilter(1); break;
      case "registerAsc": setSort(1); setFilter(2); break;
      default: setSort(0); setFilter(1)
    }
  }

  const sortPlaceholder = () => {
    return (
      <span style={{
        fontFamily: "Pretendard",
        fontSize: "14px",
        fontWeight: "500",
        letterSpacing: "-0.01px",
        color: "#5d5d5d"
      }}
      >
        <img className="arrangeIcon" src={arrangeIcon} alt="arrangeIcon" />
        정렬
      </span>
    )
  }

  return (
    <Div>
      {innerWidth > 720 ?
        <div>
          <div className="selectItem">
            {isArtists ?
              <Select
                onChange={(e) => { setSearchCategory(e.value) }}
                options={artistCategoryOptions}
                value={artistCategoryOptions.find(option => option.value === urlPath)}
                placeholder="전체 아티스트"
              />
              :
              <Select
                onChange={(e) => { setSearchCategory(e.value) }}
                options={archiveCategoryOptions}
                defaultValue={{ value: "archive", label: '전체 카테고리' }}
              />
            }
          </div>

          {isArtists &&
            <div className="selectItem second">
              <Select
                onChange={(e) => { setUserLocation(e.value) }}
                options={addressOptions}
                defaultValue={{ value: 0, label: '전체 지역' }}
              />
            </div>
          }
          <div className="selectItem third">
            <Select
              onChange={(e) => { sortValueChange(e.value) }}
              options={sortOptions}
              placeholder={sortPlaceholder()}
            />
          </div>
        </div>
        :
        <div className="selectButtonForMobile" onClick={
          isArtists ?
            () => {
              filterModal(
                isArtists,
                <Select
                  onChange={(e) => { setSearchCategory(e.value);modalClose()}}
                  options={artistCategoryOptions}
                  value={artistCategoryOptions.find(option => option.value === urlPath)}
                  placeholder="전체 아티스트"
                />,
                <Select
                  onChange={(e) => { sortValueChange(e.value);modalClose()}}
                  options={sortOptions}
                  value={sortOptions.find(option => option.value === getSortValue(sort, filter))}
                  placeholder={
                    <span style={{
                      fontFamily: "Pretendard",
                      fontSize: "14px",
                      fontWeight: "500",
                      letterSpacing: "-0.01px",
                      color: "#5d5d5d"
                    }}
                    >
                    정렬
                    </span>
                  }
                />,
                <Select
                  onChange={(e) => { setUserLocation(e.value);modalClose()}}
                  options={addressOptions}
                  value={addressOptions.find(option => option.value === userLocation)}
                  defaultValue={{ value: 0, label: '전체 지역' }}
                />,
              )
            }
            :
            () => {
              filterModal(
                isArtists,
                <Select
                  onChange={(e) => { setSearchCategory(e.value);modalClose()}}
                  options={archiveCategoryOptions}
                  value={archiveCategoryOptions.find(option => option.value === urlPath)}
                  defaultValue={{ value: "archive", label: '전체 카테고리' }}
                />,
                <Select
                  onChange={(e) => { sortValueChange(e.value);modalClose()}}
                  options={sortOptions}
                  value={sortOptions.find(option => option.value === getSortValue(sort, filter))}
                  placeholder={
                    <span style={{
                      fontFamily: "Pretendard",
                      fontSize: "14px",
                      fontWeight: "500",
                      letterSpacing: "-0.01px",
                      color: "#5d5d5d"
                    }}
                    >
                    정렬
                    </span>
                  }
                />
              )
            }
          }>
          <img className="arrangeIcon" src={arrangeIcon} alt="arrangeIcon"/>필터 및 정렬
        </div>
      }
    </Div>
  )
}

export default ArrangeBar

const Div = styled.div`
  max-width:1400px;
  min-width:324px; // 3개가 더이상 안붙게 하는 point
  margin: 0 auto;

  > div{
    display: flex;
    align-items: center;
    position:relative;
  }
  .selectItem{
    margin-right: 10px;
    width:190px;
    height:48px;
    &.third{
      position:absolute;
      right:0;
      margin-right:0;
    }
  }
  .arrangeIcon{
    vertical-align:top;
    margin-right:10px;
  }

  .selectButtonForMobile{
    width: 124px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #e6e6e6;
    justify-content:center;
    cursor:pointer;
  }

  @media (max-width: 655px) { // 3개가 붙는 point
    .selectItem{
      margin:0px;
      width:103px;
      &.second{
        margin-left:7px;
      }
    }
    .text{
      display:none;
    }
    .css-lqetsr-container{
      width:103px;
    }
    .css-1b4a6u0-control{
      padding: 0px;
    }
    .css-88v23l-control{
      padding: 0px;
    }
    .css-lsosb-singleValue{
      font-size: 12px;
      margin:0;
      text-overflow:inherit;
    }
    .css-319lph-ValueContainer{
      padding:0;
      margin-left:10px;
    }
    .css-14o8y4y-indicatorContainer{
      padding-left:7px;
      padding-right:7px;
    }
    .css-1j3pprx-indicatorContainer{
      padding-left:7px;
      padding-right:7px;
    }
  }
`