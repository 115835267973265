import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "../customHook/useLocalStorage";
import jwt_decode from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [shouldStoreInLocalStorage, setStoreInLocalStorage] = useState(user? true : false)
  const navigate = useNavigate()
  const location = useLocation()

  const userChange = (newUser, shouldStoreInLocalStorage) => {
    setUser(newUser, shouldStoreInLocalStorage);
  }

  const refreshAPI = async(user) => {
    let {refresh_token, token_type} = user
    return await fetch(`/api/jwt/refresh`,{
      headers: {
        'Authorization': `${token_type} ${refresh_token}`,
      }
      // bearer 
    })
    .then(async (res)=>{
      return {status: res.status, body: await res.json()}
    })
   .then(res=>{
      if(res.status === 200){
        return res.body
      } else {
        navigate(location.pathname, {
          state:{errorStatusCode: '40101'}
        });
        return null
      }
    })
  }

  const tokenRefresh = async (user) => {
    // 만료된 경우에만 refreshAPI 호출
    let decoded
    try{
      decoded = jwt_decode(user.access_token)
    } catch {
      decoded = null
    }
    if(!decoded){return false}
    let now = Date.now()
    if(decoded.exp*1000 < now){
      // decoded.exp < now 이게 만료의미함.
      let refreshResponse = await refreshAPI(user)
      if(refreshResponse){return refreshResponse}else{return}
    } else {
      // console.log('유효한 토큰. 만료시간: ', new Date(decoded.exp*1000))
      return 
    }
  }

  const tokenValidation = (user, shouldStoreInLocalStorage) => {
    return async function(){ // 호출될때 실행되어야해서 callback return함.
      let newUser = await tokenRefresh(user)
      if(newUser){
        let {user_profile} = user
        userChange({...newUser, user_profile}, shouldStoreInLocalStorage) // token에 의해 user가 바뀔때 profile img 안날라가도록 연결
        return {...newUser, user_profile}  // 토큰 유효시간 만료인 경우
      }
      return null
    } 
  } // refresh token할때 변경된 token정보 저장함.

  const login = async (data, rememberMe, location) => { 
    // 자동로그인의 경우 localstorage에 저장.
    rememberMe && setStoreInLocalStorage(rememberMe)
    setUser(data, rememberMe);
    location ? navigate(location):navigate(-1)
  };

  const logout = () => {
    setUser(null, true);
    setStoreInLocalStorage(false)
    navigate("/", { replace: true });
  };

  const userType = user?.data.user_privilege === 1 ? `artists` : `client`


  const value = useMemo(
    () => ({
      user,
      userType,
      shouldStoreInLocalStorage,
      login,
      logout,
      tokenValidation,
      userChange
    }),
    /*eslint-disable */
    [user, shouldStoreInLocalStorage]
    /*eslint-enable */
  );
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/