import Main from './page/main';
import Artist from './page/artist';
import ArtistDetail from './page/artistDetail';
import Archive from './page/archive';
import ArchiveDetail from './page/archiveDetail';
import Brand from './page/brand';
import Inspiration from './page/inspiration';
import Mypage from './page/mypage';
import Start from './page/start';
import QNA from './page/QNA';
import { ProtectedRoute } from './auth/protectedRoute';
import { AuthProvider } from './auth/auth';
import { ModalProvider } from './components/modal/modal';
import { PaginationProvider } from './customHook/usePagination';
import { BlockProvider } from './customHook/useBlock';
import { LoadingProvider } from './customHook/useLoading';
import { PopupProvider } from './components/popup/popup';
import Gnb from './components/GNB/gnb';
import Footer from './components/footer/footer';
import NiceCallBack from './page/niceCallBack';
import PageNotFound from './page/pageNotFound';
import EventBanner from './components/banner/eventBanner';
import { ScrollToTopProvider } from './customHook/userScrollToTop';
import { occupationList, archiveCategory } from './listsAndObjects';
import { stringToSnakeCase } from './converter';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import ErrorHandler from './errorHandler';
import InspirationDetail from './page/inspirationDetail';
import Search from './page/search';
import DeleteComplete from './page/deleteComplete';

import { useScript } from './customHook/useScript';
import { useEffect } from 'react';

const App = () => {
  const JS_KEY = process.env.REACT_APP_JS_KEY;
  const status = useScript("https://developers.kakao.com/sdk/js/kakao.js");
  // kakao sdk 초기화
  useEffect(() => {
    if (status === "ready" && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init(JS_KEY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);


  return (
    <BrowserRouter>
      <AuthProvider>
        <BlockProvider>
          <LoadingProvider>
            <ModalProvider>
              <PopupProvider>
                <ScrollToTopProvider>
                  <PaginationProvider>
                    <EventBanner/>
                    <Gnb />
                    <ErrorHandler>
                      <Routes>
                        <Route path="/" element={<Main />}></Route>
                        <Route path="/search" element={<Search />} />
                        <Route path="/artist" element={<Artist />}></Route>
                        <Route path={`/artist/:user_idx`} element={<ArtistDetail />}></Route>
                        {occupationList.map(occupation =>
                          <Route key={`${stringToSnakeCase(occupation)}`} path={`${stringToSnakeCase(occupation)}`} element={<Artist />}></Route>
                        )}
                        <Route path="/archive/:archive_idx" element={<ArchiveDetail />}></Route>
                        <Route path="/brand/:user_idx" element={<Brand />}></Route>

                        <Route path="/archive" element={<Archive />}></Route>
                        {Object.keys(archiveCategory).map(archiveCategory =>
                          <Route key={`${stringToSnakeCase(archiveCategory)}`} path={`${stringToSnakeCase(archiveCategory)}`} element={<Archive />}></Route>
                        )}
                        <Route path="/inspiration" element={<Inspiration />}></Route>
                        <Route path="/inspiration/:inspiration_idx" element={<InspirationDetail />}></Route>

                        <Route path="/start/*" element={<Start />}></Route>

                        <Route path="/mypage/*" element={<ProtectedRoute><Mypage /></ProtectedRoute>}></Route>

                        <Route path="/QNA" element={<QNA/>}></Route>

                        <Route path="/nice-callback" element={<NiceCallBack />}></Route>

                        <Route path="/deleteUser" element={<DeleteComplete />}></Route>


                        <Route path="*" element={<PageNotFound />}></Route>
                      </Routes>
                    </ErrorHandler>
                    <Footer />
                  </PaginationProvider>
                </ScrollToTopProvider>
              </PopupProvider>
            </ModalProvider>
          </LoadingProvider>
        </BlockProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App