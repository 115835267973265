
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useProjectAPI from '../../../api/useProjectAPI';
import styled from 'styled-components';
import CreativeList from '../../../components/creatives/creativeList';
import uploadIcon from '../../../images/upload.svg'

import { useModal } from '../../../components/modal/modal';
import { usePagination } from '../../../customHook/usePagination';
import { useBlock } from '../../../customHook/useBlock';
import { useAuth } from '../../../auth/auth';
import { objectKeyChangeFromXtoY } from "../../../converter"

export default function Project() {
  const { user, userType } = useAuth()
  const navigation = useNavigate()
  const { deleteProjectAPI } = useProjectAPI()
  const { deleteModal ,modalClose } = useModal()
  const [reload, setReload] = useState(Math.random())

  const { apiData, isDataChanged, setApiData, setApiDataWidthPreviousData } = usePagination()
  const { setBlock } = useBlock()
  const [cursor, setCursor] = useState(0)

  const url = userType === 'client' ? `/${userType}/list?client_idx=${user.data.user_idx}` : `/${userType}/detail?artists_idx=${user.data.user_idx}`

  useEffect(() => {
    setBlock(true)
    setApiData(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])

  useEffect(() => {
    if (!cursor) { return }
    let newUrl = url + `&cursor=${cursor}`
    setBlock(true)
    setApiDataWidthPreviousData(newUrl)
    // 이전데이터에 새 데이터 더해서 data return하는 함수
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor])

  const viewMore = (lastItem) => {
    setCursor(lastItem.portpolio_idx || lastItem.archive_idx)
  }

  const deleteButtonClicked = (project_idx) => {
    deleteModal(project_idx, handleDelete)
  }

  const handleDelete = async (idx) => {
    setBlock(true)
    let { result } = await deleteProjectAPI(idx, user)
    if (result) {
      modalClose()
      setReload(Math.random())
    }
    setBlock(false)
  }

  const routeChange = (project_idx) => {
    let path = userType === 'client' ? `/archive/${project_idx}` : `/artist/${user.data.user_idx}?projectID=${project_idx}`
    navigation(path)
  }

  return (
    <Div>
      <div className="header">내 작업 공유</div>
      <div className="subHeader">나의 작업을 널리 알려보세요</div>
      <div className="addportpolio">
        <div className="blueButton" onClick={() => { navigation('/mypage/project/add') }}>
          <img src={uploadIcon} alt="upload icon" />작품 등록하기
        </div>
      </div>
   
      <div className="underLine" style={{marginBottom:'0px'}} />
      <div className="portpolioList">
        {apiData && apiData.length > 0 ? 
          apiData.map(project => {
            let newProject
            userType === 'client' ? newProject = objectKeyChangeFromXtoY(project, 'archive', 'project') : newProject = objectKeyChangeFromXtoY(project, 'portpolio', 'project')
            return (
              <CreativeList key={newProject.project_idx} project={newProject} onClick={() => { routeChange(newProject.project_idx) }}>
                <div className="buttons">
                  <div className="whiteButton" onClick={() => { deleteButtonClicked(newProject.project_idx) }}>삭제</div>
                  <div className="blackButton" onClick={() => { (navigation(`/mypage/project/update`, { state: newProject })) }}>수정</div>
                </div>
              </CreativeList>
            )
          })
        :
        <div>
          <CreativeList onClick={() => {navigation('/mypage/project/add')}}></CreativeList>
        </div>
        }
        {apiData?.length % 6 === 0 && apiData?.length >= 6 && isDataChanged && 
          <div className="viewMore"style={{ marginTop: "37px" }} onClick={() => { viewMore(apiData[apiData.length - 1]) }}>더보기</div>
        }
      </div>
    </Div>
  )
}

const Div = styled.div`
  padding-right: 40px;
  .addportpolio{
    display: flex;
    justify-content: flex-end;
    margin-top: -44px;
    .blueButton{
      width: 136px;
      height: 44px;
      font-size: 14px;
      img{
        width:16px;
        height:16px;
        margin-right:4px;
      }
    }
  }

  .portpolioList{
    margin-bottom:101px;
    .buttons{
      display: flex;
      >div{
        width:64px;
        height:36px;
        margin-left:10px;
      }
      .whiteButton{
        font-size: 14px;
        font-weight: 500;
      }
      .blackButton{
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  
  @media (max-width: 900px) {
    padding: 0 18px;
    box-sizing:border-box;
  }

  @media (max-width: 720px) {
    .addportpolio{
      margin-top:0px;
      display: block;
      margin-top:26px;
    }
    .underLine{
      margin-top:22px !important;
    }
  }
`
