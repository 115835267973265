import { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useScrollLock } from "../../customHook/useScrollLock";
import styled from "styled-components";
import MatchingPopup from './matchingPopup'
import closeButton from '../../images/closeButton.svg'

const PopupContext = createContext();


export const PopupProvider = ({ children }) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  const [isShow, setShow] = useState(false)
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState('')

  useEffect(() => {
    let timeoutId;
    visible ? setShow(true) : timeoutId = setTimeout(() => setShow(false), 250);
    return () => { if (timeoutId !== undefined) { clearTimeout(timeoutId); } };
  }, [visible]);

  useEffect(() => {
    isShow ? lockScroll() : unlockScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  const PopupShow = useCallback((artist) => {
    setVisible(true)
    setContent(<MatchingPopup artist={artist} />)
  }, []);

  const PopupClose = useCallback(() => {
    setVisible(false)
  }, []);

  const value = useMemo(
    () => ({ PopupShow, PopupClose }),
    /*eslint-disable */
    []
    /*eslint-enable */
  );

  return (
    <PopupContext.Provider value={value}>
      {isShow &&
        <Div visible={visible}>
          <div
            className="popupContent"
            onClick={e => {
              // popup content click시 닫지 않도록.
              e.stopPropagation();
            }}
          >
            {content}
            <img className="popupClose" alt="close" src={closeButton} onClick={() => {PopupClose()}}/>
          </div>
        </Div>
      }
      {children}
    </PopupContext.Provider>
  )
}

export const usePopup = () => {
  return useContext(PopupContext);
};

const Div = styled.div`

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  animation: ${props => props.visible ? 'fadeIn' : 'fadeOut'} 0.25s ease-out;

  .popupContent {
    position: relative;
  }

  .popupClose {
    fill: white;
    position: absolute;
    top: 18px;
    right: 20px;
    width: 18px;
    height: 18px;
    z-index:10;
    cursor:pointer;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

`