import userImage from '../../images/userImage.svg'
import SelectFile from '../../images/selectFile.svg'
import { useForm } from "react-hook-form";
import { useState } from "react";
import useAPI from "../../api/useAPI";
import { useAuth } from "../../auth/auth";
import Logo from "../../images/opacityLogo.png"
import { useModal } from "./modal";
import { useBlock } from '../../customHook/useBlock';
import styled from 'styled-components';
import { useWindowSize } from '../../customHook/useWindowSize';

export default function ProfileChangeModal() {

  const { putAPI, uploadImageAPI } = useAPI()
  const { innerWidth, innerHeight } = useWindowSize()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { user, userChange, shouldStoreInLocalStorage } = useAuth()
  const [user_profile, setUser_profile] = useState(user.user_profile ? user.user_profile : user.data.user_privilege === 1 ? userImage : Logo)
  const [selected, setSelected] = useState('')
  const { modalShow, modalClose } = useModal()
  const { setBlock } = useBlock()
  const onSubmit = async (submitData) => {
    const uploadResponse = await uploadImageAPI(submitData.img_url[0]) // API 내부에 setBlock 있음
    if (uploadResponse.result === 'success') {
      let url = `/user/profile`
      let body = {"img_url":[uploadResponse.data.img_url]}
      let { data, result } = await putAPI(url, user, body)
      if (result === "success") {
        userChange({ ...user, user_profile: data.user_profile }, shouldStoreInLocalStorage) // profile 저장후 user Data에 반영
        setBlock(false)
      }
      modalClose()
    } else {
      modalShow({ message: '이미지 업로드에 실패하였습니다. 관리자에게 문의해주세요', actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
      setBlock(false)
    }
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : user.data.user_privilege === 1 ? userImage : Logo
    setSelected(e.target.files[0]?.name)
    setUser_profile(selectedFile)
  }


  return (
    <Form id='profileChangeForm' onSubmit={handleSubmit(onSubmit)} width={innerWidth} height={innerHeight}>
      {user.data.user_privilege === 1 ? 
       <div className="userImage">
          <img src={user_profile} alt="user_image" />
      </div>
      :
      <div className="brandLogo">
        <img src={user_profile} alt="client logo"/>
      </div>
      }
      <div className="title">
        {user.data.user_privilege === 1 ? "프로필 사진 등록" : "브랜드 로고 등록"}
        <span className="description">5MB 이하 </span>
      </div>
      <div className="profileInput">
        <label htmlFor="profileImg">
          <img className="selectFileIcon" src={SelectFile} alt="file icon" />
          {selected ? selected : "이미지를 선택해 주세요"}
        </label>
        <input id="profileImg" type="file" accept="image/*, .pdf" {...register("img_url", {
          required: true, 
          validate: { lessThan5MB: (files) => files[0]?.size < 5000000 },
          onChange: (e) => { handleFileChange(e) },
        })} />
        {errors.img_url?.type === 'required' && <div className="errorMessage">이미지를 업로드해주세요.</div>}
        {errors.img_url?.type === 'lessThan5MB' && <div className="errorMessage"> "5MB 이하의 이미지를 업로드해주세요."</div>}
      </div>
      <div></div>
      <button className="blackButton" form='profileChangeForm' type="submit">수정하기</button>
    </Form>
  )
}


const Form = styled.form`
  max-width:480px;
  max-height:430px;
  width: calc(${props => props.width}px * 0.9);
  height: calc(${props => props.height}px * 0.9);
  overflow: auto;
  background-color: #fff;
  border-radius: 12px;

  .userImage {
    text-align:center;
    img{
      width: 175px;
      height: 175px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: 50px;
      object-fit: cover;
    }
  }
  .brandLogo{
    margin:0 auto;
    width:250px;
    img{
      box-sizing: border-box;
      width: 100%;
      padding:10px;
      height:100px;
      margin-top: 100px;
      margin-bottom:25px;
      border: 1px solid #D9D9D9;
      object-fit:contain;
    }
  }

  .title {
    font-family: Pretendard;
    text-align: left;
    max-width:490px;
    width:80%;
    margin: 15px auto;
  }

  .description {
    padding-left: 15px;
    font-size: 14px;
    color: #999999;
  }

  .profileInput{
    height: 46px;
    margin: 0 auto;
    width: 80%;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px #dcdcdc;
    background-color: #fff;

    > label{
      height: 100%;
      display: block;
      line-height: 46px;
      text-align: left;
      padding: 0 15px;
      color:#afafaf;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      cursor:pointer;
      > img{
        margin-right:15px;
      }
    }
    input{
      display:none;
    }
    .selectFileIcon {
      top: 25px;
      left: 18px;
    }
    .errorMessage{
      color:#EB5757; // 경고 메세지
      padding-top:4px;
      font-size:14px;
    }
  }
  .blackButton{
    margin:28px auto 20px !important;
  }

  @media (max-width: 420px) {
    .userImage {
      img{
        width: 125px;
        height: 125px;
        margin-top:72px;
        margin-bottom:28px;
      }
    }
  }
`