
import CopyLink from '../../images/copyLink.png';
import KakaoShare from '../../images/kakaoShare.png';
import FbShare from '../../images/fbShare.png';
import TelegramShare from '../../images/telegramShare.png';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TelegramShareButton,
} from "react-share";

import { useModal } from './modal';
import styled from "styled-components";

// login, add wish, my board
export default function ShareModal({ profileData, type }) {

  const { modalShow, modalClose } = useModal()
  const currentUrl = window.location.href;

  const handleKakaoButton = (profileData) => {
    window.Kakao.Share.createDefaultButton({
      container: '#kakaotalk-sharing-btn',
      objectType: 'feed',
      content: {
        title: type === "artist" ? `${profileData.user_name}님의 포트폴리오` : "archive" ? `${profileData.archive_brand}님의 아카이브` : `${profileData.company_name}님의 아카이브`,
        description: profileData.user_myself || '',
        imageUrl: profileData.user_profile,
        link: {
          mobileWebUrl: currentUrl,
          webUrl: currentUrl,
        },
      },
      social: {
        viewCount: profileData.view_cnt,
        likeCount: profileData.like_cnt,
        subscriberCount: profileData.wishlist_cnt || profileData.moodboard_cnt,
      }, // brand에서 없음
      buttons: [
        {
          title: '방문하기',
          link: {
            mobileWebUrl: currentUrl,
            webUrl: currentUrl,
          },
        },
      ],
    });
  }

  return (
    <Div>
      <div className="title">공유하기</div>
      <div className="link">
        <div id="kakaotalk-sharing-btn" onClick={() => { handleKakaoButton(profileData) }}>
          <div className="linkButton">
            <img src={KakaoShare} alt="kakao share" />
            <div>
              카카오톡
            </div>
          </div>
        </div>
        <FacebookShareButton url={currentUrl}>
          <div className="linkButton">
            <img src={FbShare} alt="facebook share" />
            <div>
              페이스북
            </div>
          </div>
        </FacebookShareButton>

        <TelegramShareButton url={currentUrl}>
          <div className="linkButton">
            <img src={TelegramShare} alt="telegram share" />
            <div>
              텔레그렘
            </div>
          </div>
        </TelegramShareButton>
        <CopyToClipboard
          text={currentUrl}
          onCopy={() => modalShow({ message: `링크가 복사되었습니다.`, actionButton: <div onClick={() => { modalClose() }} className="blackButton">닫기</div> })}
        >
          <button>
            <div className="linkButton">
              <img src={CopyLink} alt="copy link" />
              <div>
                링크복사
              </div>
            </div>
          </button>
        </CopyToClipboard>
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width: 500px;
  height: 224px;
  border-radius: 12px;
  padding-top:48px;
  box-sizing:border-box;
  .title{
    margin: 0 auto 23px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: bold;
  }

  .link{
    display: flex;
    justify-content:center;
    >button{
      background: white;
    }
    .linkButton{
      margin:0 10px;
      cursor:pointer;
      >img{
        width:52px;
        height:52px;
      }
      >div{
        font-family: Pretendard;
        font-size: 12px;
        font-weight: 500;
        color: #353535;
      }
    }
  }
  @media (max-width: 720px) {
    width: 324px;
    padding-top: 38px;
    height: 178px;
    .title{
      margin: 0 auto 12px;
      font-size: 17px;
    }
  }
`