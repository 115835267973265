import styled from 'styled-components';
import ReactSelect, { components } from "react-select";
import arrow_down from "../../images/arrow_down.svg"

export default function Select(props) {

  // option box box-shadow and border
  const customStyles = {
    container: (base) => ({
      ...base,
      height: '100%',
      borderRadius: "4px",
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: isSelected ? '#000000' : isFocused ? '#5d5d5d' : "#5d5d5d",
      // color: isSelected ? '#000000' : "5d5d5d",
      fontSize: "15px",
      fontWeight: isSelected? "600" : "400",
      paddingLeft:'14px',
      backgroundColor: isFocused ? "#f5f5f5" : undefined,
      height:"48px",
      lineHeight:"36px",
      cursor:"pointer",
      ':active': {
        ...styles[':active'],
        backgroundColor:'rgba(0,0,0,0.2)'
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
      textAlign: 'left',
      paddingTop:"10px",
      boxShadow:"0 0 8px 0 rgba(0, 0, 0, 0.1)",
      paddingBottom:"10px",
      border: "solid 1px #edeeef",
      zIndex:"10",
    }),

    menuList: (provided) => ({
      ...provided,
      height:`${props.menuListHeight || 'inherit'}`,
    }),


    control: (base,{isFocused}) => ({
      ...base,
      backgroundColor: 'white',
      borderRadius: '4px',
      textAlign: 'left',
      height: '100%',
      paddingLeft:'6px',
      cursor:"pointer",
      // This line disable the blue border
      border: isFocused? `solid 1px ${props.borderColorFocus || '#black'}`:`solid 1px ${props.borderColor || '#e6e6e6'}`,
      boxShadow: 'none',
      // This line disable the blue border
      "&:hover": {
        backgroundColor: "#f5f5f5",
      }
    }),

    valueContainer: (base) => ({
      ...base,
    }),


    placeholder:(base) => ({
      ...base,
      fontFamily: "Pretendard",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "-0.01px",
      color: "#5d5d5d",
    }),

    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
      paddingRight:'12px',
      paddingLeft:'12px'
    }),

    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Pretendard",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "-0.01px",
      color: "#5d5d5d",
    }),
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrow_down} alt="arrow"/>
      </components.DropdownIndicator>
    );
  };

  return (
    <Div>
      <ReactSelect
        {...props}
        styles={customStyles}
        isSearchable={false}
        // defaultValue={props.defaultValue || props.options[0]}
        defaultValue={props.defaultValue}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
      />
    </Div>
  )
}

const Div = styled.div`
  height:100%;
  input{
    height:0px !important; // projectForm input 등에서 설정된 height:50px 때문에 목록글자가 중간에 오지않음
  }
  

  @media (max-width: 372px) { // sort의 이름순, 등록순 글자 라인이 깨지는 point
    *{font-size:0.813rem}
  }
`