
import artistBanner from '../../images/artistBanner.png'
import archiveBanner from '../../images/archiveBanner.png'
import QNABanner from '../../images/QNABanner.png'
import QNABannerForMobile from '../../images/QNABannerForMobile.png'
import { useWindowSize } from '../../customHook/useWindowSize'

import styled from 'styled-components'

function Banner({location, style}) {

  const { innerWidth } = useWindowSize()

  let head;
  let description;
  let backgroundImage;
  switch (location) {

    case 'artist':
      head = '아티스트'
      description =  innerWidth > 420 ? '아티스트들의 개성과 독창성이 반영된 다양한 장르의 포트폴리오를 만나보세요' : <>아티스트들의 개성과 독창성이 반영된<br/> 다양한 장르의 포트폴리오를 만나보세요</>
      backgroundImage = artistBanner
      break;

    case 'archive':
      head = '아카이브'
      description = innerWidth > 500 ? "다양한 카테고리 브랜드들의 아이덴티티와 메세지가 담긴 광고 콘텐츠 아카이브를 만나보세요" : <>다양한 카테고리 브랜드들의 아이덴티티와 메세지가 담긴<br/> 광고 콘텐츠 아카이브를 만나보세요</>
      backgroundImage = archiveBanner
      break;

    case 'QNA':
      head = '자주 묻는 질문 Q & A'
      description = '더 궁금한 사항은 1:1문의를 이용해주세요'
      backgroundImage = innerWidth > 1200 ?  QNABanner : QNABannerForMobile
      break;
   
    default:
      head = ''
      description = ''
      backgroundImage = artistBanner
  }

  return (
    <Div style={{...style, backgroundImage: `url(${backgroundImage})`}}>
      <div className="banner">
        <div className="filter">
          <div>
            <h2>
              {head}
            </h2>
            <span>
              {description}
            </span>
          </div>
        </div>
      </div>
    </Div>
    
  );
}

export default Banner;

const Div = styled.div`
  width: 100%;
  height: 220px;
  min-width:324px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .banner{
    width: 100%;
    height: 100%;
    .filter{
      width:100%;
      height:100%;
      backdrop-filter: blur(4px);
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      >div{
        max-width:1400px;
        width: 90%;
        margin: 0 auto;
        > h2{
          margin:0 0 18px 0;
          font-family: Pretendard;
          font-size: 28px;
          font-weight: bold;
          color: #fff;
        }
        > span{
          font-family: Pretendard;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.04px;
          color: #fff;
        }
      }
    }
  }

  @media (max-width: 720px) {
    height: 180px;
    .banner{
      height: 180px;
      .filter{
        >div{
          > h2{
            font-size: 22px;
          }
          > span{
            font-family: Pretendard;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .banner{
      .filter{
        >div{
          > span{
            display:inline-block;
            max-width:276px;
          }
        }
      }
    }
  }
`