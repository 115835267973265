
import { useModal } from './modal';
import UserImage from '../../images/userImage.svg'
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import { useWindowSize } from '../../customHook/useWindowSize';

// login, add wish, my board
export default function ProjectMemberModal({ member }) {

  const navigate = useNavigate()
  const { modalClose } = useModal()
  const { innerWidth, innerHeight } = useWindowSize()


  const routeChange = (member_idx) => {
    if(!member_idx) return
    navigate(`/artist/${member_idx}`)
    modalClose()
  }

  return (
    <Div width={innerWidth} height={innerHeight}>
      {/* <div onClick={()=>{modalClose()}}>X</div> */}
      <div className="title">광고 콘텐츠 제작 <span>참여자</span></div>
      <div>
        {member.map((m)=>{
          return(
            <div key={uuidv4()} className="member" onClick={()=>{routeChange(m.member_idx)}} style={m.member_idx ? {cursor:'pointer'} : {}}>
              <img src={m.user_profile || UserImage} alt="profile"/>
              <div className="name">{m.member_name}</div>
              <div className="job">{m.job_detail}</div>
            </div>
          )
        })}
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  max-width: 500px;
  max-height: 466px;
  width:${props => props.width*0.9}px;
  height:${props => props.height*0.9}px;
  padding: 22px 0 0;
  border-radius: 12px;
  box-sizing:border-box;
  overflow-y: auto;
  .title, .title > span{
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 500;
  }
  .title{
    margin: 0 auto 22px;
    >span{
      font-weight: 700;
    }
  }
  .member{
    border-top: solid 1px #ebebeb;
    display: flex;
    align-items:center;
    padding:16px 22px;
    > img{
      display: block;
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius:50%;
    }
    .name{
      font-family: Pretendard;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.05px;
      margin-left:14px;
      margin-right:12px;
    }
    .job{
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      color: #999;
    }
  }
  .member:first-child{
    border-top:none;
  }

  @media (max-width: 500px) {
    width:${props => props.width}px;
    height:${props => props.height}px;
    max-height: initial;
    border-radius: 0px;
    padding-top:0px;
    
    .title, .title > span{
      font-size: 17px;
    }
    .title{
      margin: 0 auto;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
      border-bottom:1px solid #f7f7f7;
      padding:18px 0;
    }
  }
`