import { useState, useEffect } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form";
import { emailRegExp, passwordRegExp } from '../../../components/regex';
import useAPI from '../../../api/useAPI';

import { useNavigate } from "react-router-dom"
import { useModal } from "../../../components/modal/modal";
import { useBlock } from "../../../customHook/useBlock";
export default function FindPW({ children }) {

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const { getAPI, putAPI } = useAPI()

  const { modalShow, modalClose } = useModal()
  const { setBlock } = useBlock()
  const [isVerrfied, setVerrified] = useState(false)
  const navigate = useNavigate()
  const [token_version_id, setToken_version_id] = useState('')

  const domain_name = window.location.protocol + "//" + window.location.host;

  useEffect(() => {
    function receiveMessage(e) {
      if (e.origin !== domain_name || e.data.path !== '/nice-callback') return
      // a.value = e.data.check; // 본인인증 완료시 완료됬다는 check 넘길 수 있음
      if (e.data.check) {
        setToken_version_id(e.data.token)
        setVerrified(true)
      } else {
        modalShow({ message: "본인인증에 실패하였습니다.", actionButton: <div className="blackButton" onClick={() => { onClickCertify(); modalClose(); }}>다시 시도하기</div> })
      }
    }
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const findPWSubmit = async (data) => {
    setBlock(true)
    let url = `/jwt/check-id?user_id=${data.user_id}`
    const { result } = await getAPI(url)
    if (result) {
      setBlock(false)
      modalShow({ message: '이메일을 다시 확인해주세요.', actionButton: <div className="blackButton" onClick={() => modalClose()}>다시 시도하기</div> })
    } else {
      setBlock(false)
      onClickCertify();
    }
  }

  const changePWSubmit = async (data) => {
    delete data.user_pw_check;
    data.token_version_id = token_version_id
    setBlock(true)

    let url = `/jwt/find-passwd`
    const { result } = await putAPI(url, false, data)
    if (result === 'success') {
      setBlock(false)
      modalShow({ message: '비밀번호가 변경되었습니다.', submessage:'변경된 비밀번호로 다시 로그인해주세요.', actionButton: <div className="blackButton" onClick={() => { modalClose(); navigate('/start', { state: { from: '/' } });    }}>로그인 하기</div> })
    }
  }

  const onClickCertify = async () => {
    let url = `/user/nice?domain_name=${domain_name}`
    const { data } = await getAPI(url)
    const { token_version_id, enc_data, integrity_value } = data
    window.open('', 'popup', "width=600,height=400,left=200,top=200");
    document.niceForm.target = "popup";
    document.niceForm.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
    document.niceForm.token_version_id.value = token_version_id;
    document.niceForm.enc_data.value = enc_data;
    document.niceForm.integrity_value.value = integrity_value;
    document.niceForm.submit();
  }

  const getError = ()=>{
    if(errors.user_id){
      switch (errors.user_id.type) {
        case 'pattern': return <span>올바른 이메일 형식을 입력해주세요.</span>
        case 'maxLength': return <span>60자 이내로 입력해주세요.</span>
        case 'required': return <span>아이디(이메일)를 입력해주세요.</span>
        default: return <></>
      }
    } 
    else if (errors.user_pw) {
      switch (errors.user_pw.type) {
        case 'pattern': return <span>영문자 및 숫자의 조합으로 6 ~ 20자로 입력해주세요.</span>
        case 'maxLength': return <span>60자 이내로 입력해주세요.</span>
        case 'required': return <span>새 비밀번호를 입력해주세요.</span>
        default: return <></>
      }
    } 
    else if (errors.user_pw_check){
      switch (errors.user_pw_check.type) {
        case 'validate': return <span>비밀번호가 일치하지 않습니다.</span>
        case 'required': return <span>새 비밀번호를 확인해주세요.</span>
        default: return <></>
      }
    } else {
      return
    }
  }

  return (
    <Div>
      {!isVerrfied ?
        <>
          <div className="content">
            가입한 아이디를 입력해주세요.<br />
            휴대폰 본인인증을 통해
            비밀번호를 변경합니다.
          </div>
          <form name="niceForm" id="niceForm" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb">
            <input type="hidden" id="m" name="m" value="service" />
            <input type="hidden" id="token_version_id" name="token_version_id" value="" />
            <input type="hidden" id="enc_data" name="enc_data" />
            <input type="hidden" id="integrity_value" name="integrity_value" />
          </form>
          <form id="findPWform" onSubmit={handleSubmit(findPWSubmit)}>
            <input type="email" placeholder="아이디(이메일)" className="id" {...register("user_id", { required: true, pattern: { value: emailRegExp }, maxLength: 60 })} />
            <div className="error">{getError()}</div>
          </form>
          <button className="blackButton" form='findPWform' type="submit">휴대폰 인증하기</button>
          {children}
        </>
        :
        <>
          <div className="content">
            비밀번호를 변경해주세요.
          </div>
          <form id="changePWform" onSubmit={handleSubmit(changePWSubmit)}>
            <div>
              <input type="password" autoComplete="off" placeholder="새 비밀번호 입력" style={{ marginBottom: '8px' }} {...register("user_pw", {required: true, pattern: { value: passwordRegExp }, maxLength: 60})}/>
            </div>
            <div>
              <input type="password" autoComplete="off" placeholder="새 비밀번호 확인" {...register("user_pw_check",{required: true, validate: (user_pw_check) => { const { user_pw } = getValues(); return user_pw === user_pw_check }, maxLength: 60})}/>
              <div className="error">{getError()}</div>
            </div>
          </form>
          <button className="blackButton" form='changePWform' type="submit">비밀번호 변경하기</button>
          <div className="underLine"></div>
        </>
      }
    </Div>
  )
}

const Div = styled.div`
  input{
    margin-bottom:36px;
  }
  .error{
    > span{ // 경고 메세지
     color:#EB5757;
     display: block;
     height:20px;
     font-size: 14px;
     margin:-34px 0 14px;
     text-align:left;
   }
  }
`