export const GMTtoKSTconverter= (time) => {
  let gmtTime =  new Date(time)
  return new Date(gmtTime.getTime())
}

export const getKeyByValue = (obj, value) => 
  Object.keys(obj).find(key => obj[key] === value);

export const stringToSnakeCase = (word) => {
  return word?.replace(' ', '_').toLowerCase()
}

export const toSnakeCase = (object) => {
  return Object.keys(object)
    .map((key) => {
      return { before: key, after: stringToSnakeCase(key) }
    })
    .reduce((cur, { before, after }) => { return Object.assign(cur, { [after]: object[before] }) }, {});
}

export const objectKeyChangeFromXtoY = (object, presentKey, newKey) =>{
  return Object.keys(object)
  .map((key) => {
    return {before: key , after: key.replace(presentKey, newKey)}
  })
  .reduce((cur, {before, after}) => { return Object.assign(cur, { [after]: object[before] })}, {});
}

export const convertKeysToLowercase = (obj) => {
  const result = {};
  
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key.toLowerCase()] = obj[key];
    }
  }
  
  return result;
}