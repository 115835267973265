import { Link } from "react-router-dom";
import MainLogo from '../../images/logo.svg';
import styled from 'styled-components'
import { useScrollToTop } from "../../customHook/userScrollToTop";

function Logo() {

  const { scrollToTop } = useScrollToTop()
  return (
    <Div>
      <Link to="/" onClick={()=>{scrollToTop()}}><img src={MainLogo} alt="logo"/></Link>
    </Div>
  );
}

export default Logo;

const Div = styled.div`
  display:flex;
  align-items:center;
  > a{
    padding-right:46px;
    img{
      display: block;  // image에 잡힌 Padding 5px 없애기 위해
    }
  }
  @media (max-width: 1250px) {
    > a{
      img{
        height: 25px;
      }
    }
  }
  @media (max-width: 480px) {
    > a{
      img{
        height: inherit;
      }
    }
  }
`
