import styled from "styled-components";

// login, add wish, my board
export default function ModalContent({modalContent}){
  return(
    <Div>
      <div className="message">{modalContent.message}</div>
      <div className="submessage">{modalContent.submessage}</div>
      <div>{modalContent.others}</div> {/* 전화문의등 */}
      <div className="button">{modalContent.actionButton}</div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  width: 400px;
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top:62px;

  .button {
    margin:34px 0 24px;
  }

  .numberInfoModalTitle{ // mypage matching
    font-size: 32px !important;
    margin-top: -8px;
  }

  .numberInfoModalMessage{ 
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
    color: #424242;
    margin: 20px 0 24px;
  }
  .numberInfoModalNumber{
    width: 300px;
    height: 54px;
    border-radius: 4px;
    background-color: #f2f2f2;
    display: flex;
    justify-content:center;
    align-items:center;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: normal;
    color: #1f1f1f;
    margin-bottom:-8px;
  }


  .flexContainer {
    // 예 or 아니요 버튼. confirmEmailDuplicationModal 에서 사용
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    bottom: 15px;

    div {
      width: 46%;
      height: 60px;
      line-height: 60px;
      background: #222222;
      color: #FFFFFF;
    }
  }
  @media (max-width: 720px) {
    width: 324px;
    padding-top: 50px;
    .button{
      margin: 25px 0 20px;
    }
    .numberInfoModalNumber{
      width: 280px;
    }
  }
`