import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ARROW_left from '../../images/arrow_left_modal.svg'
import ARROW_right from '../../images/arrow_right_modal.svg'
import styled from 'styled-components'
import { useWindowSize } from '../../customHook/useWindowSize'
import { useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';

export default function CarouselModal({ images, index, setCloseButtonStyle }) {
	const { innerWidth, innerHeight } = useWindowSize()

	useEffect(()=>{
		let style;
		innerWidth > 420 ?
		style = {
			top: "0",
    	right: "-26px",
			filter:"invert(85%) sepia(89%) saturate(3%) hue-rotate(183deg) brightness(110%) contrast(97%)",
		}
		:
		style = {
			top: "10px",
    	right: "10px",
			filter:"invert(85%) sepia(89%) saturate(3%) hue-rotate(183deg) brightness(110%) contrast(97%)",
		}
		setCloseButtonStyle(style)
		return () => {setCloseButtonStyle({})}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[innerWidth])

	function NextArrow(props) {
		const { className, style, onClick } = props;
		return (
			<div
				className={className}
				style={{ ...style, display: "block" }}
				onClick={onClick}
			>
				<img className="arrowImage" src={ARROW_right} alt="arrow_right" />
			</div>
		);
	}

	function PrevArrow(props) {
		const { className, style, onClick } = props;
		return (
			<div
				className={className}
				style={{ ...style, display: "block" }}
				onClick={onClick}
			>
				<img className="arrowImage" src={ARROW_left} alt="arrow_left" />
			</div>
		);
	}

	

	const settings = {
		className: "center",
		infinite: true,
		slidesToShow: 1,
		arrows: true,
		swipeToSlide: true,
		centerPadding: '0px',
		initialSlide: index,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		dots: true,
		appendDots: (dots) => (
			<div
				style={{
					width: '100%',
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<ul> {dots} </ul>
			</div>
		),
		dotsClass: 'dots_custom',
		responsive: [
			{
				breakpoint: 421,
				settings: {
					arrows: false,
				}
			},
		]
	};

	return (
		<Div width={innerWidth} height={innerHeight}>
			<Slider {...settings}>
				{images.map((image) => {
					if (image.includes("https://d3azijzmx402dn.cloudfront.net/")) {
						return (
							<div className="projectsImages" key={uuidv4()}>
								<img src={image} alt="projectsImages" />
							</div>
						)
					} else {
						return (
							<div className="projectsVideo" key={uuidv4()}>
								<iframe
									width="100%"
									src={`https://www.youtube.com/embed/${image}`}
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									title="Embedded youtube"
								/>
							</div>
						)
					}
				})}
			</Slider>
		</Div>
	)
}

const Div = styled.div`
  width:${props => props.width * 0.8 + 'px'};
  height:${props => props.height * 0.8 + 'px'};
  max-width:1080px;
  max-height:790px;
	background-color: #191919;
	.slick-slide{
		div:focus-visible{
			outline: 0;
		}
	}
  .projectsImages{
    > img{
      cursor:default;
      object-fit:contain;
      margin:0 auto;
      width:100%;
      height:${props => props.height * 0.8 + 'px'};
      max-width:1080px;
      max-height:790px;
    }
  }
  .projectsVideo{
    display: flex !important;
    justify-content: center;
    align-items: center;
    max-height:790px;
    height:${props => props.height * 0.8 + 'px'};

    iframe{
      border:0;
      aspect-ratio: 1.7 / 1;
    }
  }
	.slick-arrow{
		width:50px;
		height:70px;
	}
	.slick-prev{
		left: 0px;
		z-index:1;
	}
	.slick-next{
		right: 0px;
	}
	.arrowImage{
		width:100%;
		height:100%;
	}
	@media (max-width: 900px) {
		.slick-arrow{
			width:30px;
			height:50px;
		}
		.slick-prev{
			left: -30px;
		}
		.slick-next{
			right: -30px;
		}
	}

	@media (max-width: 420px) {
		width:${props => props.width * 1 + 'px'};
		height:${props => props.height * 1 + 'px'};
		max-height:inherit;
		background-color:#191919;
		.projectsImages{
    	> img{
				height:initial;
    	}
  	}
  	.projectsVideo{
			height:initial;
		}

		.slick-slider{
			height:${props => props.height * 1 + 'px'};
			padding-top:100px;
			.slick-list{
				height:calc(100% - 100px);

				.slick-track{
					height:100%;
					.slick-slide{
						display: flex;
						align-items: center;
						> div{
							width:100%; // youtube video
						}
					}
				}
			}
			.dots_custom{
				align-items: end;
				height: 80px;
				margin-bottom: 20px;
			}
		}
	}
`