import { createContext, useContext, useMemo, useState } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false)

  const value = useMemo(
    () => ({ isLoading, setLoading }),
    /*eslint-disable */
    [isLoading]
    /*eslint-enable */
  );

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  )
};

export const useLoading = () => {
  return useContext(LoadingContext);
};