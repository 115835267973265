
import styled from "styled-components"
import { GMTtoKSTconverter } from "../../../converter";



export default function MatchingDescrpition({ matchingData, children }) {
  let matchingDate = GMTtoKSTconverter(matchingData?.matching_start)

  return (
    <>
      {matchingData &&
        <Div>
          <div className="descriptionBlock">
            <div className="header">캠페인</div>
            <div className="content">
              <span className="title">캠페인 카테고리</span>
              <span className="description">{matchingData.matching_category}</span>
            </div>
            <div className="content">
              <span className="title">캠페인 명</span>
              <span className="description">{matchingData.matching_name}</span>
            </div>
            <div className="content">
              <span className="title">회사 홈페이지 주소</span>
              <span className="description">{matchingData.matching_homepage}</span>
            </div>
          </div>

          <div className="descriptionBlock">
            <div className="header">촬영</div>
            <div className="content">
              <span className="title">촬영 예상 일정</span>
              <span className="description">{`${matchingDate.getFullYear()}.${matchingDate.getMonth() + 1}.${matchingDate.getDate()}`}</span>
            </div>
            <div className="content">
              <span className="title">촬영 예상 시간</span>
              <span className="description">{matchingData.matching_hour}시간</span>
            </div>
            <div className="content">
              <span className="title">촬영 예상 장소</span>
              <span className="description">{`${matchingData.matching_address} ${matchingData.matching_address_detail}`}</span>
            </div>
            <div className="content">
              <span className="title">영상 촬영 진행 유무</span>
              <span className="description">{matchingData.matching_filming ? "예" : "아니오"}</span>
            </div>
          </div>
 
          <div className="descriptionBlock">
            <div className="header">콘텐츠</div>
            <div className="content">
              <span className="title">저작물 사용국가</span>
              <span className="description">{matchingData.matching_scope ? "글로벌" : "대한민국"}</span>
            </div>
            <div className="content">
              <span className="title">저작물 사용기간</span>
              <span className="description">{matchingData.matching_range}개월</span>
            </div>
            <div className="content">
              <span className="title">저작물 매체 사용범위</span>
              <span className="description">{JSON.parse(matchingData.matching_type).toString()}</span>
            </div>
          </div>
     
          <div className="descriptionBlock">
            <div className="header">사용 범위</div>
            <div className="content">
              <span className="title">스폰서 광고 사용여부</span>
              <span className="description">{matchingData.matching_sponser ? "예" : "아니오"}</span>
            </div>

            <div className="content">
              <span className="title">홈쇼핑 오픈마켓 및<br/> 온라인 배너 사용유무</span>
              <span className="description">{matchingData.matching_banner ? "예" : "아니오"}</span>
            </div>

            <div className="content">
              <span className="title">매장 POP 또는 와이드 사용 여부</span>
              <span className="description">{matchingData.matching_pop ? "예" : "아니오"}</span>
            </div>
            <div className="content">
              <span className="title">인쇄룩북, 고객용 <br/>포스트카드 제작 사용 여부</span>
              <span className="description">{matchingData.matching_card ? "예" : "아니오"}</span>
            </div>
            <div className="content">
              <span className="title">옥외광고 사용 여부</span>
              <span className="description">{matchingData.matching_outdoor ? "예" : "아니오"}</span>
            </div>
          </div>

          <div className="descriptionBlock significant">
            <div className="header">특이사항</div>           
            <div className="content">
              <span className="description">{matchingData.matching_detail ? matchingData.matching_detail : "없음"}</span>
            </div>
          </div>
          {children}
        </Div>
      }
    </>
  )
}

const Div = styled.div`
  text-align:left;
  width:100%;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  margin-top:34px;
  margin-bottom: 26px;

  .descriptionBlock{
    border-bottom: solid 1px #e9e9e9;
    margin-bottom:48px;
    .header{
      font-family: Pretendard;
      font-size: 18px;
      font-weight: bold;
      padding-bottom:19px;
      border-bottom: solid 2px #1f1f1f;
    }
    
    .content{
      padding:5px 0;
      span{
        display: inline-block;
        padding:14px 0;
      }
      .title{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: bold;
        color: #1f1f1f;
        width:280px;
        > br{
          display: none;
        }
      }
      .description{
        font-family: Pretendard;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.32px;
        color: #353535;
      }
    }
  }

  @media (max-width: 720px) {
    border-top: 1px solid #e9e9e9;
    border-radius: 0;
    padding: 11px 15px 0;
    margin-bottom:0px;
    .descriptionBlock{
      .content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
          width:inherit;
        }
      }
      &.significant{
        margin-bottom:16px;
      }
    }
   
  }
  @media (max-width: 520px) {
    .descriptionBlock{
      .content{
        .title{
          > br{
            display: block;
          }
        }
      }
    }
  }
`