
import styled from 'styled-components';

export default function ProgressBar({phase}) {
  return (
    <Div phase={phase}/>
  )
}

const Div = styled.div`
  width:100%;
  max-width:360px;
  height:4px;
  background-color: #f4f4f4;
  margin:32px 0 24px;
  transition:0.2s;
  &:before{
    content:'';
    display: block;
    background-color: black;
    width:${props => props.phase * 25}%;
    height:4px;
    transition:0.2s;
  }
`