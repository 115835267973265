import check from "../../images/blueCheck.svg"
import styled from "styled-components";
import { useModal } from "./modal";
import { useWindowSize } from '../../customHook/useWindowSize';

export default function AddWishAndMoodbardModal({ message, subMessage, actionButton}){

  const { modalClose } = useModal()
  const { innerWidth, innerHeight } = useWindowSize()

  return(
    <Div width={innerWidth} height={innerHeight}>
      <div className="check">
        <img src={check} alt="check_icon"/>
      </div>
      <div className="message">{message}</div>
      <div className="submessage">{subMessage}</div>
      <div className="buttons">
        <div className="whiteButton" onClick={()=>{modalClose()}}>확인</div>
        <div className="blackButton">{actionButton}</div>
      </div>
    </Div>
  )
}

const Div = styled.div`
  background-color: white;
  max-width:500px;
  width: calc(${props => props.width}px * 0.9);
  height: calc(${props => props.height}px * 0.9);
  max-height: 268px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content:center;
  align-items: center;
  padding:34px 38px 22px;
  box-sizing:border-box;
  overflow: auto;
  .check{
    width:48px; // 52 - 2 * 2(border)
    height:48px;
    display: flex;
    justify-content:center;
    align-items: center;
    border-radius:50%;
    border: dotted 2px #d4d4d4;
    margin-bottom:26px;
    img{
      width:20px;
      height:16px;
      padding: 16px 0; // 사이즈가 줄어들었을때 빈 공간때문에 check가 찌부되지않도록
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top:34px;

    .whiteButton {
      width: 32%;
      height: 48px;
      font-weight: 600;
      letter-spacing: -0.32px;
      color: #424242;
    }
    .blackButton{
      width: 65%;
      height: 48px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: -0.32px;
    }
  }
  @media (max-width: 720px) {
    width:324px;
    padding:23px 24px 20px;

    .check{
      margin-bottom:18px;
    }
    .buttons {
      margin-top:25px;
    }
  }
`
